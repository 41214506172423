import * as angular from 'angular';

class DatepickerComponentController {
  static $inject = ['$element'];
  public model: any;
  public placeholder: string;
  public format: string;
  public animation: boolean;

  constructor(private $element: ng.IAugmentedJQuery) {}

  $onInit() {
    this.placeholder = this.placeholder || 'DD/MM/YYYY';
    this.format = "'dd/MM/yyyy'";
    this.animation = false;
  }

  clearDate() {
    this.model = null;
    this.$element.find('input').data('kendoDatePicker').value(null);
  }
}

export class DatepickerComponent implements angular.IComponentOptions {
  public bindings: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      model: '=',
      placeholder: '@'
    };

    this.templateUrl = 'flowingly.datepicker.tmpl.html';
  }

  controller = DatepickerComponentController;
}

angular
  .module('flowingly.components')
  .component('flowinglyDatepicker', new DatepickerComponent());
