import angular, { IHttpService, IQService, IScope, IPromise } from 'angular';
import { PPDFService } from '../../runner.services/ppdf.service';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { FormGen } from '@Client/@types/formGen';
import { RunnerFlowsFormatterService } from '@Client/runner.services/flows.formatter';
import {
  IStepReassignment,
  IWebhookOverride
} from '@Client/runner.services/flow.api.service';
import { EventName } from '@Shared.Angular/flowingly.services/appInsights.service';
import { IFlowForUserPascalCase } from '@Shared.Angular/@types/core/contracts/queryModel/flows/flowForUser';
import { Guid } from '@Shared.Angular/@types/guid';

/**
 * We should move out all the UI logic and variables that are being passed
 * over and over again to different sub components and just include them here.
 * Then inject this service.
 */
export default class RunnerFlowService {
  constructor(
    private userNotificationsApiService: SharedAngular.UserNotificationsApiService,
    private pubsubService: SharedAngular.PubSubService,
    private runnerFlowsFormatter: RunnerFlowsFormatterService,
    private notificationService: SharedAngular.NotificationService,
    private dialogService: SharedAngular.DialogService,
    private flowListManager: FlowListManager,
    private fgFileListService: FormGen.FgFileListService,
    public sessionService: SharedAngular.SessionService,
    private pdf: SharedAngular.PDFService,
    public $q: IQService,
    private he: He,
    private $: JQueryStatic,
    private flowApiService: FlowApiService,
    private PPDFService: PPDFService,
    public avatarService: SharedAngular.AvatarService,
    public commentApiService: SharedAngular.CommentApiService,
    public dateService: DateService,
    public APP_CONFIG: SharedAngular.APP_CONFIG,
    public $http: IHttpService,
    public lodashService: Lodash,
    public $sce: IScope,
    public flowinglyConstants: SharedAngular.FlowinglyConstants,
    private appInsightsService: SharedAngular.AppInsightsService
  ) {}

  public hasStarted: boolean;

  public startFlow(
    flowModelId: Guid,
    subject: string,
    recipients: IActorParameter[],
    cc: IActorParameter[],
    assignedActorId?: Guid,
    shouldAppendNameToSubject?: boolean,
    excludeActors?: Guid[]
  ) {
    return this.flowApiService.bulkStartFlow(
      flowModelId,
      subject,
      recipients,
      cc,
      assignedActorId,
      shouldAppendNameToSubject,
      excludeActors
    );
  }

  public getFlowById(flowId: Guid) {
    return this.flowApiService.getFlowById(flowId, true).then((flowForUser) => {
      return this.runnerFlowsFormatter.formatFlow(flowForUser);
    });
  }

  public showNudgeDialog(nudgeUserParams: INudgeUserParameters) {
    const { dialogService } = this;
    return dialogService
      .showDialog({
        template:
          'Client/runner.flow/runner.flow.history/runner.flow.history.nudge.dialog.tmpl.html',
        controller: 'nudgeDialogController',
        appendClassName: 'ngdialog-normal',
        data: nudgeUserParams
      })
      .then((stepNudgeHistory) => {
        if (dialogService.isCloseModalWithCancelAction(stepNudgeHistory)) {
          //user closed modal by clicking on overlay (or cancel or press Esc key)
          return;
        }
        return stepNudgeHistory;
      });
  }

  /**
   * Moved from footer.component.js's nudgeFlowWaitingOnActor from
   * commit e346d44c3b548a19424aa4282a2fe08213c38825 on 24/09/2019.
   */
  nudgeUser(nudgeUserParameters: INudgeUserParameters) {
    const {
      pubsubService,
      notificationService,
      flowApiService,
      appInsightsService,
      showNudgeDialog
    } = this;

    appInsightsService.startEventTimer('stepUserNudged');

    const nudgeDialog = () => showNudgeDialog.bind(this)(nudgeUserParameters);
    const timedNudgeDialog = appInsightsService.timeUserActivityForEvent(
      nudgeDialog,
      'stepUserNudged'
    );
    return timedNudgeDialog().then((stepNudgeHistory) => {
      if (!stepNudgeHistory) {
        return;
      }
      return flowApiService
        .nudgeFlowWaitingOnActor(stepNudgeHistory)
        .then((response) => {
          if (!!response && typeof response !== 'string') {
            pubsubService.publish('STEP_NEW_NUDGE', stepNudgeHistory);
            notificationService.showSuccessToast(
              'Nudge has been successfully sent'
            );
          }
          const { flowIdentifier, stepName } = nudgeUserParameters;
          appInsightsService.trackMetricIfTimerExist('stepUserNudged', {
            flowIdentifier,
            stepName
          });
        });
    });
  }

  showReassignDialog(dialogData, flowId: string, stepId: string) {
    const { dialogService } = this;
    return dialogService
      .showDialog({
        template:
          'Client/runner.flow/runner.flow.footer/runner.flow.reassign.dialog.tmpl.html',
        controller: 'flowReassignController',
        appendClassName: 'ngdialog-theme-plain w-500',
        data: dialogData
      })
      .then((data: IStepReassignment) => {
        if (dialogService.isCloseModalWithCancelAction(data)) {
          //user closed modal by clicking on overlay (or cancel or press Esc key)
          return;
        }

        if (data != null) {
          data.stepId = stepId;
          data.flowId = flowId;

          return data;
        }
      });
  }

  parseReassignStepParameter(reassignStepParameters: ReassignParameters) {
    const { flowId, flowIdentifier } = reassignStepParameters;
    let stepId = undefined,
      currentStep = undefined,
      currentTask = undefined,
      stepName = undefined,
      taskName = undefined,
      dialogData,
      appInsightsEventName: EventName;

    if ('currentStep' in reassignStepParameters) {
      // reassign a step
      stepId = reassignStepParameters.stepId;
      currentStep = reassignStepParameters.currentStep;
      stepName = currentStep.Name;
      appInsightsEventName = 'stepReassigned';
      dialogData = { currentStep };
    } else {
      // reassign a step task
      currentTask = reassignStepParameters.currentTask;
      taskName = currentTask.Name;
      stepName = currentTask.StepName;
      appInsightsEventName = 'stepTaskReassigned';
      dialogData = reassignStepParameters;
    }
    return {
      flowIdentifier,
      flowId,
      stepId,
      appInsightsEventName,
      stepName,
      taskName,
      dialogData
    };
  }
  /**
   * Moved from footer.component.js's reassignStep from
   * commit e346d44c3b548a19424aa4282a2fe08213c38825 on 24/09/2019.
   */
  reassign(reassignStepParameters: ReassignParameters) {
    const {
      notificationService,
      appInsightsService,
      showReassignDialog,
      flowApiService,
      parseReassignStepParameter
    } = this;

    const {
      stepId,
      flowId,
      flowIdentifier,
      appInsightsEventName,
      dialogData,
      stepName,
      taskName
    } = parseReassignStepParameter.bind(this)(reassignStepParameters);

    appInsightsService.startEventTimer(appInsightsEventName);

    const reassignDialog = () =>
      showReassignDialog.bind(this)(dialogData, flowId, stepId);

    const timedReassignDialog =
      appInsightsService.timeUserActivityForEvent<IStepReassignment>(
        reassignDialog,
        appInsightsEventName
      );
    return timedReassignDialog().then((data) => {
      if (!data) {
        return;
      }
      return flowApiService.reassignStep(data).then((response) => {
        if (response.Success) {
          notificationService.showSuccessToast(
            data.reassignType + ' has been successfully reassigned'
          );
        } else {
          notificationService.showErrorToast(response.ErrorMessage);
        }
        appInsightsService.trackMetricIfTimerExist(appInsightsEventName, {
          flowIdentifier,
          stepName,
          taskName
        });
      });
    });
  }

  overrideProcessingIntegration(
    parameters: IOverrideProcessingIntegrationParameters
  ) {
    const { dialogService, notificationService } = this;
    const { stepId, flowId, userId } = parameters;

    return dialogService
      .showDialog({
        template:
          'Client/runner.flow/runner.flow.footer/runner.flow.override.integration.dialog.tmpl.html',
        controller: 'flowOverrideIntegrationController',
        appendClassName: 'ngdialog-theme-plain w-500',
        data: parameters
      })
      .then((data: IWebhookOverride) => {
        if (dialogService.isCloseModalWithCancelAction(data)) {
          return;
        }

        if (data != null) {
          data.stepId = stepId;
          data.flowId = flowId;
          data.userId = userId;

          return this.flowApiService
            .overrideProcessingIntegration(data)
            .then(function (response) {
              if (response.Success) {
                notificationService.showSuccessToast(
                  'The processing has been successfully skipped'
                );
              } else {
                notificationService.showErrorToast(response.ErrorMessage);
              }
            });
        }
      });
  }

  deleteFlow(flowId: Guid) {
    const {
      pubsubService,
      flowApiService,
      flowListManager,
      notificationService,
      userNotificationsApiService
    } = this;

    return flowApiService.deleteFlow(flowId).then((response) => {
      if (response) {
        notificationService.showSuccessToast('Flow deleted sucessfully');
        flowListManager.refreshFlowInstanceLists();
      }
      return response;
    });
  }

  cancelEntity(cancelEntityParameters: ICancelEntityParameters) {
    const {
      dialogService,
      flowListManager,
      notificationService,
      fgFileListService,
      appInsightsService,
      flowApiService,
      userNotificationsApiService,
      pubsubService
    } = this;

    const appInsightsEventName =
      cancelEntityParameters.entity === 'flow'
        ? 'flowCanceled'
        : 'stepTaskCanceled';
    appInsightsService.startEventTimer(appInsightsEventName);

    const showCancelDialog = () => {
      return dialogService
        .showDialog({
          template:
            'Client/runner.flow/runner.flow.footer/runner.flow.cancel.dialog.tmpl.html',
          controller: 'flowCancelController',
          appendClassName: 'ngdialog-theme-plain w-700',
          data: cancelEntityParameters
        })
        .then((data) => {
          if (dialogService.isCloseModalWithCancelAction(data)) {
            // The user closed the modal form by pressing Esc, Cancel, etc.
            return;
          }

          if (data != null) {
            return data;
          }
        });
    };
    const timedCancelDialog = appInsightsService.timeUserActivityForEvent(
      showCancelDialog,
      appInsightsEventName
    );

    return timedCancelDialog().then((data) => {
      switch (data?.entity) {
        case 'flow': {
          fgFileListService.removeFileControlsForFlow(data.FlowId);
          return flowApiService.withdrawFlow(data).then((response) => {
            if (response === true) {
              notificationService.showSuccessToast(
                'Flow cancellation succeeded'
              );
              flowListManager.refreshFlowInstanceLists();
              userNotificationsApiService.getNotificationCount(true);
            } else {
              notificationService.showErrorToast('Error cancelling flow');
            }
            appInsightsService.trackMetricIfTimerExist(appInsightsEventName);
            return response;
          });
        }
        case 'task': {
          return flowApiService.cancelStepTask(data).then((response) => {
            if (response === true) {
              pubsubService.publish(
                'STEP_TASK_CANCELLED',
                cancelEntityParameters
              );
              notificationService.showSuccessToast(
                'Task cancellation succeeded'
              );
            } else {
              notificationService.showSuccessToast('Error cancelling task');
            }
            appInsightsService.trackMetricIfTimerExist(appInsightsEventName);
            return response;
          });
        }
      }
    });
  }

  addStepTask(addStepTaskParameters: IAddStepTaskParameters): IPromise<any> {
    const { dialogService, flowListManager, pubsubService } = this;
    const { stepId, flowId } = addStepTaskParameters;

    return dialogService
      .showDialog({
        template:
          'Client/runner.flow/runner.flow.footer/runner.flow.addsteptask.dialog.tmpl.html',
        controller: 'flowAddStepTaskController',
        appendClassName: 'ngdialog-theme-plain w-700',
        data: addStepTaskParameters
      })
      .then((data) => {
        if (dialogService.isCloseModalWithCancelAction(data)) {
          return;
        }

        if (data != null) {
          data.stepId = stepId;
          data.flowId = flowId;
          data.startedByUserId = addStepTaskParameters.stepTaskCreatedByUserId;

          return this.flowApiService.addStepTask(data).then((response) => {
            if (response.status === 200 && response.data) {
              pubsubService.publish('STEP_TASK_CREATED', response.data);
            } else {
              console.error('Unsuccessfully added a step task.');
            }
          });
        }
      });
  }

  updateStepTaskStatus(
    updateStepTaskParameters: IUpdateStepTaskStatusParameters
  ) {
    return this.flowApiService
      .updateStepTaskStatus(
        updateStepTaskParameters.stepTaskId,
        updateStepTaskParameters.status
      )
      .then((response) => {
        if (response.status === 200 && response.data) {
          this.pubsubService.publish('STEP_TASK_UPDATED', response.data);
          return response.data;
        } else {
          console.error('Unsuccessfully updated the step task status.');
          return null;
        }
      });
  }

  updateStepTaskApproval(
    updateStepTaskApprovalParameters: IUpdateStepTaskApprovalParameters
  ): IPromise<any> {
    return this.flowApiService
      .updateStepTaskApproval(
        updateStepTaskApprovalParameters.stepTaskId,
        this.sessionService.getUser().id,
        updateStepTaskApprovalParameters.approved,
        updateStepTaskApprovalParameters.comment
      )
      .then((response) => {
        if (response.status === 200 && response.data) {
          this.pubsubService.publish('STEP_TASK_UPDATED', response.data);
          return response.data;
        } else {
          console.error('Unsuccessfully updated the step task approval.');
          return null;
        }
      });
  }

  /**
   * Moved from footer.component.js's exportToPdf from
   * commit e346d44c3b548a19424aa4282a2fe08213c38825 on 24/09/2019.
   */
  public exportToPdf(pdfName: string, flow: IFlowForUserPascalCase) {
    const { $ } = this;

    if ($('.menu-desktop-pinned-content').length) {
      return PdfHelper.generatePdfPayload(flow, this).then((data) => {
        return this.PPDFService.generatePpdf(
          'Client/runner/services/pdf-templates/flow-to-ppdf.html',
          data
        );
      });
    }
  }
}

export interface IActorParameter {
  userId?: Guid;
  groupdId?: Guid;
}

export interface INudgeUserParameters {
  whoIsNudged: string;
  stepName: string;
  stepId: string;
  nudgedById: string;
  nudgedByUserName: string;
  flowSubject: string;
  flowIdentifier: string;
}

export interface IReassignStepParameters {
  currentStep: unknown;
  stepId: string;
  flowId: string;
  flowIdentifier: string;
}

export interface IReassignStepTaskParameters {
  currentTask: unknown;
  flowId: string;
  flowIdentifier: string;
  reassignType: string;
  taskId: string;
}

export type ReassignParameters =
  | IReassignStepParameters
  | IReassignStepTaskParameters;

export interface IOverrideProcessingIntegrationParameters {
  currentStep: any;
  stepId: string;
  flowId: string;
  userId: string;
}

export interface ICancelEntityParameters {
  entity: string;
  stepId: string;
  flowId: string;
  stepTaskId: string;
}

export interface IAddStepTaskParameters {
  stepTaskCreatedByUserId: string;
  stepId: string;
  flowId: string;
}

export interface IUpdateStepTaskStatusParameters {
  stepTaskId: string;
  status: string;
}

export interface IUpdateStepTaskApprovalParameters {
  stepTaskId: string;
  approved: boolean;
  comment?: string;
}

export class PdfHelper {
  static generatePdfPayload(
    flow: IFlowForUserPascalCase,
    controller: RunnerFlowService
  ) {
    const {
      $q,
      sessionService,
      commentApiService,
      avatarService,
      dateService,
      APP_CONFIG,
      $http,
      lodashService,
      $sce,
      flowinglyConstants
    } = controller;
    const data = {
      flow,
      completedSteps: flow.Steps.filter((step) => step.IsCompleted == 1),
      inProgressSteps: flow.Steps.filter((step) => step.IsCompleted == 0),
      backToFlowLink: APP_CONFIG.runnerUrl + '/flows/' + flow.FlowId,
      brandName: APP_CONFIG.brandingName,
      flowEmptyMsg: {
        NoLabel: 'No Label',
        NoInfoEntered: 'No information was entered.',
        NoFileUploaded: 'No file was uploaded.',
        TableNoFile: 'No file added',
        TableNoRowsAdded: flowinglyConstants.tableNoRowMessage.TABLE_NO_ROW,
        NoComments: 'No Comments to display.'
      },
      maxOptionsToDisplay: 15,
      generateTableData(field) {
        const table =
          field.Text != null && field.Text != data.flowEmptyMsg.TableNoRowsAdded
            ? JSON.parse(field.Text)
            : [];
        const headers = JSON.parse(field.TableSchema);
        table.headers = headers.map(({ header, isRequired, type }) => ({
          value: header,
          isRequired: isRequired,
          type: type
        }));
        table.rowSums = data.formatTotals(table, headers);

        return table;
      },
      generateOptionsList(list) {
        /* Used By:
                    History/Completed Steps:
                    - Option List

                    Current Steps
                    - Dropdown
                    - Option List
                    - Multi-selection List
                    - Task List
                    - Approval */
        const options = JSON.parse(list);
        return options;
      },
      formatDisplay: {
        started_by: function (who_istarted, requested_by) {
          let _started_by;

          const requester = requested_by
            ? requested_by.FirstName + ' ' + requested_by.LastName
            : null;

          if (requester && requester != who_istarted)
            _started_by =
              requester + ' on behalf of ' + data.flow.StartedByName;
          else _started_by = data.flow.StartedByName;

          return _started_by;
        },

        delegated_by: function (assigned, delegator) {
          let _delegated_by;

          if (delegator && delegator != assigned)
            _delegated_by = assigned + ' on behalf of ' + delegator;
          else _delegated_by = assigned;

          return _delegated_by;
        },

        instruction: function (ins) {
          return $sce.trustAsHtml(ins);
        },

        field_header: function (
          displayname,
          field_type,
          value,
          text,
          step_type,
          showDisplayName = false
        ) {
          let _field_header = displayname;

          switch (field_type) {
            case 'instruction':
              _field_header = showDisplayName ? _field_header : '';
              break;
            case 'approval':
              _field_header =
                step_type == flowinglyConstants.taskType.PARALLEL_APPROVAL ||
                step_type == flowinglyConstants.taskType.SEQUENTIAL_APPROVAL
                  ? ''
                  : 'Approval Decision';
              break;
            case 'approvecomment':
              if (value != null && text != null) _field_header = 'Comment';
              else _field_header = '';
              break;
            default:
              break;
          }

          return _field_header;
        },

        generic: function (value, text, field_type, step_type) {
          let _generic = text;

          if ((value == null || value == '') && (text == null || text == '')) {
            if (
              field_type == 'approval' &&
              (step_type == flowinglyConstants.taskType.PARALLEL_APPROVAL ||
                step_type == flowinglyConstants.taskType.SEQUENTIAL_APPROVAL)
            )
              _generic = '';
            else _generic = data.flowEmptyMsg.NoInfoEntered;
          }

          return _generic;
        },

        table_file: function (value) {
          let i;

          if (typeof value === 'undefined')
            return data.flowEmptyMsg.TableNoFile;
          else {
            for (i = 0; i < data.tableFileNames.length; i++) {
              if (typeof data.tableFileNames[i] === 'undefined')
                return data.flowEmptyMsg.TableNoFile;
              else {
                if (value == data.tableFileNames[i].id)
                  return data.tableFileNames[i].filename;
              }
            }
          }
        },

        email_recipient: function (r) {
          const _list =
            r.length > 0 ? r.map((r) => ' ' + r.DisplayName).toString() : [];

          return _list;
        },

        signature_timestamp: function (time) {
          return dateService.formatUtcToLocal(time);
        },

        comment_tagged_name: function (c) {
          const regex = /\[~(.*?)\]/g;
          const found = c.match(regex);
          let tagged_name, formatted, i;

          if (found != null) {
            tagged_name = found.map((f) => f.slice(2, f.length - 1));
            formatted = tagged_name.map(
              (tn) => '<span class="flowingly-blue">@' + tn + '</span>'
            );

            for (i = 0; i < found.length; i++)
              c = c.replace(found[i], formatted[i]);
          }

          return c;
        },

        utc_time: function (time, display_type) {
          let _format;

          switch (display_type) {
            case 'r':
              _format = 'LLL';
              break;
            case 'c':
              _format = 'LLLL';
              break;
            default:
              break;
          }

          return dateService.formatUtcToLocalForPrinting(time, _format);
        },

        optional_generic: function (_validation) {
          let _optional = _validation != null ? JSON.parse(_validation) : '';

          if (_optional != '')
            _optional = !_optional.required ? '(optional)' : '';

          return _optional;
        },

        optional_table: function (_isReq, type) {
          let _opt_tbl;

          if (type == flowinglyConstants.tableCellType.FORMULA)
            _opt_tbl = '(formula)';
          else if (type == flowinglyConstants.tableCellType.LOOKUP)
            _opt_tbl = _isReq == false ? '(lookup)' : '';
          else _opt_tbl = _isReq == false ? '(Optional)' : '';

          return _opt_tbl;
        },

        formatStepTaskDateTime: function (dateTimeString) {
          if (dateTimeString != null) {
            const formattedDateTime = dateTimeString.replace('Z', '');
            return formattedDateTime;
          }
          return dateTimeString;
        }
      },
      generateAvatar: {
        forFlow: function () {
          return avatarService.getFlowInitials(flow.Name);
        },

        forActor: function (actor) {
          return avatarService.getUserInitial(actor);
        },

        forActorColor: function (actor) {
          return avatarService.getColour(actor);
        }
      },
      flowComments: null,
      tableFileNames: null,
      requestConfigFunc(fileId) {
        if (typeof fileId === 'undefined') return { id: null, filename: null };
        else {
          const user = sessionService.getUser(),
            linkForRequest =
              APP_CONFIG.apiBaseUrl +
              'files/' +
              user.businessId +
              '/' +
              user.id +
              '/' +
              fileId,
            requestConfig = {
              method: 'Get',
              headers: { Authorization: 'Bearer ' + sessionService.getToken() },
              url: linkForRequest,
              cache: 'true'
            };

          return $http(requestConfig).then((file) => {
            return { id: fileId, filename: file.data.filename };
          });
        }
      },

      isLookupCurrencyCell(cell, tableSchema) {
        const schemaCell = tableSchema.find((c) => c.id === cell.id);

        return (
          cell.type === flowinglyConstants.tableCellType.LOOKUP &&
          schemaCell &&
          schemaCell.lookupConfig &&
          schemaCell.lookupConfig.displayValueType === 'currency'
        );
      },

      isLookupNumberCell(cell, tableSchema) {
        const schemaCell = tableSchema.find((c) => c.id === cell.id);

        return (
          cell.type === flowinglyConstants.tableCellType.LOOKUP &&
          schemaCell &&
          schemaCell.lookupConfig &&
          schemaCell.lookupConfig.displayValueType === 'number'
        );
      },

      getDropdownCell(cell, schema) {
        const cellData = schema.find((o) => o.id === cell.id);

        if (
          cellData.dbDataSource &&
          Array.isArray(cellData.dbDataSource.displayValueOptions)
        ) {
          const matchingOption = cellData.dbDataSource.displayValueOptions.find(
            (option) => option.name === cellData.dbDataSource.displayValue
          );

          if (
            matchingOption?.dataType ===
            flowinglyConstants.customDataTypeName.NUMBER
          ) {
            return {
              isNumeric: true,
              type: flowinglyConstants.tableCellType.NUMBER
            };
          } else if (
            matchingOption?.dataType ===
            flowinglyConstants.customDataTypeName.CURRENCY
          ) {
            return {
              isNumeric: true,
              type: flowinglyConstants.tableCellType.CURRENCY
            };
          } else {
            return { isNumeric: false, type: null };
          }
        }
        return { isNumeric: true, type: cell.type };
      },

      getFormulaCell(cells, schema) {
        const formulaCell = schema.find((o) => o.id == cells.id);
        if (!formulaCell) return;

        let isNumber = false;
        let isCurrency = false;
        if (!formulaCell.formulaConfig) return;

        for (const element of formulaCell.formulaConfig.formulaOperands) {
          const columnId = parseInt(element.replace('column', ''), 10);
          const column = schema.find((o) => o.id === columnId);
          if (!column) continue;
          if (
            column.dbDataSource &&
            Array.isArray(column.dbDataSource.displayValueOptions)
          ) {
            const matchingOption = column.dbDataSource.displayValueOptions.find(
              (option) => option.name === column.dbDataSource.displayValue
            );
            if (
              matchingOption?.dataType ===
              flowinglyConstants.customDataTypeName.CURRENCY
            ) {
              isCurrency = true;
            } else if (
              matchingOption?.dataType ===
              flowinglyConstants.customDataTypeName.NUMBER
            ) {
              isNumber = true;
            }
          } else if (column.type === flowinglyConstants.tableCellType.NUMBER) {
            isNumber = true;
          } else if (
            column.type === flowinglyConstants.tableCellType.CURRENCY
          ) {
            isCurrency = true;
          }
        }
        if (isCurrency) {
          return {
            isNumeric: true,
            type: flowinglyConstants.tableCellType.CURRENCY
          };
        } else if (isNumber) {
          return {
            isNumeric: true,
            type: flowinglyConstants.tableCellType.NUMBER
          };
        }
      },

      calculateCellSum(cell, tableSchema) {
        let sum = 0;
        const cellValue = cell.value;

        if (
          data.isLookupCurrencyCell(cell, tableSchema) ||
          data.isLookupNumberCell(cell, tableSchema) ||
          data.getDropdownCell(cell, tableSchema)?.isNumeric ||
          data.getFormulaCell(cell, tableSchema)?.isNumeric
        ) {
          if (typeof cellValue === 'string' && cellValue.indexOf(',') >= 0) {
            const valueArray = cellValue.split(',');

            for (const v of valueArray) {
              if (!isNaN(parseFloat(v))) {
                sum += parseFloat(v);
              }
            }
          } else {
            if (!isNaN(parseFloat(cellValue))) sum += parseFloat(cellValue);
          }
        } else sum += cellValue;

        return sum;
      },

      formatTotals(table, schema) {
        //if there are any number / currency columns, we create an array of totals
        let foundNumberOrCurrency = false,
          sumCells = [];

        lodashService.forEach(table.rows, function (row) {
          lodashService.forEach(row.cells, function (cell) {
            const existing = lodashService.find(sumCells, function (sumCell) {
              return sumCell.id === cell.id;
            });
            if (cell.type === flowinglyConstants.tableCellType.DROPDOWN) {
              cell.type = data.getDropdownCell(cell, schema)?.type;
            } else if (cell.type === flowinglyConstants.tableCellType.FORMULA) {
              cell.type = data.getFormulaCell(cell, schema)?.type;
            }
            if (
              (cell.type === flowinglyConstants.tableCellType.CURRENCY ||
                cell.type === flowinglyConstants.tableCellType.NUMBER ||
                data.isLookupNumberCell(cell, schema) ||
                data.isLookupCurrencyCell(cell, schema)) &&
              !isNaN(cell.value)
            ) {
              foundNumberOrCurrency = true;
              if (existing) {
                existing.sum += data.calculateCellSum(cell, schema);
              } else {
                const sumInitValue = data.calculateCellSum(cell, schema);
                sumCells.push({
                  id: cell.id,
                  type: cell.type,
                  sum: sumInitValue
                });
              }
            } else {
              if (!existing) sumCells.push({ id: cell.id, type: cell.type });
            }
          });
        });
        //if did not find any number or currency columns clear out the data so total row not displayed.
        if (!foundNumberOrCurrency) {
          sumCells = [];
        }

        return sumCells;
      }
    };

    return $q((resolve, reject) => {
      return commentApiService
        .getFlowComments(1, flow.FlowId)
        .then((comments) => {
          data.flowComments = comments;
        })
        .then(() => {
          const s = flow.Steps.filter((step) => step.IsCompleted == 1);
          let tables,
            parsedTables,
            i,
            j,
            k,
            m,
            fileIdsMapping,
            _parsed,
            _mapped;
          const fileUploadsField = [];

          for (i = 0; i < s.length; i++) {
            tables = s[i].Fields.filter((f) => f.Type == 'table');

            for (j = 0; j < tables.length; j++) {
              if (tables[j].Value != data.flowEmptyMsg.TableNoRowsAdded) {
                parsedTables = JSON.parse(tables[j].Value);

                if (parsedTables) {
                  for (k = 0; k < parsedTables.rows.length; k++) {
                    _parsed = parsedTables.rows[k].cells.filter(
                      (c) =>
                        c.type == flowinglyConstants.tableCellType.FILE &&
                        c.value != null
                    );
                    _mapped = _parsed.map(({ value }) => ({
                      value: value,
                      filename: null
                    }));

                    for (m = 0; m < _mapped.length; m++)
                      fileUploadsField.push(_mapped[m]);
                  }
                }
              }
            }
          }

          if (fileUploadsField.length > 0) {
            fileIdsMapping = fileUploadsField.map((v) => ({
              id: v.value,
              filename: null
            }));
            fileIdsMapping = fileIdsMapping.map((f) =>
              data.requestConfigFunc(f.id)
            );

            return $q.all(fileIdsMapping);
          }
        })
        .then((fileIdsMapping) => {
          data.tableFileNames = fileIdsMapping;
        })
        .then(() => {
          resolve(data);
        });
    });
  }

  static onPreDrawFactory({ $, pdf, he, $q }: any) {
    const $base = $('.content-wrap');

    const TEXT_NODE = 3;
    const EL_NODE = 1;
    const SPEC_CHAR_RX = /[^\x00-\x7F]+/;

    return function ({ element: $dom }) {
      // fixes for missing signature
      const $signatures = $base.find('canvas.signature-pad');
      const $copiedSignatures = $dom.find('canvas.signature-pad');

      $copiedSignatures.each((i, el) => {
        const $iframe = $($signatures[i]);
        const fromCtx = $iframe[0].getContext('2d');
        const toCtx = el.getContext('2d');

        const width = $iframe.width();
        const height = $iframe.height();

        const imgData = fromCtx.getImageData(0, 0, width, height);
        toCtx.putImageData(imgData, 0, 0);
      });

      // fixes for text not displaying
      // the text is inside an iframe, so we manually move it here
      const $iframes = $base.find('.k-editor iframe');
      const $copiedIframes = $dom.find('.k-editor iframe');

      $copiedIframes.each((i, el) => {
        const contents = $('<div></div>').html(
          $iframes.eq(i).contents().find('body').html()
        );
        $(el).parents('table').replaceWith(contents);
      });

      // fixes for the checkboxes not appearing in EDGE
      const $checkboxes = $dom
        .find('.form-field-wrapper [type=checkbox]')
        .add($dom.find("[checked='checked']"));
      $checkboxes.each((i, el) => {
        const $el = $(el);
        const $label = $el.next();
        $el.remove();

        const mark =
          $el.is(':checked') || $el.attr('checked') == 'checked' ? '✓' : '';
        $label.prepend(
          `<span class='pdf-export-checkbox-replacement'>${mark}</span>`
        );
      });

      const $promises = [];
      // find all non english text nodes
      // and replace them with image
      $dom
        .find('*')
        .contents()
        .each((i, el) => {
          let $el = $(el);

          const hasChildren = $el.children().length > 0;
          const isNotValidDomToShow = $el.prop('tagName') == 'OPTION';
          if (!$el.isVisible() || hasChildren || isNotValidDomToShow) {
            return;
          }

          let text = el.textContent || $el.val();
          if (!text || text.length == 0) {
            return;
          }

          text = he.decode(text);

          const hasNonAsciiChars = text.length && SPEC_CHAR_RX.test(text);
          if (hasNonAsciiChars) {
            if ($el.attr('type') == 'text') {
              // cant show inputs
              const $tmp = $(`<div>${text}</div>`);
              $el.replaceWith($tmp);
              $el = $tmp;
            }

            $el.css('font-weight', 400); // remove font weight
            $promises.push(
              pdf.utility.domToImage($el).then((img) => {
                if (img) {
                  $el.replaceWith(img);
                }
              })
            );
          }
        });

      // give the jquery changes a few seconds to finalize
      // since we have image loading and what not.
      return $q.all($promises);
    };
  }
}

RunnerFlowService.$inject = [
  'userNotificationsApiService',
  'pubsubService',
  'runnerFlowsFormatter',
  'notificationService',
  'dialogService',
  'flowListManager',
  'fgFileListService',
  'sessionService',
  'pdfService',
  '$q',
  'he',
  'jQuery',
  'flowApiService',
  PPDFService.ID,
  'avatarService',
  'commentApiService',
  'dateService',
  'APP_CONFIG',
  '$http',
  'lodashService',
  '$sce',
  'flowinglyConstants',
  'appInsightsService'
];
angular
  .module('flowingly.runner.flow')
  .service('runnerFlowService', RunnerFlowService);

export type RunnerFlowServiceType = InstanceType<typeof RunnerFlowService>;
