import angular from 'angular';
import { IFlowGroupCategory } from './flow.list.manager';

export interface Category {
  name?: string | null;
  id: string | null;
  flowModels?: string[];
  subCategories?: Category[];
  count: number;
  items?: Category[];
  text?: string;
}
export class RunnerCategoryNavigationService {
  getFormattedReportTreeData = (data: Category[]): IFlowGroupCategory[] => {
    const formattedData = [];
    data.forEach((category) => {
      const formattedCategory = {
        items: [],
        id: category.id,
        text: category.name,
        flowModels: category.flowModels
      };

      if (category.subCategories?.length > 0) {
        formattedCategory.items = this.getFormattedReportTreeData(
          category.subCategories
        );
      }

      formattedData.push(formattedCategory);
    });

    return formattedData;
  };

  getFormattedTreeData = (data: Category[]): IFlowGroupCategory[] => {
    data.forEach((category) => {
      category.items = category.subCategories;
      delete category.subCategories;
      category.text = category.name;
      delete category.name;

      if (category.items?.length > 0) {
        category.items = this.getFormattedTreeData(category.items);
      }
    });
    return data;
  };
  getFlowModelsFromNestedCategories(categories: Category[]) {
    const allFlowModels = [];

    function extractFlowModels(categories) {
      categories.forEach((category) => {
        if (category.flowModels?.length > 0) {
          allFlowModels.push(...category.flowModels);
        }
        if (category.subCategories?.length > 0) {
          extractFlowModels(category.subCategories);
        }
        if (category.items?.length > 0) {
          extractFlowModels(category.items);
        }
      });
    }

    extractFlowModels(categories);
    return allFlowModels;
  }
  findNodeById(nodes: Category[], id: string) {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      } else if (node.items?.length > 0) {
        const foundNode = this.findNodeById(node.items, id);
        if (foundNode) {
          return foundNode;
        }
      } else if (node.subCategories?.length > 0) {
        const foundNode = this.findNodeById(node.subCategories, id);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  }
}

angular
  .module('flowingly.runner.services')
  .factory(
    'runnerCategoryNavigationService',
    () => new RunnerCategoryNavigationService()
  );

export type RunnerCategoryNavigationServiceType = InstanceType<
  typeof RunnerCategoryNavigationService
>;
