import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular
  .module('flowingly.runner.flow')
  .controller('selectApproversController', selectApproversController);

selectApproversController.$inject = [
  '$scope',
  'lodashService',
  'flowinglyConstants'
];

function selectApproversController(
  $scope: IScopeNgDialog,
  _: Lodash,
  flowinglyConstants: SharedAngular.FlowinglyConstants
) {
  const $ctrl = this;

  $ctrl.selectedList = [];
  $ctrl.currentStepId = $scope.ngDialogData.currentStepId;
  $ctrl.flowId = $scope.ngDialogData.flowId;
  $ctrl.nodeId = $scope.ngDialogData.nodeId;
  $ctrl.stepType = $scope.ngDialogData.stepType;
  $ctrl.stepName = $scope.ngDialogData.stepName;
  $ctrl.taskTypeConstants = flowinglyConstants.taskType;
  $ctrl.numberOfApproversRequired =
    $scope.ngDialogData.numberOfApproversRequired;
  $ctrl.maxNumberOfApproversRequired =
    $scope.ngDialogData.maxNumberOfApproversRequired;
  $ctrl.numberOfApproversRequiredType =
    $scope.ngDialogData.numberOfApproversRequiredType;
  $ctrl.indexDisplay = `(${$scope.ngDialogData.currentIndex + 1}/${
    $scope.ngDialogData.totalCount
  })`;

  if ($ctrl.stepType === flowinglyConstants.taskType.SEQUENTIAL_APPROVAL) {
    $ctrl.numberOfApproversRequiredType =
      flowinglyConstants.numberOfApproversRequiredType.SUBSET;
  }

  $ctrl.saveSelectedApprovers = () => {
    const data = [];

    _.forEach($ctrl.selectedList, (approver) => {
      data.push(approver.id);
    });

    $scope.closeThisDialog(data);
  };

  $ctrl.cancel = () => {
    $scope.closeThisDialog();
  };

  $ctrl.isFormValid = () => {
    return (
      $ctrl.currentForm.$valid &&
      !(
        $ctrl.selectedLessThanRequiredWhenRequiredTypeIsSubset() ||
        $ctrl.selectedZeroWhenRequiredTypeIsAll() ||
        $ctrl.selectedMoreThanMaxRequiredWhenRequiredTypeIsSubset()
      )
    );
  };

  $ctrl.selectedLessThanRequiredWhenRequiredTypeIsSubset = () => {
    return (
      $ctrl.numberOfApproversRequiredType ===
        flowinglyConstants.numberOfApproversRequiredType.SUBSET &&
      $ctrl.selectedList.length < $ctrl.numberOfApproversRequired
    );
  };

  $ctrl.selectedMoreThanMaxRequiredWhenRequiredTypeIsSubset = () => {
    return (
      $ctrl.numberOfApproversRequiredType ===
        flowinglyConstants.numberOfApproversRequiredType.SUBSET &&
      $ctrl.maxNumberOfApproversRequired &&
      $ctrl.maxNumberOfApproversRequired > 0 &&
      $ctrl.selectedList.length > $ctrl.maxNumberOfApproversRequired
    );
  };

  $ctrl.selectedZeroWhenRequiredTypeIsAll = () => {
    return (
      $ctrl.numberOfApproversRequiredType ===
        flowinglyConstants.numberOfApproversRequiredType.ALL &&
      $ctrl.selectedList.length === 0
    );
  };
}
