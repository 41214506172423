import { Services } from '@Shared.Angular/@types/services';
import INodeCommandData from '@Shared.Angular/@types/core/contracts/commands/modeler/nodeCommandData';
import {
  ActorTypes,
  DynamicActorTypeIds,
  DynamicActorTypeNames,
  NodeCategory,
  TaskType
} from '@Shared.Angular/flowingly.services/flowingly.constants';
import {
  IFlowModelParser,
  IImportableFlowModelFile,
  ImportableFlowModel,
  ImportableFlowModelState
} from '../flow.model.import.service';
import IModelNode from '@Shared.Angular/@types/modelNode';
import { IModelNodeLink } from '@Shared.Angular/@types/workflow';
import IRunnerCard from '@Shared.Angular/@types/core/contracts/queryModel/card/runnerCard';

interface ISwimlaneModelNode extends IModelNode {
  swimlane?: string;
}

export default class FlowModelVsdxParser implements IFlowModelParser {
  private maxKey = 0;
  constructor(
    private fflate: Fflate,
    private guidService: Services.GuidService,
    private avatarService: Services.AvatarService,
    private notificationService: Services.NotificationService
  ) {}

  parseImportables(importFiles: IImportableFlowModelFile[]) {
    const imports: Promise<ImportableFlowModel[]>[] = [];
    for (const importFile of importFiles) {
      if (!importFile.file.name.toLowerCase().endsWith('.vsdx')) {
        continue;
      }
      try {
        const importables = this.convertVsdxFileToFlowModels(importFile.file);
        imports.push(importables);
        importables.then(
          (importables) => (importFile.handled = importables.length > 0)
        );
      } catch {
        /* empty */
      }
    }
    return Promise.allSettled(imports).then((results) => {
      return results
        .filter((result) => result.status === 'fulfilled')
        .map(
          (result: PromiseFulfilledResult<ImportableFlowModel[]>) =>
            result.value
        )
        .flat();
    });
  }

  private async convertVsdxFileToFlowModels(file: File) {
    const buffer = await file.arrayBuffer();
    const fileData = new Uint8Array(buffer);
    const unzippedData = this.fflate.unzipSync(fileData);
    const flowModels: ImportableFlowModel[] = [];
    const utf8Decoder = new TextDecoder();
    let flowModelCount = 0;
    const fileNameParts = file.name.split('.');
    fileNameParts.splice(fileNameParts.length - 1, 1);
    const fileNameWithoutExtension = fileNameParts.join('.');
    for (const [path, fileBytes] of Object.entries(unzippedData)) {
      if (fileBytes.length < 1 || !/visio\/pages\/page[0-9]+.xml/.test(path)) {
        continue;
      }

      const text = utf8Decoder.decode(fileBytes);
      const cleanText = text.replaceAll(
        // eslint-disable-next-line no-control-regex
        new RegExp('[\\x00-\\x08\\x0e-\\x1f]+', 'g'),
        ''
      );
      const parser = new DOMParser();
      const xmlDocument = parser.parseFromString(cleanText, 'application/xml');
      const error = xmlDocument.querySelector('parsererror');
      if (error) {
        console.error(error);
        this.notificationService.showErrorToast(
          error.childNodes[1].textContent,
          5000
        );
        continue;
      }
      this.removeWhitespaceNodes(xmlDocument);
      const pageContents = Array.from(xmlDocument.childNodes).find(
        (node) => node.nodeName === 'PageContents'
      );
      if (!pageContents) {
        continue;
      }
      const flowModel = this.parseFlowModelFromPageContents(pageContents);
      if (!flowModel) {
        continue;
      }
      flowModel.name = `${fileNameWithoutExtension} - ${
        flowModel.name || flowModelCount++
      }`;
      flowModel.key = flowModel.name;
      flowModel.file = file;
      flowModels.push(flowModel);
    }

    return flowModels;
  }

  private removeWhitespaceNodes(node: Node) {
    for (let i = node.childNodes.length; i-- > 0; ) {
      const child = node.childNodes[i];
      if (child.nodeType === 3 && child.nodeValue.match(/^\s*$/)) {
        node.removeChild(child);
      } else if (child.nodeType === 1) {
        this.removeWhitespaceNodes(child);
      }
    }
  }

  private parseFlowModelFromPageContents(pageContents: Node) {
    try {
      const pageChildNodes = Array.from(pageContents.childNodes);
      const shapesNode = pageChildNodes.find(
        (node) => node.nodeName === 'Shapes'
      );
      if (!shapesNode) {
        return null;
      }
      const shapesChildNodes = Array.from(shapesNode.childNodes);
      const importable = new ImportableFlowModel({
        categoryHierarchy: ['Visio Imports'],
        name: '',
        modelNodes: [],
        modelLinks: [],
        flowinglyNodes: [],
        willImport: true,
        publish: true,
        state: ImportableFlowModelState.Pending
      });
      this.getLinksFromShapes(shapesNode, importable);
      const linkedNodeIds = new Set(
        importable.modelLinks.flatMap((link) => [link.to, link.from])
      );
      const idToNode: Map<number, Node> = new Map();
      this.getNodesForLinks(shapesNode, linkedNodeIds, idToNode);
      this.removeLinksWithoutNodes(importable, idToNode);
      this.createFlowModelNodes(importable, idToNode);
      this.createStartAndEndNodes(importable);
      this.createSwimLaneNodes(importable.modelNodes);
      this.setFlowModelName(importable, shapesChildNodes);
      return importable;
    } catch (exception) {
      console.debug(exception);
    }
  }

  private setFlowModelName(flowModel: ImportableFlowModel, shapes: Node[]) {
    const cffContainer = shapes.find(
      (shape) => (shape as Element).getAttribute('NameU') === 'CFF Container'
    );
    if (cffContainer) {
      flowModel.name = cffContainer.textContent?.trim();
      return;
    }

    const modelNameShapes = shapes.filter((shape) => {
      const shapeElement = shape as Element;
      const shapeId = parseInt(shapeElement.getAttribute('ID'));
      const isLinked = flowModel.modelLinks.some(
        (link) => link.from === shapeId || link.to === shapeId
      );
      const isLink = Array.from(shape.childNodes).some(
        (node) => (node as Element).getAttribute('N') === 'BegTrigger'
      );
      return !isLinked && !isLink && shapeElement.textContent?.length < 100;
    });
    if (modelNameShapes.length === 1) {
      flowModel.name = this.getText(modelNameShapes[0]);
    }
  }

  private createModelNodeStart(shape: Node) {
    const location = this.getLocationForNode(shape);
    const key = parseInt((shape as Element).getAttribute('ID'));
    return this.createStartNodeForLocation(location.x, location.y, key);
  }

  private createStartNodeForLocation(x: number, y: number, key: number) {
    const modelNode: IModelNode = {
      id: this.guidService.new(),
      key: key,
      category: NodeCategory.EVENT,
      text: 'Start',
      eventType: 1,
      eventDimension: 1,
      item: 'start',
      isNew: false,
      IsInitial: 'true',
      loc: `${x} ${y}}`
    };
    this.maxKey = Math.max(this.maxKey, modelNode.key);
    return modelNode;
  }

  private createModelNodeEnd(shape: Node) {
    const location = this.getLocationForNode(shape);
    const key = parseInt((shape as Element).getAttribute('ID'));
    return this.createEndNodeForLocation(location.x, location.y + 50, key);
  }

  private createEndNodeForLocation(x: number, y: number, key: number) {
    const modelNode: IModelNode = {
      id: this.guidService.new(),
      key: key,
      category: NodeCategory.EVENT,
      text: 'End',
      eventType: 1,
      eventDimension: 8,
      item: 'End',
      isNew: false,
      IsFinal: 'true',
      loc: `${x} ${y}`
    };
    this.maxKey = Math.max(this.maxKey, modelNode.key);
    return modelNode;
  }

  private getNodesForLinks(
    shapesNode: Node,
    linkedNodeIds: Set<number>,
    idToNode: Map<number, Node>
  ) {
    const shapeNodes = Array.from(shapesNode.childNodes);
    for (const shape of shapeNodes as Element[]) {
      const shapeChildNodes = Array.from(shape.childNodes);
      const childShapesNode = shapeChildNodes.find(
        (node) => node.nodeName === 'Shapes'
      );
      if (childShapesNode) {
        this.getNodesForLinks(childShapesNode, linkedNodeIds, idToNode);
      }
      const shapeId = parseInt(shape.getAttribute('ID'));
      if (linkedNodeIds.has(shapeId)) {
        idToNode.set(shapeId, shape);
      }
    }
  }

  private removeLinksWithoutNodes(
    importable: ImportableFlowModel,
    idToNode: Map<number, Node>
  ) {
    importable.modelLinks = importable.modelLinks.filter(
      (link) => idToNode.has(link.from) && idToNode.has(link.to)
    );
  }

  private createFlowModelNodes(
    importable: ImportableFlowModel,
    idToNode: Map<number, Node>
  ) {
    const startNodeIds = Array.from(idToNode.keys()).filter(
      (nodeId) => !importable.modelLinks.some((link) => link.to === nodeId)
    );
    if (startNodeIds.length === 1) {
      this.createFlowModelNodesInOrder(startNodeIds[0], importable, idToNode);
    } else {
      for (const [nodeId, node] of idToNode.entries()) {
        const linksToNode = importable.modelLinks.filter(
          (link) => link.to === nodeId
        );
        const linksFromNode = importable.modelLinks.filter(
          (link) => link.from === nodeId
        );
        this.createFlowModelNode(
          node,
          importable,
          linksToNode,
          linksFromNode,
          false // Without knowing the Start node we can't calculate backlinks
        );
      }
    }
  }

  private createFlowModelNodesInOrder(
    startNodeId: number,
    importable: ImportableFlowModel,
    idToNode: Map<number, Node>
  ) {
    const createdNodeIds: Set<number> = new Set();
    let currentNodeIds: Set<number> = new Set();
    let nextNodeIds: Set<number> = new Set([startNodeId]);
    const modelLinks = importable.modelLinks;
    while (nextNodeIds.size > 0) {
      currentNodeIds = nextNodeIds;
      nextNodeIds = new Set();
      for (const nodeId of currentNodeIds) {
        if (createdNodeIds.has(nodeId)) {
          continue;
        }
        const linksToNode = modelLinks.filter((link) => link.to === nodeId);
        const linksFromNode = modelLinks.filter((link) => link.from === nodeId);
        let hasBacklink = false;
        linksFromNode.forEach((link) => {
          if (
            this.IsSourceToTargetABacklink(
              modelLinks,
              startNodeId,
              link.from,
              link.to
            )
          ) {
            link.isBacklink = true;
            hasBacklink = true;
          }
        });
        const node = idToNode.get(nodeId);
        this.createFlowModelNode(
          node,
          importable,
          linksToNode,
          linksFromNode,
          hasBacklink
        );
        createdNodeIds.add(nodeId);
        linksFromNode
          .filter((link) => !createdNodeIds.has(link.to))
          .forEach((link) => nextNodeIds.add(link.to));
      }
    }
  }

  private createFlowModelNode(
    node: Node,
    importable: ImportableFlowModel,
    linksToNode: IModelNodeLink[],
    linksFromNode: IModelNodeLink[],
    hasBacklink: boolean
  ) {
    const text = this.getText(node);
    const textLowerCase = text.toLowerCase();
    const nameU = (node as Element).getAttribute('NameU');
    const forceDecision =
      text.endsWith('?') && linksFromNode.every((link) => link.visible);
    const forceComponent =
      textLowerCase.startsWith('go to:') && /process/.test(textLowerCase);
    let modelNode: IModelNode;
    if (linksToNode.length === 0 && textLowerCase === 'start') {
      modelNode = this.createModelNodeStart(node);
    } else if (
      linksFromNode.length === 0 &&
      (textLowerCase === 'end' || textLowerCase === 'stop')
    ) {
      modelNode = this.createModelNodeEnd(node);
    } else if (forceDecision || /^Decision(\.|$)/.test(nameU)) {
      modelNode = this.createModelNodeDecision(node);
    } else if (forceComponent || /^Subprocess(\.|$)/.test(nameU)) {
      modelNode = this.createModelNodeComponent(node);
    } else if (hasBacklink) {
      modelNode = this.createModelNodeApproval(node);
      const flowinglyNode = this.createFlowinglyNode(
        modelNode,
        importable.modelNodes.length
      );
      importable.flowinglyNodes.push(flowinglyNode);
    } else {
      modelNode = this.createModelNodeStep(node);
      const flowinglyNode = this.createFlowinglyNode(
        modelNode,
        importable.modelNodes.length
      );
      importable.flowinglyNodes.push(flowinglyNode);
    }
    importable.modelNodes.push(modelNode);
  }

  private IsSourceToTargetABacklink(
    links: IModelNodeLink[],
    currentNodeId: number,
    sourceNodeId: number,
    targetNodeId: number,
    foundTargetNode = false,
    visitedNodeIds: Set<number> = new Set()
  ) {
    if (currentNodeId === targetNodeId && visitedNodeIds.has(sourceNodeId)) {
      return false;
    }
    foundTargetNode = foundTargetNode || currentNodeId === targetNodeId;
    const linksFromCurrent = links.filter(
      (link) => link.from === currentNodeId
    );
    if (linksFromCurrent.some((link) => link.to === sourceNodeId)) {
      return foundTargetNode;
    } else if (visitedNodeIds.has(currentNodeId)) {
      return false;
    }
    visitedNodeIds.add(currentNodeId);

    return linksFromCurrent.some((link) =>
      this.IsSourceToTargetABacklink(
        links,
        link.to,
        sourceNodeId,
        targetNodeId,
        foundTargetNode,
        visitedNodeIds
      )
    );
  }

  private getText(node: Node) {
    return (
      Array.from(node.childNodes)
        .find((n) => n.nodeName === 'Text')
        ?.textContent.trim()
        .replaceAll(/redirect/gi, 're-direct')
        .replaceAll('\r\n', ' ')
        .replaceAll('\n', ' ') || ''
    );
  }

  private getSwimlaneName(node: Node) {
    const properties = Array.from(node.childNodes).filter(
      (child) =>
        child.nodeName === 'Section' &&
        (child as Element).getAttribute('N') === 'Property'
    );
    const swimlaneName = properties
      .flatMap((property) =>
        Array.from(property.childNodes).filter(
          (child) => child.nodeName === 'Row'
        )
      )
      .flatMap((row) =>
        Array.from(row.childNodes).filter((child) => child.nodeName === 'Cell')
      )
      .map((cell) => (cell as Element).getAttribute('V'))
      .filter((v) => v);
    if (swimlaneName.length === 0) {
      return undefined;
    }
    return swimlaneName[0];
  }

  private createSwimLaneNodes = (nodes: ISwimlaneModelNode[]) => {
    const descendingSwimlaneNames = new Set<string>([
      ...nodes
        .toSorted((a, b) => this.getLocation(a).y - this.getLocation(b).y)
        .map((node) => node.swimlane)
        .filter((name) => name)
    ]);
    if (descendingSwimlaneNames.size === 0) {
      return;
    }
    const X_SPACING = 200;
    const Y_SPACING = 250;
    const nodeLocations = nodes.map((node) => this.getLocation(node));
    let yOffset = 100;
    const poolY =
      nodeLocations.map((location) => location.y).sort((a, b) => a - b)[0] -
      yOffset;
    const poolX =
      nodeLocations.map((location) => location.x).sort((a, b) => a - b)[0] -
      X_SPACING;
    const poolNode: IModelNode = {
      key: ++this.maxKey,
      text: 'Pool',
      isGroup: 'true',
      category: NodeCategory.POOL,
      loc: `${poolX} ${poolY}`,
      id: this.guidService.new()
    };
    nodes.push(poolNode);
    const laneWidth =
      nodeLocations
        .map((location) => location.x)
        .sort((a, b) => a - b)
        .at(-1) +
      X_SPACING -
      poolX;
    let totalLaneHeight = 0;
    for (const name of descendingSwimlaneNames) {
      const nodesInSwimlane = nodes.filter((n) => n.swimlane === name);
      const laneNodeLocations = nodesInSwimlane.map((n) => this.getLocation(n));
      const laneYCoordinates = laneNodeLocations
        .map((location) => location.y)
        .sort((a, b) => a - b);
      const laneHeight =
        Y_SPACING +
        (laneYCoordinates.at(-1) - laneYCoordinates[0] + 1) +
        yOffset;
      yOffset = 0;
      const laneNode: IModelNode = {
        key: ++this.maxKey,
        text: name,
        isGroup: 'true',
        group: poolNode.key,
        category: NodeCategory.LANE,
        loc: `${poolX} ${poolY + totalLaneHeight}`,
        size: `${laneWidth} ${laneHeight}`,
        id: this.guidService.new()
      };
      nodesInSwimlane.forEach((n) => (n.group = laneNode.key));
      nodes.push(laneNode);
      totalLaneHeight += laneHeight;
    }
    nodes.forEach((node) => delete node.swimlane);
  };

  private createModelNodeComponent(shape: Node) {
    const location = this.getLocationForNode(shape);
    const modelNode: ISwimlaneModelNode = {
      key: parseInt((shape as Element).getAttribute('ID')),
      category: NodeCategory.COMPONENT,
      taskType: TaskType.COMPONENT,
      loc: `${location.x} ${location.y}`,
      id: this.guidService.new(),
      text: this.getText(shape),
      swimlane: this.getSwimlaneName(shape)
    };
    this.maxKey = Math.max(this.maxKey, modelNode.key);
    return modelNode;
  }

  private createStartAndEndNodes(importable: ImportableFlowModel) {
    const links = importable.modelLinks;
    for (const node of importable.modelNodes) {
      if (node.category === NodeCategory.EVENT) {
        continue;
      }
      if (!links.some((link) => link.from === node.key)) {
        const locationParts = node.loc.split(' ').map((part) => parseInt(part));
        const endNode = this.createEndNodeForLocation(
          locationParts[0] + 180,
          locationParts[1] + 100,
          ++this.maxKey
        );
        importable.modelNodes.push(endNode);
        const link = this.createLink(node.key, endNode.key, null);
        importable.modelLinks.push(link);
      } else if (!links.some((link) => link.to === node.key)) {
        const locParts = node.loc.split(' ').map((a) => parseInt(a));
        const startNode = this.createStartNodeForLocation(
          locParts[0] - 250,
          locParts[1],
          ++this.maxKey
        );
        importable.modelNodes.push(startNode);
        const link = this.createLink(startNode.key, node.key, null);
        importable.modelLinks.push(link);
      }
    }
  }

  private createModelNodeStep(shape: Node) {
    const location = this.getLocationForNode(shape);
    const modelNode: ISwimlaneModelNode = {
      key: parseInt((shape as Element).getAttribute('ID')),
      id: this.guidService.new(),
      category: NodeCategory.ACTIVITY,
      actor: DynamicActorTypeNames.INITIATOR,
      actorName: DynamicActorTypeNames.INITIATOR,
      avatarUrl: this.avatarService.getModelerNodeAvatarUrl(
        null,
        DynamicActorTypeNames.INITIATOR
      ),
      actorType: ActorTypes.DYNAMIC,
      dynamicActorType: DynamicActorTypeIds.ASSIGNED_INITIATOR,
      text: this.getText(shape),
      item: 'generic task',
      taskType: TaskType.TASK,
      loc: `${location.x} ${location.y}`,
      NotifyOnStepCreated: true,
      isNew: false,
      displayNotificationIcon: 'false',
      swimlane: this.getSwimlaneName(shape)
    };
    this.maxKey = Math.max(this.maxKey, modelNode.key);
    return modelNode;
  }

  private createModelNodeApproval(shape: Node) {
    const location = this.getLocationForNode(shape);
    const modelNode: ISwimlaneModelNode = {
      key: parseInt((shape as Element).getAttribute('ID')),
      id: this.guidService.new(),
      category: NodeCategory.ACTIVITY,
      actor: DynamicActorTypeNames.INITIATOR,
      actorName: DynamicActorTypeNames.INITIATOR,
      avatarUrl: this.avatarService.getModelerNodeAvatarUrl(
        null,
        DynamicActorTypeNames.INITIATOR
      ),
      actorType: ActorTypes.DYNAMIC,
      dynamicActorType: DynamicActorTypeIds.ASSIGNED_INITIATOR,
      text: this.getText(shape),
      item: 'generic task',
      taskType: TaskType.APPROVAL,
      loc: `${location.x} ${location.y}`,
      NotifyOnStepCreated: true,
      isNew: false,
      displayNotificationIcon: 'false',
      swimlane: this.getSwimlaneName(shape)
    };
    this.maxKey = Math.max(this.maxKey, modelNode.key);
    return modelNode;
  }

  private createModelNodeDecision(shape: Node) {
    const location = this.getLocationForNode(shape);
    const decision: ISwimlaneModelNode = {
      key: parseInt((shape as Element).getAttribute('ID')),
      id: this.guidService.new(),
      category: NodeCategory.EXCLUSIVE_GATEWAY,
      text: this.getText(shape),
      loc: `${location.x} ${location.y}`,
      allowNamingLinks: false,
      gateway: {
        fieldId: null,
        dbName: null,
        dbFieldName: null,
        gates: []
      },
      swimlane: this.getSwimlaneName(shape)
    };
    this.maxKey = Math.max(this.maxKey, decision.key);
    return decision;
  }

  private getLocationForNode(node: Node) {
    const childNodes = Array.from(node.childNodes);
    let x = 0;
    let y = 0;
    for (const child of childNodes) {
      if (child.nodeName !== 'Cell') {
        continue;
      }
      const childElement = child as Element;
      const nAttribute = childElement.getAttribute('N');
      if (nAttribute === 'PinX') {
        x = parseFloat(childElement.getAttribute('V'));
      } else if (nAttribute === 'PinY') {
        y = parseFloat(childElement.getAttribute('V'));
      }
    }
    return { x: Math.floor(x * 180), y: -Math.floor(y * 180) };
  }

  private createFlowinglyNode(modelNode: IModelNode, nodeArrayIndex: number) {
    const flowinglyNode: INodeCommandData = {
      ActorName: DynamicActorTypeNames.INITIATOR,
      AssignedInitiator: true,
      AssignedInitiatorManager: false,
      AssignedPreviousActor: false,
      AvatarUrl: this.avatarService.getModelerNodeAvatarUrl(
        null,
        DynamicActorTypeNames.INITIATOR
      ),
      Card: {
        formElements: []
      } as IRunnerCard,
      DynamicActorType: DynamicActorTypeIds.ASSIGNED_INITIATOR,
      FlowModelId: this.guidService.empty(),
      Id: this.guidService.new(),
      IsFirstNode: false,
      IsLastNode: false,
      IsPublicForm: false,
      MaxNumberOfApproversRequired: 0,
      ModelerNodeId: modelNode.id,
      NodeDataArrayIndex: nodeArrayIndex,
      NotifyOnStepCreated: true,
      NumberOfApproversRequired: 0,
      NumberOfApproversRequiredType: 0,
      PublicFormType: 0,
      RefSequence: '',
      SelectedApprovers: [],
      SelectedDynamicActors: [],
      StepName: modelNode.text,
      StepType: modelNode.taskType,
      UserNotificationRequest: false,
      WhenApproversSelected: 0,
      isDeleted: false,
      NodeCustomEmail: null
    };
    return flowinglyNode;
  }

  private getLocation(node: IModelNode) {
    const parts = node.loc.split(' ');
    return {
      x: parseInt(parts[0]),
      y: parseInt(parts[1])
    };
  }

  private getLinksFromShapes(
    shapesNode: Node,
    importable: ImportableFlowModel
  ) {
    const shapeNodes = Array.from(shapesNode.childNodes);
    for (const shape of shapeNodes) {
      const shapeChildNodes = Array.from(shape.childNodes);
      const childShapesNode = shapeChildNodes.find(
        (node) => node.nodeName === 'Shapes'
      );
      if (childShapesNode) {
        this.getLinksFromShapes(childShapesNode, importable);
      }
      const begTrigger = shapeChildNodes.find(
        (node) =>
          node.nodeName === 'Cell' &&
          (node as Element).getAttribute('N') === 'BegTrigger'
      ) as Element;
      const endTrigger = shapeChildNodes.find(
        (node) =>
          node.nodeName === 'Cell' &&
          (node as Element).getAttribute('N') === 'EndTrigger'
      ) as Element;
      if (!begTrigger || !endTrigger) {
        continue;
      }
      const text = this.getText(shape);
      const fromId = parseInt(
        /\(Sheet.([0-9]+)!/.exec(begTrigger.getAttribute('F'))?.at(1)
      );
      const toId = parseInt(
        /\(Sheet.([0-9]+)!/.exec(endTrigger.getAttribute('F'))?.at(1)
      );
      const link = this.createLink(fromId, toId, text);
      importable.modelLinks.push(link);
    }
  }

  private createLink(fromKey: number, toKey: number, text: string) {
    return {
      from: fromKey,
      to: toKey,
      text: text,
      visible: !!text,
      Trigger: {
        Type: 'Auto',
        NameRef: 'GatewayDecisionCommand'
      },
      Conditions: [
        {
          Type: 'Action',
          ConditionInversion: 'false',
          NameRef: 'ExclusiveGatewayImplementation'
        }
      ]
    } as IModelNodeLink;
  }
}
