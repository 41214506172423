import React, { useEffect, useState } from 'react';
import CategoryNavigation from '@Client/runner.nav.categories/components/CategoryNavigation';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import CategoryNavigationMobile from '@Client/runner.nav.categories/components/CategoryNavigationMobile';
import ICategoryDetail from '@Shared.Angular/@types/core/contracts/queryModel/category/categoryDetail';

type Props = {
  isMobile: boolean;
  categorisedItems: ICategoryDetail[];
  allCategoriesId: string;
  recentlyViewed?: string;
  selectedCategory: string;
  onCategoryClick: (id: string, index?: string) => void;
  onFlowModelListClick: (id: string) => void;
  selectedIndex: string;
  expandedCategory?: string[];
};
const Category = (props: Props) => {
  const {
    categorisedItems: categorisedProcessMaps,
    isMobile,
    allCategoriesId,
    recentlyViewed,
    selectedCategory,
    onCategoryClick,
    selectedIndex,
    expandedCategory
  } = props;

  const [showSubCategories, setShowSubCategories] = useState(false);

  const appConfig = useService<SharedAngular.APP_CONFIG>('APP_CONFIG');

  const categories = [
    { text: 'All', id: allCategoriesId },
    ...(recentlyViewed
      ? [{ text: 'Recently Viewed', id: recentlyViewed }]
      : []),
    ...categorisedProcessMaps
  ];

  const allCategoriesIndex = '0';
  const recentlyViewedIndex = '1';

  useEffect(() => {
    if (appConfig) {
      setShowSubCategories(appConfig.enableSubCategories);
    }
  }, [appConfig]);

  return (
    <div>
      {isMobile && (
        <div className="imin-groupsandfilters m-0 mr-30 row p-0 runner-contents-area mt-40">
          <div className="col s10 m6 l4 p-0">
            <div className="row valign-wrapper mt-10 mb-0 mr-0 ml-15">
              {showSubCategories ? (
                <CategoryNavigationMobile
                  categories={categories}
                  categoryClicked={onCategoryClick}
                />
              ) : (
                <select
                  className="col s6 m6 l12 browser-default select"
                  value={selectedCategory}
                  onChange={(e) => onCategoryClick(e.target.value)}
                >
                  <option value={allCategoriesId}>All Categories</option>
                  {categorisedProcessMaps.map((category) => (
                    <option key={category.name} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
      )}

      {!isMobile && (
        <div className="categories-col">
          <h4 className="pl-15">
            <b>Categories</b>
          </h4>
          {showSubCategories ? (
            <CategoryNavigation
              categories={categories}
              categoryClicked={onCategoryClick}
              selectedIndex={selectedIndex}
              expandedCategory={expandedCategory}
            />
          ) : (
            categorisedProcessMaps.length > 0 && (
              <ul className="p-0 categories-list" id="categorylist">
                <li
                  className={`pt-5 pb-5 categories-list-item ${
                    selectedCategory === allCategoriesId
                      ? 'selected-category'
                      : ''
                  }`}
                  title="All"
                  onClick={() =>
                    onCategoryClick(allCategoriesId, allCategoriesIndex)
                  }
                >
                  All
                </li>
                {recentlyViewed && (
                  <li
                    className={`pt-5 pb-5 categories-list-item ${
                      selectedCategory === recentlyViewed
                        ? 'selected-category'
                        : ''
                    }`}
                    title="Recently Viewed"
                    onClick={() =>
                      onCategoryClick(recentlyViewed, recentlyViewedIndex)
                    }
                  >
                    Recently Viewed
                  </li>
                )}
                {categorisedProcessMaps.map((category, index) => (
                  <li
                    key={category.id}
                    className={`pt-5 pb-5 categories-list-item ${
                      selectedCategory === category.id
                        ? 'selected-category'
                        : ''
                    }`}
                    onClick={() => onCategoryClick(category.id, String(index))}
                  >
                    {category.name}
                  </li>
                ))}
              </ul>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Category;
