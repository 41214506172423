import { SharedAngular } from '@Client/@types/sharedAngular';
import {
  DeleteEntityTypes,
  CategoryDeleteWarningStatus
} from '@Shared.Angular/flowingly.services/flowingly.constants';

angular
  .module('flowingly.runner.setup')
  .controller('setupDeleteDialogController', setupDeleteDialogController);

setupDeleteDialogController.$inject = [
  '$scope',
  '$q',
  'ngDialog',
  'setupDeleteApiService',
  'lodashService',
  'categoryApiService'
];

// This controller is for the reusable delete dialog.
// The purpose of this dialog is
// * to warn users about the implications of their delete
// * make them choose replacements for the actors being deleted
// * confirm the delete by typing the word delete
//
// Once completed, control is returned to the caller to perform the delete.

function setupDeleteDialogController(
  $scope,
  $q,
  ngDialog,
  setupDeleteApiService,
  lodashService,
  categoryApiService: SharedAngular.CategoryApiService
) {
  const ctrl = this;

  ctrl.reassignActorRequired = false;
  ctrl.reassignManagerRequired = false;
  ctrl.reassignCustomDatabaseRequired = false;
  ctrl.reassignCategoryApproverRequired = false;
  ctrl.confirmed = false;
  ctrl.allActorsChosen = false;
  ctrl.canDelete = false;
  ctrl.actors = []; // list of actors, selected by the user to return to the caller
  ctrl.warnings = []; // list of warnings to display to the user
  ctrl.users = []; // list of users the user can choose from
  ctrl.teams = []; // list of teams the user can choose from

  ctrl.itemToDelete = $scope.ngDialogData.itemToDelete; // the item the user wants to delete (user/team/category)
  ctrl.entityType = $scope.ngDialogData.entityType; // the type of the itemToDelete
  ctrl.entityName = ctrl.itemToDelete.name || ctrl.itemToDelete.fullName; // the name of the type of the itemToDelete

  ctrl.closeDialog = closeDialog;
  ctrl.onDeleteConfirmed = onDeleteConfirmed;
  ctrl.onActorChosen = onActorChosen;
  ctrl.submitDelete = submitDelete;

  init(ctrl.itemToDelete);

  ////////// PUBLIC METHODS

  function submitDelete() {
    // close the dialog, returning the actors chosen by the user (submit delete)
    if (ctrl.entityType === DeleteEntityTypes.userEntityType) {
      ngDialog.closeAll({
        actor: ctrl.actors.actor,
        manager: ctrl.actors.manager,
        customdatabaseactor: ctrl.actors.customdatabaseactor,
        categoryApprover: ctrl.actors.categoryApprover
      });
    } else {
      ngDialog.closeAll({ actor: ctrl.actors.actor });
    }
  }

  function closeDialog() {
    // cancel the delete
    ngDialog.closeAll(undefined);
  }

  function onDeleteConfirmed(confirmed) {
    // user has type the confirmation phrase (delete)
    const noReassignRequired =
      !ctrl.reassignActorRequired &&
      !ctrl.reassignManagerRequired &&
      !ctrl.reassignCustomDatabaseRequired &&
      !ctrl.reassignCategoryApproverRequired;
    ctrl.confirmed = confirmed;
    ctrl.canDelete =
      ctrl.confirmed && (noReassignRequired === true || ctrl.allActorsChosen);
  }

  function onActorChosen(actors) {
    // user has chosen a new actor from dropdowns
    ctrl.actors = actors;

    if (ctrl.entityType === DeleteEntityTypes.userEntityType) {
      //USERS
      //if user is any flows, they need to be resassigned to a new actor (user/team)
      let hasSelectedActor = true;
      let hasSelectedManager = true;
      let hasSelectedCustomDatabaseActor = true;
      let hasSelectedCategoryApprover = true;

      if (ctrl.reassignActorRequired) {
        hasSelectedActor = actors.actor !== undefined;
      }

      if (ctrl.reassignManagerRequired) {
        hasSelectedManager = actors.manager !== undefined;
      }

      if (ctrl.reassignCustomDatabaseRequired) {
        hasSelectedCustomDatabaseActor =
          actors.customdatabaseactor !== undefined;
      }

      if (ctrl.reassignCategoryApproverRequired) {
        hasSelectedCategoryApprover = actors.categoryApprover !== undefined;
      }

      ctrl.allActorsChosen =
        hasSelectedActor &&
        hasSelectedManager &&
        hasSelectedCustomDatabaseActor &&
        hasSelectedCategoryApprover;
    } else {
      ctrl.allActorsChosen = actors.actor;
    }

    ctrl.canDelete = ctrl.confirmed && ctrl.allActorsChosen;
  }

  ///////// PRIVATE METHODS
  function init(itemToDelete) {
    ctrl.entityTypeName = setEntityTypeName(ctrl.entityType);
    ctrl.loading = true;

    const warningsAreLoaded = setupDeleteApiService
      .getWarnings(ctrl.entityType, itemToDelete)
      .then((warnings) => {
        if (ctrl.entityType === DeleteEntityTypes.categoryEntityType) {
          const message =
            warnings.flowModelCount +
            ' Flow Models will be reassigned to a new category';
          //warning is a simple string - if the string is not empty then reassignment required
          ctrl.warnings =
            warnings.status === CategoryDeleteWarningStatus.reassignCategory
              ? [message]
              : [];
          ctrl.reassignActorRequired = ctrl.warnings.length > 0;
        } else {
          //for all other types will be an object
          angular.copy(warnings.warnings, ctrl.warnings);
          ctrl.reassignActorRequired = warnings.reassignActorRequired;
          ctrl.reassignManagerRequired = warnings.reassignManagerRequired;
          ctrl.reassignCustomDatabaseRequired =
            warnings.reassignCustomDatabaseRequired;
          ctrl.reassignCategoryApproverRequired =
            warnings.reassignCategoryApproverRequired;
        }
      });
    const pendingPromises = [warningsAreLoaded];

    if (
      ctrl.entityType === DeleteEntityTypes.teamEntityType ||
      ctrl.entityType === DeleteEntityTypes.userEntityType
    ) {
      const teamsAreLoaded = setupDeleteApiService.getTeams().then((teams) => {
        teams = filterOutItemToDelete(teams);
        if (teams.length !== 0) {
          teams = sortAlphabetically(teams, DeleteEntityTypes.teamEntityType);
        }
        ctrl.teams = angular.copy(teams);
      });
      pendingPromises.push(teamsAreLoaded);
    } else if (ctrl.entityType === DeleteEntityTypes.categoryEntityType) {
      const categoriesAreLoaded = categoryApiService
        .getCategories()
        .then((cats) => {
          cats = filterOutItemToDelete(cats);
          cats = sortAlphabetically(cats, DeleteEntityTypes.categoryEntityType);
          ctrl.categories = angular.copy(cats);
        });
      pendingPromises.push(categoriesAreLoaded);
    }
    $q.all(pendingPromises).then(() => {
      ctrl.loading = false;
    });
  }

  function filterOutItemToDelete(actors) {
    return actors.filter((d) => {
      return d.id !== ctrl.itemToDelete.id;
    });
  }

  function sortAlphabetically(actors, entityType) {
    if (entityType === DeleteEntityTypes.userEntityType) {
      return lodashService.sortBy(actors, 'fullName');
    } else {
      return lodashService.sortBy(actors, 'name');
    }
  }

  function setEntityTypeName(type) {
    switch (type) {
      case DeleteEntityTypes.userEntityType:
        return 'User';

      case DeleteEntityTypes.teamEntityType:
        return 'Team';

      case DeleteEntityTypes.categoryEntityType:
        return 'Category';

      case DeleteEntityTypes.flowModelEntityType:
        return 'Flow Model';

      case DeleteEntityTypes.databaseEntityType:
        return 'Database';

      case DeleteEntityTypes.roleEntityType:
        return 'Role';
    }
  }
}
