/**
 * @ngdoc directive
 * @name flowReportAllDataItems
 * @module flowingly.runner.report
 * @description  This component is used to display the Flow All Data Report.
 * @usage
 * ```
     <flow-report-all-data-items ng-show="$ctrl.report.expanded" flow="$ctrl.flow" report="$ctrl.report"></flow-report-all-data-items>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Report+Components
 * ### Properties
 * #### Inputs
 * * flow: The id of the flow for the report to display
 * * name: The flow name
 * * report: the report to display
 */

import angular, {
  IDocumentService,
  IHttpService,
  ILocationService,
  IQService,
  IRootScopeService,
  ISCEService,
  IScope,
  ITimeoutService,
  IWindowService
} from 'angular';
import * as FlowinglyKendo from '../../interfaces/flowingly.kendo';
import {
  IPageResult,
  IVersion,
  IPagination
} from '../../interfaces/flowingly.pagination';
import ReportsApiService from '../../runner.services/reports.api.service';
import {
  IReportFlowData,
  ReportColumnDatatypesEnum,
  ISearchOptions
} from '../../interfaces/flowingly.report';
import { IMap } from '../../interfaces/util';

import { SharedAngular } from '@Client/@types/sharedAngular';
import { IStateService } from 'angular-ui-router';
import { Guid } from '@Shared.Angular/@types/guid';

export class ReportAllDataItemsController implements angular.IController {
  static $inject = [
    '$rootScope',
    '$location',
    '$log',
    '$scope',
    '$state',
    '$window',
    '$document',
    '$timeout',
    'APP_CONFIG',
    'intercomService',
    'reportsApiService',
    'reportsUtilityService',
    'browserUtilsService',
    'lodashService',
    '$sce',
    'momentService',
    'usSpinnerService',
    'notificationService',
    'he',
    'lodashService',
    'flowModelApiService',
    '$q',
    'kendoService',
    '$http',
    'fileService',
    'flowinglyMomentService',
    'brandingService'
  ];

  constructor(
    public $rootScope: IRootScopeService,
    public $location: ILocationService,
    public $log: SharedAngular.DevLoggingService,
    public $scope: IScope,
    public $state: IStateService,
    public $window: IWindowService,
    public $document: IDocumentService,
    public $timeout: ITimeoutService,
    public APP_CONFIG: SharedAngular.APP_CONFIG,
    public intercomService: SharedAngular.IntercomService,
    public reportsApiService: ReportsApiService,
    public reportsUtilityService: ReportsUtilityService,
    public browserUtilsService: SharedAngular.BrowserUtilsService,
    public lodashService: Lodash,
    public $sce: ISCEService,
    public moment: Moment,
    public usSpinnerService: IUsSpinnerService,
    public notificationService: SharedAngular.NotificationService,
    public he: He,
    public _: Lodash,
    public flowModelApiService: SharedAngular.FlowModelApiService,
    public $q: IQService,
    public kendoService: Kendo,
    public $http: IHttpService,
    public fileService: SharedAngular.FileService,
    public flowinglyMomentService: SharedAngular.FlowinglyMomentService,
    public brandingService: SharedAngular.BrandingService
  ) {
    this.updateStateVariable = this.updateStateVariable.bind(this);
    this.dbFormat = this.moment.defaultFormatUtc;
  }
  // from bindings
  private flowModelId: Guid;
  private endDateOverride: any;
  private startDateOverride: any;

  // from self
  public isInFlight = false;
  public isReady = false;
  public noRows = false;
  public startDate: any;
  public endDate: any;
  public dateFormat = 'DD/MM/YYYY hh:mm A';
  public dateFormatDateOnly = 'DD/MM/YYYY';
  public fullDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  public dbFormat: string;
  public datePickerOpts = {
    format: 'dd/MM/yyyy hh:mm tt' /* kendo format is not the same as moment */,
    interval: 5
  };
  public autoFit = false; // disable autoFit as it has performance hit on the report loading
  public name = '';
  public kendoDs: any;
  public versions: any;
  public aVersionIsChecked = true;
  public helpUri: string;
  public gridFieldMap: IMap<IGridField> = {
    flowCategory: { type: ReportColumnDatatypesEnum.string },
    flowModel: { type: ReportColumnDatatypesEnum.string },
    flowId: { type: ReportColumnDatatypesEnum.string },
    flowSubject: { type: ReportColumnDatatypesEnum.string },
    status: { type: ReportColumnDatatypesEnum.string },
    daysOverdue: { type: ReportColumnDatatypesEnum.number },
    waitingOn: { type: ReportColumnDatatypesEnum.string },
    lastUpdatedBy: { type: ReportColumnDatatypesEnum.string },
    lastUpdatedDate: { type: ReportColumnDatatypesEnum.string },
    startedBy: { type: ReportColumnDatatypesEnum.string },
    startedDate: { type: ReportColumnDatatypesEnum.string },
    pendingSteps: { type: ReportColumnDatatypesEnum.number },
    currentSteps: { type: ReportColumnDatatypesEnum.string },
    currentStepDueDate: { type: ReportColumnDatatypesEnum.string },
    flowProgress: { type: ReportColumnDatatypesEnum.number },
    numberOfAttachements: { type: ReportColumnDatatypesEnum.number },
    numberOfComments: { type: ReportColumnDatatypesEnum.number }
  };

  public columns: FlowinglyKendo.KendoGridColDef[] = [
    {
      field: 'flowIdentifier',
      width: 150,
      locked: false,
      title: 'Flow ID',
      sortable: true,
      filterable: {
        cell: {
          showOperators: false,
          template: (args) => {
            args.element.attr({
              class: 'k-inputlike',
              placeholder: `Search by Flow ID`
            });
          }
        }
      },
      headerAttributes: {
        title: 'Flow ID'
      },
      template: ({ flowIdentifier, flowId }) => {
        return `<a href="/flows/${flowId}">${flowIdentifier}</a>`;
      }
    },
    {
      field: 'flowVersion',
      width: 130,
      locked: false,
      title: 'Flow Version',
      sortable: true,
      headerAttributes: {
        title: 'The version of the flow model when a flow was started'
      },
      filterable: {
        cell: {
          showOperators: false,
          template: (args) => {
            args.element.attr({
              class: 'k-inputlike',
              placeholder: `Search by Flow Version`
            });
          }
        }
      }
    },
    {
      field: 'subject',
      width: 200,
      locked: false,
      title: 'Subject',
      sortable: true,
      headerAttributes: {
        title: 'Flow Subject'
      },
      filterable: {
        cell: {
          showOperators: false,
          template: (args) => {
            args.element.attr({
              class: 'k-inputlike',
              placeholder: `Search by Subject`
            });
          }
        }
      }
    },
    {
      field: 'startedBy',
      width: 150,
      locked: false,
      title: 'Started by',
      sortable: true,
      filterable: {
        cell: {
          showOperators: false,
          template: (args) => {
            args.element.attr({
              class: 'k-inputlike',
              placeholder: `Search by Started by`
            });
          }
        }
      },
      headerAttributes: {
        title: 'User who started the Flow'
      }
    },
    {
      field: 'startedDate',
      width: 150,
      locked: false,
      title: 'Started date',
      sortable: true,
      filterable: false,
      template: ({ startedDate }) => {
        if (this.APP_CONFIG.showReportDatesInUtc) {
          return (
            '<span title="' +
            this.moment(startedDate).format(this.fullDateTimeFormat) +
            '">' +
            this.moment(startedDate).format(this.dateFormatDateOnly) +
            '</span>'
          );
        }
        return (
          '<span title="' +
          this.flowinglyMomentService.formatFullDate(
            startedDate,
            this.fullDateTimeFormat
          ) +
          '">' +
          this.flowinglyMomentService.formatFullDate(
            startedDate,
            this.dateFormatDateOnly
          ) +
          '</span>'
        );
      },
      headerAttributes: {
        title: 'Flow start date'
      }
    },
    {
      field: 'status',
      width: 150,
      locked: false,
      title: 'Status',
      sortable: true,
      headerAttributes: {
        title: 'Flow Status'
      },
      filterable: {
        cell: {
          showOperators: false,
          template: (args) => {
            args.element.attr({
              class: 'k-inputlike',
              placeholder: `Search by Status`
            });
          }
        }
      }
    },
    {
      field: 'flowProgress',
      width: 150,
      locked: false,
      title: 'Flow Progress',
      sortable: true,
      filterable: false,
      headerAttributes: {
        title: 'Flow Progress'
      },
      template: ({ flowProgress }) => {
        return '<span>' + (flowProgress || 0) + '%</span>';
      }
    },
    {
      field: 'lastUpdatedBy',
      width: 150,
      locked: false,
      title: 'Last updated by',
      sortable: false,
      filterable: false,
      headerAttributes: {
        title: 'The user who last updated the Flow'
      }
    },
    {
      field: 'lastUpdatedDate',
      width: 150,
      locked: false,
      title: 'Last updated date',
      sortable: false,
      filterable: false,
      template: ({ lastUpdatedDate }) => {
        if (this.APP_CONFIG.showReportDatesInUtc) {
          return (
            '<span title="' +
            this.moment(lastUpdatedDate).format(this.fullDateTimeFormat) +
            '">' +
            this.moment(lastUpdatedDate).format(this.dateFormatDateOnly) +
            '</span>'
          );
        }
        return (
          '<span title="' +
          this.flowinglyMomentService.formatFullDate(
            lastUpdatedDate,
            this.fullDateTimeFormat
          ) +
          '">' +
          this.flowinglyMomentService.formatFullDate(
            lastUpdatedDate,
            this.dateFormatDateOnly
          ) +
          '</span>'
        );
      },
      headerAttributes: {
        title: 'The date a Flow was last updated'
      }
    },
    {
      field: 'daysOverdue',
      width: 150,
      locked: false,
      title: 'Days Overdue',
      sortable: false,
      filterable: false,
      headerAttributes: {
        title:
          'Number of days by which the most overdue current step is exceeding its due date.'
      },
      template: ({ daysOverdue }) => {
        // business logic if > 5 days round up to the whole number see FLOW-4682
        // eles stay at 2 decimal points if applicable
        if (daysOverdue > 5) {
          return '<span>' + Math.round(daysOverdue) + '</span>';
        } else {
          const temp = daysOverdue;
          if (temp % 1 > 0) {
            return '<span>' + daysOverdue.toFixed(1) + '</span>';
          } else {
            return '<span>' + daysOverdue + '</span>';
          }
        }
      }
    },
    {
      field: 'waitingOn',
      width: 150,
      locked: false,
      title: 'Waiting on',
      sortable: false,
      filterable: false,
      headerAttributes: {
        title: 'Users assigned to a current step'
      }
    },
    {
      field: 'numberOfCurrentSteps',
      width: 150,
      locked: false,
      title: 'Number of Current Steps',
      sortable: false,
      filterable: false,
      headerAttributes: {
        title: 'Number of current steps'
      }
    },
    {
      field: 'currentSteps',
      width: 150,
      locked: false,
      title: 'Current steps',
      sortable: false,
      filterable: false,
      headerAttributes: {
        title: 'Names of current steps'
      }
    },
    {
      field: 'currentStepDueDate',
      width: 150,
      locked: false,
      title: 'Current Step Due Date',
      sortable: false,
      filterable: false,
      headerAttributes: {
        title: 'The earliest due date of the current step(s).'
      },
      template: ({ currentStepDueDate }) => {
        if (currentStepDueDate) {
          if (this.APP_CONFIG.showReportDatesInUtc) {
            return (
              '<span>' +
              this.moment(currentStepDueDate).format(this.dateFormatDateOnly) +
              '</span>'
            );
          }
          return (
            '<span>' +
            this.flowinglyMomentService.formatFullDate(
              currentStepDueDate,
              this.dateFormatDateOnly
            ) +
            '</span>'
          );
        } else {
          return '';
        }
      }
    },
    {
      field: 'numberOfAttachements',
      width: 180,
      locked: false,
      title: 'Attachments',
      sortable: false,
      filterable: false,
      headerAttributes: {
        title: 'Number of attachments'
      }
    },
    {
      field: 'numberOfComments',
      width: 180,
      locked: false,
      title: 'Comments',
      sortable: false,
      filterable: false,
      headerAttributes: {
        title: 'Number of comments'
      }
    }
  ];

  public kendoGridOptions: any;

  $onInit() {
    return this.flowModelApiService
      .getFlowModelById(this.flowModelId, true)
      .then(({ name }) => {
        this.name = name;
        this.$document.title = 'Report - ' + name;
      })
      .then(() => {
        this.actualInit();
        this.intercomService.trackEvent('Run Report', {
          'report name': this.name
        });
        $('.hidediv').show();
      });
  }

  public actualInit() {
    const { moment } = this;

    /*
     * Start and End dates will by default be TODAY minus the date range however the date filters can be
     * overriden via query param so when the page is visited via pressing the browser back button or
     * just passing the URL, we can "memorize" the date range.
     *
     * Example
     *      http://server/report/SOME_GUID                                  starts at TODAY -  3 days
     *      http://server/report/SOME_GUID&startDateOverride=06/26/1992     starts at that date - 3 days
     */
    if (
      this.startDateOverride &&
      moment(this.startDateOverride, this.dateFormat).isValid()
    ) {
      this.startDate = moment(this.startDateOverride, this.dateFormat).format(
        this.dateFormat
      );
    } else {
      this.startDate = moment()
        .subtract(2, 'days')
        .startOf('day')
        .format(this.dateFormat);
    }

    if (
      this.endDateOverride &&
      moment(this.endDateOverride, this.dateFormat).isValid()
    ) {
      this.endDate = moment(this.endDateOverride, this.dateFormat).format(
        this.dateFormat
      );
    } else {
      this.endDate = moment().endOf('day').format(this.dateFormat);
    }

    this.helpUri = this.brandingService.getBrandedHelpUri(
      'https://help.flowingly.net/coming-soon/how-do-i-filter-export-reports'
    );

    this.initializeKendoGrid();
    this.isReady = true; // kendo-grid is initialized after this
  }

  public applyRangeFilter() {
    this.clearFilters();

    // update the query params without reloading the view
    // so we can give out the link as is (and also use the browser back button)
    this.$state.go(
      '.',
      {
        flowModelId: this.flowModelId,
        endDate: this.endDate,
        startDate: this.startDate
      },
      { notify: false }
    );
  }

  private getDateRangeFilters() {
    return [
      {
        field: 'startedDate',
        operator: FlowinglyKendo.KendoFilterOps.gte,
        value: this.APP_CONFIG.showReportDatesInUtc
          ? this.moment(this.startDate, this.dateFormat).format(this.dbFormat)
          : this.moment(this.startDate, this.dateFormat)
              .utc()
              .format(this.dbFormat)
      },
      {
        field: 'startedDate',
        operator: FlowinglyKendo.KendoFilterOps.lte,
        value: this.APP_CONFIG.showReportDatesInUtc
          ? this.moment(this.endDate, this.dateFormat).format(this.dbFormat)
          : this.moment(this.endDate, this.dateFormat)
              .utc()
              .format(this.dbFormat)
      }
    ];
  }

  /**
   * Copied over from commit cf14f727. One of those cases where we are mixing angular
   * and jquery again. Ugh
   */
  public initializeExportButton() {
    // Initialize export data button
    // need make sure Angular done its digest cycle thus html tags are exist
    this.$timeout(() => {
      const el: any = angular.element('#report-export-menu-opener');
      el.dropdown({
        belowOrigin: true
      });

      return this.versions;
    });
  }

  private fetchReportItems(
    filters: ISearchOptions
  ): angular.IPromise<IPageResult<IReportFlowData>> {
    filters.filter.filters = filters.filter.filters.map((f) => {
      return {
        ...f,
        type: this.gridFieldMap[f.field]
          ? this.gridFieldMap[f.field].type
          : ReportColumnDatatypesEnum.string
      };
    });

    return this.reportsApiService
      .fetchReportByFlowModelId(this.flowModelId, filters)
      .catch((err) => {
        if (err.data.errorCode == 'ERR_0001') {
          this.intercomService.trackEvent('Export Data exceeds maximum', {
            'Flow ID': this.flowModelId
          });
        }

        return {
          versions: {
            version: 0,
            versionString: '',
            versionDate: ''
          } as IVersion,
          payload: [],
          pagination: {
            count: 0,
            pageSize: 0,
            currentPage: 0,
            totalPages: 0
          } as IPagination
        } as IPageResult<IReportFlowData>;
      });
  }

  private initializeKendoGrid() {
    const { $timeout, moment, APP_CONFIG } = this;
    this.kendoDs = new this.kendoService.data.DataSource({
      serverSorting: true,
      serverPaging: true,
      serverFiltering: true,
      transport: {
        read: (options: FlowinglyKendo.IKendoDsTransportResult) => {
          this.setBusy(true);

          // need to wrap in timeout because of (see initDatepickerBind)
          $timeout(() => {
            this.fetchReportItems(options.data as any as ISearchOptions)
              .then((response) => {
                options.success(response), (this.versions = response.versions);
              })
              .finally(() => {
                this.setBusy(false);
              });
          }, 100);
        }
      },
      schema: {
        total: (result: IPageResult<IReportFlowData>) =>
          result.pagination.count,
        data: (result: IPageResult<IReportFlowData>) => result.payload,
        model: {
          fields: this.gridFieldMap
        }
      } as FlowinglyKendo.IKendoDsSchema,
      pageSize: 100,
      //initial filter
      filter: [
        {
          field: 'startedDate',
          operator: FlowinglyKendo.KendoFilterOps.gte,
          value: this.APP_CONFIG.showReportDatesInUtc
            ? moment(this.startDate, this.dateFormat).format(this.dbFormat)
            : moment(this.startDate, this.dateFormat)
                .utc()
                .format(this.dbFormat)
        },
        {
          field: 'startedDate',
          operator: FlowinglyKendo.KendoFilterOps.lte,
          value: this.APP_CONFIG.showReportDatesInUtc
            ? moment(this.endDate, this.dateFormat).format(this.dbFormat)
            : moment(this.endDate, this.dateFormat).utc().format(this.dbFormat)
        }
      ] as FlowinglyKendo.IKendoDsFilterItem[],
      // initial sort
      sort: {
        field: 'startedDate',
        dir: 'desc'
      }
    });

    this.kendoGridOptions = {
      columns: this.columns,
      //columns: [{ field:'status', title:'Status'}],
      dataSource: this.kendoDs,
      noRecords: {
        template:
          "<div class='p-10 pt-30 h-100'>No data available for this report yet.</div>"
      },
      scrollable: true,
      pageable: true,
      sortable: true,
      resizable: true,
      filterable: {
        mode: 'row',
        operators: {
          date: {
            gt: 'After',
            lt: 'Before'
          },
          datetime: {
            gt: 'After',
            lt: 'Before'
          },
          string: {
            contains: 'Contains'
          },
          number: {
            eq: 'Equal to',
            neq: 'Not equal to',
            gte: 'Greater than or equal to',
            gt: 'Greater than',
            lte: 'Less than or equal to',
            lt: 'Less than'
          }
        }
      },
      excelExport: this.handleExcelExportEvent.bind(this),
      pdfExport: this.handlePdfExportEvent.bind(this),
      excel: {
        allPages: false
      },
      pdf: {
        allPages: false
      }
    };
  }

  public showFlow(flowId) {
    this.$state.go('app.runner.flow', { flowId: flowId });
  }

  public exportToExcel() {
    const chosenVersions = [];

    $('.report-ver-checkbox').each(function () {
      if ($('#' + this.id).prop('checked'))
        chosenVersions.push($(this).attr('data-version'));
    });
    const filters = {
      take: this.$window.maxReportReturnRows,
      skip: 0,
      page: 0,
      pageSize: this.$window.maxReportReturnRows,
      sort: [],
      reportVersions: chosenVersions, //FORMAT: [1, 2, 3], [3]
      filter: {
        logic: 'and',
        filters: this.kendoDs.filter().filters
      }
    };

    const targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const combinedParam = { filters, targetTimeZone };
    const param = btoa(encodeURIComponent(JSON.stringify(combinedParam)));
    const url = `${this.APP_CONFIG.apiBaseUrl}reports/${this.flowModelId}/csv?param=${param}`;
    this.fileService.downloadByUrl(url);
  }

  public removeDot(versionString) {
    if (versionString) {
      versionString = versionString.replace('.', '');
    }

    return versionString;
  }

  public exportToPdf() {
    throw new Error('Method not yet implemented');
  }

  public handleExcelExportEvent() {
    throw new Error('Method not yet implemented');
  }
  public handlePdfExportEvent() {
    throw new Error('Method not yet implemented');
  }

  private isValidStartDate() {
    return this.isValidDate(this.startDate) && this.isValidRange();
  }
  private isValidEndDate() {
    return this.isValidDate(this.endDate) && this.isValidRange();
  }

  private isValidDate(_date_) {
    const date = this.moment(_date_.trim(), this.dateFormat);

    if (
      !date.isValid() ||
      !/^\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{1,2} (AM|PM)$/.test(_date_.trim())
    ) {
      this.notificationService.showErrorToast(
        'The date is not in the correct format'
      );
      return false;
    } else {
      return true;
    }
  }

  private isValidRange() {
    const { moment, notificationService } = this;

    const now = moment(this.endDate, this.dateFormat); //todays date
    const end = moment(this.startDate, this.dateFormat); // another date
    const duration = moment.duration(now.diff(end));
    const time = duration.asSeconds();

    if (time < 0) {
      notificationService.showErrorToast(
        'End date must be later than start date'
      );
      return false;
    }

    return true;
  }

  /**
   * atm , they do the exact same thing. Maybe if the apply range filter were not to clear
   * the current search filters itd be different but at this point it has the same behavior
   */
  public clearFilters() {
    this.kendoDs.filter(this.getDateRangeFilters());

    // if there is a non-date input in a date field
    // it does not get physically cleared, only logically
    $('.k-filtercell input').val('');
  }

  // @TODO check if needed
  public getState() {
    return {
      flowModelId: this.flowModelId,
      flowModelName: this.name,
      startDate: this.startDate,
      endDate: this.endDate
    };
  }

  // @TODO check if needed
  public updateStateVariable(...args) {
    this.$state.transitionTo('app.runner.report', this.getState(), {
      notify: false
    });
  }
  /** @TODO check if needed
   *  Moves public-href property to href.
   *
   *  This is because our attachment system is weird af.
   *  Check out the fileService.downloadFileById to see
   *  how its done.
   *
   *  Kendo needs href property to be populted when exporting
   *  to have any attachments appear in the list
   */
  public convertToPublicDownload() {
    $(this).attr('href', $(this).attr('public-href'));
  }

  /** @TODO check if needed
   *  Moves public-href property to href.
   *
   *  This is because our attachment system is weird af.
   *  Check out the fileService.downloadFileById to see
   *  how its done.
   *
   *  To get the file attachnebt, we need to use the flow-download-link directive
   *  else you wont be able to get it. This is because we have no
   *  real session system.
   */
  public revertToPrivateDownload() {
    $(this).attr('href', null);
  }

  public trackExportButtonClick($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.intercomService.trackEvent('Click Export Data', {
      'Flow ID': this.flowModelId
    });
  }

  public trackExportToExcelClick($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.intercomService.trackEvent('Export File Format', {
      'Flow ID': this.flowModelId,
      Format: 'csv'
    });
  }

  public flowVersions() {
    return this.versions;
  }

  public dropdownHeightStyle() {
    const _dropdownCSS = {
      'max-height': window.innerHeight * 0.35 + 'px',
      'overflow-y': 'auto',
      'overflow-x': 'hidden',
      'border-top': '1px solid grey',
      'padding-top': '15px'
    };

    return _dropdownCSS;
  }

  public toggleSelectAll() {
    const isChecked = $('#selectall').prop('checked');
    $('.report-ver-checkbox').prop('checked', isChecked).trigger('change');

    this.funcAVersionIsChecked();
  }

  public updateUISelectAllandExportButton() {
    const isSelectAllIsChecked = $('#selectall').prop('checked');

    //uncheck Select All if any of the versions where manually clicked
    if (isSelectAllIsChecked)
      $('#selectall').prop('checked', false).trigger('change');

    this.funcAVersionIsChecked();
  }

  public funcAVersionIsChecked() {
    let atLeastOneIsChecked = false;

    if (typeof this.versions === 'object' && this.versions.length > 0) {
      this.aVersionIsChecked = true;

      if (!$('#selectall').prop('checked')) {
        //check if any of the individual versions were checked
        $('.report-ver-checkbox').each(function (index) {
          if ($('#' + this.id.replace('.', '')).prop('checked'))
            atLeastOneIsChecked = true;
        });

        this.aVersionIsChecked = atLeastOneIsChecked ? true : false;
      }
    } else this.aVersionIsChecked = false;

    return this.aVersionIsChecked;
  }

  private setBusy(isBusy: boolean) {
    if (isBusy) {
      this.isInFlight = true;
      this.usSpinnerService.spin('spinner-reports');
    } else {
      this.isInFlight = false;
      this.usSpinnerService.stop('spinner-reports');
    }
  }
}

export default class ReportAllDataItemsComponent
  implements angular.IComponentOptions
{
  public bindings: Bindings = {
    flowModelId: '<',
    endDateOverride: '<',
    startDateOverride: '<'
  };
  public controller = ReportAllDataItemsController;
  public templateUrl =
    'Client/runner.report/v1/runner.report.alldataitems.component.tmpl.html';
}

export interface IGridField {
  type: ReportColumnDatatypesEnum;
}

angular
  .module('flowingly.runner.report')
  .component('flowReportAllDataItemsV1', new ReportAllDataItemsComponent());
