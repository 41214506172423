import React, { useEffect, useRef, useState } from 'react';
import FlowModelSummaryComponent from './FlowModelSummary/FlowModelSummary';
import IProcessMap from '@Shared.Angular/@types/processMap';
import { Button, ButtonGroup } from '@mui/material';
import EmptyState from '@Client/runner.directives/common/EmptyState';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { ProcessMapsFilterTabs } from '@Shared.Angular/flowingly.services/flowingly.constants';

interface Props {
  isMobile: boolean;
  processMaps: IProcessMap[];
  onFlowModelClick(id: string): void;
  showMyMapsTab: boolean;
  selectedCategory: string;
}

const ProcessMapList = (props: Props) => {
  const {
    processMaps,
    isMobile,
    onFlowModelClick,
    showMyMapsTab,
    selectedCategory
  } = props;

  let emptyStateSummaryText = '';
  let emptyStateBodyText = '';
  let emptyStateButtonText = '';
  let emptyStateOnButtonClick: (() => void) | undefined = undefined;

  const { maxNumberOfDaysForRecentlyUpdatedMaps, showDashboardsV1 } =
    useService<SharedAngular.APP_CONFIG>('APP_CONFIG');
  const flowsUtilityService = useService<SharedAngular.FlowsUtilityService>(
    'flowsUtilityService'
  );

  const permissionsService =
    useService<SharedAngular.PermissionsService>('permissionsService');
  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');
  const tempModelerUrlService = useService<SharedAngular.TempModelerUrlService>(
    'tempModelerUrlService'
  );

  const canCreateFlowModels = permissionsService.currentUserHasPermission(
    flowinglyConstants.permissions.FLOWMODEL_CREATE
  );

  const maxRecentlyUpdatedDays =
    maxNumberOfDaysForRecentlyUpdatedMaps * 24 * 60 * 60 * 1000;

  const searchInputRef = useRef(null);
  const [selectedButton, setSelectedButton] = useState(
    ProcessMapsFilterTabs.ALL_MAPS_ID
  );
  const [filteredProcessMaps, setFilteredProcessMaps] = useState<IProcessMap[]>(
    []
  );
  const [searchItem, setSearchItem] = useState('');

  if (selectedButton === ProcessMapsFilterTabs.RECENTLY_UPDATED_MAPS_ID) {
    emptyStateSummaryText = `There are no maps updated in the last ${maxNumberOfDaysForRecentlyUpdatedMaps} days`;
    emptyStateBodyText = '';
    emptyStateButtonText = '';
  } else {
    emptyStateSummaryText = canCreateFlowModels
      ? 'You have no Process Maps'
      : 'View Process Maps';
    emptyStateBodyText = canCreateFlowModels
      ? 'We make it easy for you to create, view and share your organizational Process Maps in one place.<br /> You do not currently have any Process Maps published.'
      : 'You do not currently have any Process Maps available to view. <br /> Please contact your administrator to have relevant Process Maps shared with you.';
    emptyStateButtonText = canCreateFlowModels ? 'Publish a Process Map' : '';
    emptyStateOnButtonClick = canCreateFlowModels
      ? () => tempModelerUrlService.openModeler()
      : undefined;
  }

  const handleChangeInInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(event.target.value.toLowerCase());
  };

  const resetSearchText = () => {
    setSearchItem('');
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };

  const filterRecentlyUpdatedMaps = (maps) => {
    return maps
      .filter(
        (x) =>
          Date.now() - new Date(x.updatedOn).getTime() <= maxRecentlyUpdatedDays
      )
      .sort((a, b) => {
        return a.updatedOn.localeCompare(b.updatedOn);
      })
      .reverse();
  };

  useEffect(() => {
    setSelectedButton(selectedButton);
    if (selectedCategory === 'recently-viewed')
      setFilteredProcessMaps(processMaps);
    else {
      setFilteredProcessMaps(
        flowsUtilityService.orderItemsAlphabetically(processMaps)
      );
    }
  }, [processMaps, selectedCategory]);

  useEffect(() => {
    filterAction();
  }, [searchItem, selectedButton, processMaps]);

  const filterAction = () => {
    const filteredValueOnSearch = processMaps.filter(
      (x) =>
        x.description?.toLowerCase().includes(searchItem) ||
        x.name?.toLowerCase().includes(searchItem) ||
        x.processOwnerName?.toLowerCase().includes(searchItem)
    );

    switch (selectedButton) {
      case ProcessMapsFilterTabs.MAPS_IM_IN_ID:
        setFilteredProcessMaps(
          filteredValueOnSearch.filter((x) => x.isUserAParticipant)
        );
        break;
      case ProcessMapsFilterTabs.ALL_MAPS_ID:
        setFilteredProcessMaps(filteredValueOnSearch);
        break;
      case ProcessMapsFilterTabs.MY_MAPS_ID:
        setFilteredProcessMaps(
          filteredValueOnSearch.filter((x) => x.isUserProcessOwner)
        );
        break;
      case ProcessMapsFilterTabs.RECENTLY_UPDATED_MAPS_ID:
        setFilteredProcessMaps(
          filterRecentlyUpdatedMaps(filteredValueOnSearch)
        );
        break;
      default:
        setFilteredProcessMaps(filteredValueOnSearch);
        break;
    }
  };

  return (
    <div className={`start-category-list ${isMobile ? 'isMobile' : ''}`}>
      <div className="process-mapv2">
        <div className="process-mapv2-search-container">
          <i className="fa-light fa-magnifying-glass"></i>
          <div className="process-mapv2-input-placeholder">
            <input
              ref={searchInputRef}
              className="process-mapv2-search"
              type="text"
              onChange={handleChangeInInput}
              required
            />
            <div className="process-mapv2-placeholder">
              Search by map name, description, or process owner
            </div>
            {searchItem !== '' && (
              <i
                className="fa-light fa-times process-mapv2-clear"
                onClick={resetSearchText}
                title="Clear"
              ></i>
            )}
          </div>
          {showDashboardsV1 ? (
            <ButtonGroup
              aria-label="Basic button group"
              className="button-group-container-process-maps"
            >
              <Button
                id={ProcessMapsFilterTabs.ALL_MAPS_ID}
                className={`button-group-process-maps ${
                  selectedButton === ProcessMapsFilterTabs.ALL_MAPS_ID
                    ? 'selected'
                    : ''
                }`}
                onClick={() =>
                  setSelectedButton(ProcessMapsFilterTabs.ALL_MAPS_ID)
                }
              >
                All maps
              </Button>
              <Button
                id={ProcessMapsFilterTabs.MAPS_IM_IN_ID}
                className={`button-group-process-maps ${
                  selectedButton === ProcessMapsFilterTabs.MAPS_IM_IN_ID
                    ? 'selected'
                    : ''
                }`}
                onClick={() =>
                  setSelectedButton(ProcessMapsFilterTabs.MAPS_IM_IN_ID)
                }
              >
                Maps I'm in
              </Button>
              <Button
                id={ProcessMapsFilterTabs.RECENTLY_UPDATED_MAPS_ID}
                className={`button-group-process-maps ${
                  selectedButton ===
                  ProcessMapsFilterTabs.RECENTLY_UPDATED_MAPS_ID
                    ? 'selected'
                    : ''
                }`}
                onClick={() =>
                  setSelectedButton(
                    ProcessMapsFilterTabs.RECENTLY_UPDATED_MAPS_ID
                  )
                }
              >
                Recently updated
              </Button>
              {showMyMapsTab ? (
                <Button
                  id={ProcessMapsFilterTabs.MY_MAPS_ID}
                  className={`button-group-process-maps ${
                    selectedButton === ProcessMapsFilterTabs.MY_MAPS_ID
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() =>
                    setSelectedButton(ProcessMapsFilterTabs.MY_MAPS_ID)
                  }
                >
                  My maps
                </Button>
              ) : null}
            </ButtonGroup>
          ) : null}
        </div>
      </div>

      {filteredProcessMaps.length > 0 ? (
        <div className={`flow-block ${isMobile ? 'isMobile' : ''}`}>
          <div className={`flow-list ${isMobile ? 'isMobile' : ''}`}>
            <ul>
              {filteredProcessMaps.map((processMap) => (
                <li key={processMap.id}>
                  <FlowModelSummaryComponent
                    flowModelSummary={processMap}
                    isMobile={isMobile}
                    onFlowModelClick={onFlowModelClick}
                    resetSearchItem={resetSearchText}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <EmptyState
            summaryText={emptyStateSummaryText}
            bodyText={emptyStateBodyText}
            buttonText={emptyStateButtonText}
            onButtonClick={emptyStateOnButtonClick}
          />
        </div>
      )}
    </div>
  );
};

export default ProcessMapList;
