/**
 * @ngdoc service
 * @name stepService
 * @module flowingly.runner.services
 *
 * @description A service for Step related actions
 */

import { FormGen } from '@Client/@types/formGen';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IFormController, IQService } from 'angular';
import IFormInput from '@Shared.Angular/@types/core/contracts/queryModel/card/formInput';
import { FormFieldConditionAction } from '@Shared.Angular/@types/core/contracts/queryModel/card/formFieldConditionAction';
import { Guid } from '@Shared.Angular/@types/guid';
import { FormFieldType } from '@Shared.Angular/flowingly.services/flowingly.constants';

export default class StepService {
  static $inject = [
    'lodashService',
    'flowinglyConstants',
    'conditionalFormService',
    'fgFileListService',
    'sessionService',
    'fileService',
    '$q',
    'dateService',
    'momentService',
    'APP_CONFIG'
  ];

  static readonly EXCLUDED_FIELD_TYPES_FOR_RESET = {
    [FormFieldType.LOOKUP]: (config) => config.enableHiddenLookupField
  };

  constructor(
    private lodashService: Lodash,
    private flowinglyConstants: SharedAngular.FlowinglyConstants,
    private conditionalFormService: SharedAngular.ConditionalFormService,
    private fgFileListService: FormGen.FgFileListService,
    private sessionService: SharedAngular.SessionService,
    private fileService: SharedAngular.FileService,
    private $q: IQService,
    private dateService: DateService,
    private momentService: Moment,
    private APP_CONFIG: SharedAngular.APP_CONFIG
  ) {}

  public initialiseFormData(step) {
    //dont initialise if there is no step (completed flow)
    if (!step) {
      return undefined;
    }

    step.LocalUpdatedDate = this.dateService.utcToLocal(step.UpdatedDate);
    step.LocalCreatedDate = this.dateService.utcToLocal(step.CreatedDate);

    //if this step has been saved before, then it will have form data
    step.$data = step.$data || {};
    step.$previousCustomValidationData =
      step.$previousCustomValidationData || {};

    for (const f of step.Schema.fields) {
      const matchField = step.Fields.find(
        (sf) => sf.Name === f.name || this.isApprovalField(sf.Name, f.name)
      );
      let parsedValue = undefined;
      let parsedPreviousValue = undefined;

      if (matchField) {
        switch (f.type.toLowerCase()) {
          case this.flowinglyConstants.formFieldType.NUMBER:
            parsedValue = this.handleStepFieldNumber(matchField.Value);
            if (matchField.CustomValidationPreviousValue)
              parsedPreviousValue = this.handleStepFieldNumber(
                matchField.CustomValidationPreviousValue
              );
            break;

          case this.flowinglyConstants.formFieldType.CURRENCY:
            parsedValue = this.handleStepFieldCurrency(matchField.Value);
            if (matchField.CustomValidationPreviousValue)
              parsedPreviousValue = this.handleStepFieldCurrency(
                matchField.CustomValidationPreviousValue
              );
            break;

          case this.flowinglyConstants.formFieldType.FILE_UPLOAD:
            parsedValue = this.handleStepFieldFileUpload(matchField);
            break;

          case this.flowinglyConstants.formFieldType.CHECKBOX:
            parsedValue =
              matchField.Value &&
              (matchField.Value === true ||
                matchField.Value.toLowerCase() === 'true')
                ? true
                : false;
            break;

          case this.flowinglyConstants.formFieldType.TASK_LIST:
          case this.flowinglyConstants.formFieldType.MULTISELECT_LIST:
            parsedValue = this.handleStepFieldTaskListOrMultiSelection(
              matchField,
              f
            );
            break;

          case this.flowinglyConstants.formFieldType.DATE:
            if (f.defaultValueOption === 'autoPopulate')
              parsedValue = this.momentService
                .utc(new Date())
                .local()
                .format('DD/MM/YYYY');
            else parsedValue = matchField.Value;
            if (matchField.CustomValidationPreviousValue)
              parsedPreviousValue = matchField.CustomValidationPreviousValue;
            break;
          case this.flowinglyConstants.formFieldType.DATETIME:
            if (f.defaultValueOption === 'autoPopulate')
              parsedValue = this.momentService
                .utc(new Date())
                .local()
                .format('DD/MM/YYYY h:mm:ss A');
            // as in datetime kendo ui format
            else parsedValue = matchField.Value;
            if (matchField.CustomValidationPreviousValue)
              parsedPreviousValue = matchField.CustomValidationPreviousValue;
            break;
          default:
            parsedValue = matchField.Value;
            break;
        }

        step.$data[f.name] = parsedValue;
        if (matchField.CustomValidationPreviousValue && parsedPreviousValue)
          step.$previousCustomValidationData[
            matchField.CustomValidationFormFieldId
          ] = parsedPreviousValue;
      } else if (f.type === 'dynamicactors') {
        const matchDynamicActorField = step.Fields.find(
          (sf) => sf.Type === 'dynamicactors'
        );
        if (matchDynamicActorField) {
          f.options = JSON.parse(matchDynamicActorField.Options);
        }
      }
    }

    return step;
  }

  private isApprovalField(fieldName: string, schemaName: string) {
    return fieldName.indexOf(schemaName) !== -1;
  }

  private handleStepFieldNumber(numberValue: string) {
    return parseFloat(numberValue);
  }

  private handleStepFieldCurrency(currencyValue) {
    if (currencyValue != null)
      //will be string if from server
      return Number(currencyValue.replace(/,/g, ''));
    else return currencyValue;
  }

  private handleStepFieldFileUpload(field) {
    const user = this.sessionService.getUser();
    this.fileService.setUser(user.id, user.businessId);

    if (field.Value !== '') {
      if (Object.prototype.toString.call(field.Value) === '[object String]') {
        //if it's a string this form is being initialised after closing it client side, without a server refresh
        const listOfFilePaths = [];
        const fileIds = field.Value.split(',');

        field.HasFiles = false;
        this.lodashService.each(fileIds, (id) => {
          //create the filepath and download link
          listOfFilePaths.push({
            filepath:
              this.APP_CONFIG.apiBaseUrl +
              'files/' +
              user.businessId +
              '/' +
              user.id +
              '/' +
              id,
            downloadLink: this.fileService.getDownloadLink(id),
            fileId: id
          });
          field.HasFiles = true;
        });
        field.Value = listOfFilePaths;
      }
    }

    return field.Value;
  }

  private handleStepFieldTaskListOrMultiSelection(field, schema) {
    const formData = {};

    for (const v of field.Value) {
      const matchOption = schema.options.find((opt) => opt.text === v.key);
      if (matchOption) {
        if (v.value === 'true') {
          formData[matchOption.value] = true;
        } else if (v.value === 'false') {
          formData[matchOption.value] = false;
        }
      }
    }

    return formData;
  }

  public validationResultIsGood(validationResult: IFormValidationResult) {
    let valid = true;

    if (!validationResult.isCheckListValid) {
      this.markMultipleSelectListInvalid(
        validationResult.invalidTaskLists,
        validationResult.form
      );
      valid = false;
    }
    if (
      !validationResult.isMultiselectListValid ||
      !validationResult.isMultiselectListCustomValid
    ) {
      this.markMultipleSelectListInvalid(
        validationResult.invalidMultiSelects,
        validationResult.form
      );
      valid = false;
    }

    if (!validationResult.requiredTableHasRows) {
      valid = false;
    }

    if (!validationResult.isTextInputValid) {
      valid = false;
    }

    if (!validationResult.isApprovalCommentFieldValid) {
      valid = false;
    }

    if (!validationResult.isFileUploadValid) {
      valid = false;
    }

    return valid;
  }

  private markMultipleSelectListInvalid(invalidFields, form) {
    for (let f = 0; f < invalidFields.length; f++) {
      for (let t = 0; t < invalidFields[f].options.length; t++) {
        const tempField = form[invalidFields[f].options[t].name];
        tempField.$invalid = true;
      }
    }
  }

  public getFormDataWithoutValidating(step) {
    //this form is a work in progress (the user abondoned it) so don't bother validating
    //just save as is.
    const schema = step.Schema;
    const data = step.$data;
    const formData = [];

    if (schema == undefined) {
      return undefined;
    }

    this.lodashService.forEach(schema.fields, (field) => {
      let fieldValue = data[field.name];
      if (
        field.type === 'table' &&
        (fieldValue === undefined || fieldValue === null)
      ) {
        fieldValue = this.flowinglyConstants.tableNoRowMessage.TABLE_NO_ROW;
      }

      switch (field.type.toLowerCase()) {
        case this.flowinglyConstants.formFieldType.FILE_UPLOAD: {
          //here we create the list of file ids for the form field.
          //this needs to include any previously uploaded files (in progress form not completed) and any files uploaded now
          const fileIds = [];
          const files = this.fgFileListService.getFilesForControl(
            field.Name,
            step.Id
          );
          for (let f = 0; f < files.length; f++) {
            fileIds.push(files[f].id);
          }
          formData.push({ key: field.name, value: fileIds.join() });
          break;
        }
        case this.flowinglyConstants.formFieldType.TASK_LIST: {
          const chkListValues = this.getTaskListValues(field, fieldValue);

          formData.push({
            key: field.name,
            value: '',
            values: chkListValues
          });
          break;
        }
        case this.flowinglyConstants.formFieldType.APPROVAL_RULE:
          formData.push({ key: field.name, value: fieldValue });
          //Need to generate unique key for ApprovalCommentField too so that it can be rendered in report. Otherwise only one comment will be rendered.
          formData.push({
            key: 'ApproveCommentField' + field.name,
            value: data['ApproveCommentField']
          });
          break;

        case this.flowinglyConstants.formFieldType.MULTISELECT_LIST: {
          const multiSelListValues = this.getMultiSelectValues(
            field,
            fieldValue
          );
          formData.push({
            key: field.name,
            value: '',
            values: multiSelListValues
          });
          break;
        }
        case this.flowinglyConstants.formFieldType.LOOKUP:
          if (
            field.lookupConfig.userObject &&
            field.lookupConfig.userObject.length > 0
          )
            formData.push({
              key: field.name,
              value: field.lookupConfig.userObject
                .map(function (uo) {
                  return uo.value;
                })
                .join(', ')
            });
          else formData.push({ key: field.name, value: fieldValue });
          break;
        case this.flowinglyConstants.formFieldType.SELECT_LIST:
          formData.push({ key: field.name, value: fieldValue });
          break;
        default:
          formData.push({ key: field.name, value: fieldValue });
          break;
      }
    });

    return formData;
  }

  public validateForm(
    flowId: Guid,
    data,
    schema,
    form: IFormController,
    fileUploadData,
    resetFieldHiddenByConditions = false,
    stepId = null
  ) {
    const validationConfig: IFormValidationResult = {
      requiredTableHasRows: true,
      isCheckListValid: true,
      isMultiselectListValid: true,
      isMultiselectListCustomValid: true,
      isTextInputValid: true,
      isApprovalCommentFieldValid: true,
      isFileUploadValid: false,
      invalidTaskLists: [],
      invalidMultiSelects: [],
      form: form,
      formData: []
    };

    validationConfig.isFileUploadValid =
      this.fgFileListService.areVisibleFileFieldsValid(flowId);

    //get the data in order of how it has been presented in the form
    const formData: IFormInput[] = [];

    this.lodashService.forEach(schema.fields, (field) => {
      let fieldValue = data[field.name];
      const formFieldType = field.type.toLowerCase();
      const isFieldHidden = this.conditionalFormService.isFieldHidden(
        field.name
      );
      const isFieldHiddenByConditions =
        this.conditionalFormService.isFieldHiddenByConditions(field.name);

      if (
        isFieldHidden === true &&
        !StepService.shouldExcludeField(field.type, this.APP_CONFIG)
      ) {
        if (
          isFieldHiddenByConditions === true &&
          resetFieldHiddenByConditions === true
        ) {
          this.resetFieldValue(field, formData, '', stepId);
        }
        formData.push({
          key: field.name,
          value: null,
          fieldConditionStatus: FormFieldConditionAction.Hide
        });
        return;
      }

      const isTable =
        formFieldType === this.flowinglyConstants.formFieldType.TABLE;
      if (isTable && field.validation?.required && !fieldValue) {
        fieldValue = this.flowinglyConstants.tableNoRowMessage.TABLE_NO_ROW;
        validationConfig.requiredTableHasRows = false;
        // The field is required but does not have a value.
        return;
      }

      // Check if the table has rows.
      // Tables only need rows when the table is marked as required.
      if (fieldValue && isTable && fieldValue.includes('rows')) {
        const rows = JSON.parse(fieldValue).rows;
        for (const key in Object.keys(rows)) {
          if (Object.prototype.hasOwnProperty.call(rows, key)) {
            const cells = rows[key].cells;
            if (cells) {
              for (const cKey in Object.keys(cells)) {
                if (Object.prototype.hasOwnProperty.call(cells, cKey)) {
                  const fieldTableSchema = JSON.parse(field.tableSchema);
                  const cellId = cells[cKey].id;
                  const fieldTableSchemaCell = fieldTableSchema.filter(
                    function (f) {
                      return f.id.toString() === cellId.toString();
                    }
                  );
                  const fieldIsRequired =
                    fieldTableSchemaCell && fieldTableSchemaCell[0]?.isRequired;
                  const fieldCellValue = cells[cKey].value;

                  if (
                    fieldIsRequired &&
                    (fieldCellValue === null ||
                      fieldCellValue === undefined ||
                      fieldCellValue === '')
                  ) {
                    fieldValue =
                      this.flowinglyConstants.tableNoRowMessage.TABLE_NO_ROW;

                    if (field.validation?.required) {
                      validationConfig.requiredTableHasRows = false;
                      return;
                    }
                  }
                }
              }
            }
          }
        }
      }

      switch (formFieldType) {
        case this.flowinglyConstants.formFieldType.TASK_LIST: {
          const chkListValues = this.getTaskListValues(field, fieldValue);

          if (
            field.validation.required &&
            field.options.length !== chkListValues.length
          ) {
            validationConfig.isCheckListValid = false;
            validationConfig.invalidTaskLists.push(field);
          }

          formData.push({
            key: field.name,
            value: '',
            values: chkListValues
          });
          break;
        }
        case this.flowinglyConstants.formFieldType.APPROVAL_RULE: {
          formData.push({ key: field.name, value: fieldValue });
          //Need to generate unique key for ApprovalCommentField too so that it can be rendered in report. Otherwise only one comment will be rendered.
          formData.push({
            key: 'ApproveCommentField' + field.name,
            value:
              typeof data['ApproveCommentField'] === 'undefined'
                ? ''
                : data['ApproveCommentField']
          });

          // [FLOW-5458] Handle approval field rejection and validation of approval comment
          // [FLOW-5458] Find approval comment field to later find out if it has value or not.
          const approvalCommentField = schema.fields.find(
            (field) =>
              field.type.toLowerCase() ==
              this.flowinglyConstants.formFieldType.APPROVAL_COMMENT
          );

          if (approvalCommentField != null) {
            // [FLOW-5458] Find the selected option of the approval field.
            const selectedApprovalOption = field.options.find((option) => {
              if (option.value === fieldValue) {
                return option;
              }
            });
            // [FLOW-5458] Now check if the selected value is approve, if approve then there is no need for comment,
            // else comment is mandatory
            if (
              selectedApprovalOption != null &&
              selectedApprovalOption.text !== 'Approve'
            ) {
              if (
                data['ApproveCommentField'] == null ||
                data['ApproveCommentField'].length === 0
              ) {
                validationConfig.isApprovalCommentFieldValid = false;
              }
            }
          }

          break;
        }
        case this.flowinglyConstants.formFieldType.MULTISELECT_LIST: {
          const multiSelListValues = this.getMultiSelectValues(
            field,
            fieldValue
          );
          let invalidMultiSelList = false;

          //handle none selected
          if (field.validation.required && multiSelListValues.length === 0) {
            validationConfig.isMultiselectListValid = false;
            invalidMultiSelList = true;
            validationConfig.invalidMultiSelects.push(field);
          }
          //handle some are false, none are true
          if (
            field.validation.required &&
            this.lodashService.every(multiSelListValues, (chk) => {
              return chk.value === false;
            })
          ) {
            validationConfig.isMultiselectListValid = false;
            invalidMultiSelList = true;
            validationConfig.invalidMultiSelects.push(field);
          }

          if (!invalidMultiSelList) {
            const cnt = this.lodashService.filter(multiSelListValues, (chk) => {
              return chk.value === true;
            });

            if (
              cnt.length > 0 &&
              field.customValidation &&
              field.customValidation.required &&
              cnt.length < field.customValidation.value
            ) {
              validationConfig.isMultiselectListCustomValid = false;
              validationConfig.invalidMultiSelects.push(field);
            }
          }

          formData.push({
            key: field.name,
            value: '',
            values: multiSelListValues
          });
          break;
        }
        case this.flowinglyConstants.formFieldType.TEXT: {
          const formField = form[field.name];

          if (formField) {
            const passedXSS = formField.$error.xssValidate !== true;
            const passedMaxLen =
              formField.$viewValue === null ||
              formField.$viewValue.length <=
                (this.lodashService.get(field, 'customValidation.maxLength') ||
                  Number.MAX_SAFE_INTEGER);
            validationConfig.isTextInputValid =
              passedXSS && passedMaxLen && formField.$valid;
          }

          formData.push({ key: field.name, value: fieldValue });
          break;
        }
        case this.flowinglyConstants.formFieldType.TABLE: {
          if (typeof fieldValue === 'undefined') {
            fieldValue = this.flowinglyConstants.tableNoRowMessage.TABLE_NO_ROW;
          }

          formData.push({ key: field.name, value: fieldValue });
          break;
        }
        default:
          formData.push({ key: field.name, value: fieldValue });
          break;
      }
    });

    validationConfig.formData = formData;

    return validationConfig;
  }

  public appendTimezoneOffsetToDates(formData: IFormInput[], stepSchema) {
    // If the returned value is positive, it means the local timezone is behind UTC; if negative, it means the local timezone is ahead of UTC.
    // Therefore, we multiply it with -1 to correct the offset.
    const currentTimezoneOffset = new Date().getTimezoneOffset() * -1;

    for (const field of formData) {
      const fieldSchema = stepSchema.fields.find((x) => x?.name === field?.key);

      if (
        fieldSchema &&
        field?.value &&
        (fieldSchema?.type === this.flowinglyConstants.formFieldType.DATE ||
          fieldSchema?.type === this.flowinglyConstants.formFieldType.DATETIME)
      ) {
        if (field.value.trim() !== '') {
          field.value += ` ${currentTimezoneOffset}`;
        }
      }
    }

    return formData;
  }

  private resetFieldValue(field, formData, fieldValue, stepId) {
    if (!field) {
      return;
    }

    if (!formData) {
      formData = [];
    }
    switch (field.type.toLowerCase()) {
      case this.flowinglyConstants.formFieldType.TASK_LIST: {
        const chkListValues = this.getTaskListValues(field, fieldValue);

        formData.push({ key: field.name, value: '', values: chkListValues });
        break;
      }
      case this.flowinglyConstants.formFieldType.MULTISELECT_LIST: {
        const multiSelListValues = this.getMultiSelectValues(field, fieldValue);

        formData.push({
          key: field.name,
          value: '',
          values: multiSelListValues
        });
        break;
      }
      case this.flowinglyConstants.formFieldType.TEXT:
        formData.push({ key: field.name, value: fieldValue });
        break;
      case this.flowinglyConstants.formFieldType.TABLE:
        fieldValue = this.flowinglyConstants.tableNoRowMessage.TABLE_NO_ROW;
        formData.push({ key: field.name, value: fieldValue });
        break;
      case this.flowinglyConstants.formFieldType.FILE_UPLOAD:
        // TODO: [FLOW-6978] Make this async
        this.processFileDelete(field, stepId);
        break;
      default:
        formData.push({ key: field.name, value: fieldValue });
        break;
    }

    return formData;
  }

  public getTaskListValues(field, fieldValue) {
    const taskListValues = [];
    for (const prop in fieldValue) {
      if (fieldValue[prop] === true) {
        const matchOption = this.getOption(prop, field.options);

        if (matchOption) {
          taskListValues.push({
            key: matchOption.text,
            value: fieldValue[prop]
          });
        }
      }
    }

    return taskListValues;
  }

  public getMultiSelectValues(field, fieldValue) {
    //we need to display both selected and unselected values
    const chkListValues = [];
    const fieldValueData = fieldValue || {}; // fallback fieldValue to empty object, then when we do fieldValueData[option.value] not throw exception if fieldValue is undefined somehow

    // this is to address issue reported in FLOW-1728 Runner - Multi-select list (Optional) history, I ever reproduced once in QA, then never again in local/dev/qa
    // the problem is fieldValue is empty, I can see in DB, the CardValues in Steps table is {"key":"field67082623275","value":"","values":[]}, which should be {"key":"field67082623275","value":"","values":[{"key":"Option 1","value":false},{"key":"Option 2","value":false}]}
    // if you trace back, the fieldValue is set in sendCard method data param. Then further down the road, fgFieldMultiselectList directive initialize the value in init function.
    // so somewhere in between got problem. I changed the code rather than loop fieldValue, we use field.options to make sure return all the options.
    for (const option of field.options) {
      chkListValues.push({
        key: option.text,
        value: fieldValueData[option.value] || false // if found value use it, otherwise fallback to false
      });
    }

    return chkListValues;
  }

  private processFileDelete(field, stepId) {
    if (!field || !stepId) {
      return;
    }

    // TODO: Tidy up.
    // TODO: [FLOW-6978] Need to make the whole process of step submit and validation async
    // for any file delete errors to show up and block the process of step transition.
    const filesForField = this.fgFileListService.getFilesForControl(
      field.name,
      stepId
    );

    if (filesForField && filesForField.length > 0) {
      const deferred = this.$q.defer();
      let errored = false;
      let filesProcessed = 0;
      filesForField.forEach((fileData) => {
        if (this.sessionService.getUser()) {
          this.fileService
            .removeFile(fileData.id, stepId)
            .then((response) => {
              filesProcessed = filesProcessed + 1;
              if (response.status != 200) {
                errored = true;
              }
              if (filesProcessed === filesForField.length) {
                if (errored) {
                  deferred.reject(
                    'Error deleting file(s) of conditionally hidden field.'
                  );
                } else {
                  deferred.resolve();
                }
              }
            })
            .catch((ex) => {
              filesProcessed = filesProcessed + 1;
              errored = true;
              if (filesProcessed === filesForField.length) {
                if (errored) {
                  deferred.reject(
                    'Error deleting file(s) of conditionally hidden field.'
                  );
                } else {
                  deferred.resolve();
                }
              }
            });
        } else {
          this.fileService
            .deleteAnonymousFile(fileData.id, stepId)
            .then((response) => {
              filesProcessed = filesProcessed + 1;
              if (response.status != 200) {
                errored = true;
              }
              if (filesProcessed === filesForField.length) {
                if (errored) {
                  deferred.reject(
                    'Error deleting file(s) of conditionally hidden field.'
                  );
                } else {
                  deferred.resolve();
                }
              }
            })
            .catch((ex) => {
              filesProcessed = filesProcessed + 1;
              errored = true;
              if (filesProcessed === filesForField.length) {
                if (errored) {
                  deferred.reject(
                    'Error deleting file(s) of conditionally hidden field.'
                  );
                } else {
                  deferred.resolve();
                }
              }
            });
        }
      });
      return deferred.promise;
    }
  }

  private getOption(value, options) {
    return options.find((o) => {
      return o.value === value;
    });
  }
  static shouldExcludeField(
    fieldType: FormFieldType,
    config: SharedAngular.APP_CONFIG
  ): boolean {
    const condition = StepService.EXCLUDED_FIELD_TYPES_FOR_RESET[fieldType];
    return condition ? condition(config) : false;
  }
}

angular.module('flowingly.runner.services').factory('stepService', StepService);
export interface IFormValidationResult {
  requiredTableHasRows: boolean;
  isCheckListValid: boolean;
  isMultiselectListValid: boolean;
  isMultiselectListCustomValid: boolean;
  isTextInputValid: boolean;
  isApprovalCommentFieldValid: boolean;
  isFileUploadValid: boolean;
  invalidTaskLists: unknown[];
  invalidMultiSelects: unknown[];
  form: IFormController;
  formData: IFormInput[];
}
