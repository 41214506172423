/**
 * @ngdoc service
 * @name flowListManager
 * @module flowingly.runner.services
 *
 * @description This service is responsible managing the various lists,
 *              it will fetch / persist data using the apiService,
 *              and format the list data using appropriate services.
 */

import angular from 'angular';
import { SharedAngular } from '@Client/@types/sharedAngular';
import IReportFlowModelDetail from '@Shared.Angular/@types/core/contracts/queryModel/reports/reportFlowModelDetail';
import {
  FlowsImInFilterTabs,
  FlowsToDoFilterTabs
} from '@Shared.Angular/flowingly.services/flowingly.constants';
import { Guid } from '@Shared.Angular/@types/guid';
import { IFlowModelForUserPascalCase } from '@Shared.Angular/@types/core/contracts/queryModel/flows/flowModelForUser';
import { IGroupedFlowsForUserPascalCase } from '@Shared.Angular/@types/core/contracts/queryModel/flows/groupedFlowsForUser';

angular
  .module('flowingly.runner.services')
  .factory('flowListManager', flowListManager);

export interface IFlowGroupCategory {
  id: string;
  name?: string;
  count: number;
  text?: string;
  flowModels?: string[];
  subCategories?: IFlowGroupCategory[];
}

flowListManager.$inject = [
  '$q',
  'lodashService',
  'sessionService',
  'flowApiService',
  'runnerFlowsFormatter',
  'runnerStartFlowsFormatter',
  'flowinglyConstants',
  'flowModelApiService',
  'runnerCategoryNavigationService',
  'APP_CONFIG',
  'momentService'
];

function flowListManager(
  $q,
  lodashService,
  sessionService,
  flowApiService: FlowApiService,
  runnerFlowsFormatter,
  runnerStartFlowsFormatter,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  flowModelApiService: SharedAngular.FlowModelApiService,
  runnerCategoryNavigationService: RunnerCategoryNavigationService,
  APP_CONFIG: SharedAngular.APP_CONFIG,
  moment: Moment
) {
  const _defaultIminFlowStatus = APP_CONFIG.showDashboardsV1
    ? 'All'
    : 'InProgress';
  const _defaultIminFlowFilter = '1';
  const _iminDateFilterFormat = 'DD/MM/YYYY';
  const _defaultIminStartDateFilter = moment()
    .subtract(APP_CONFIG.flowsInDateFilterDefaultRange, 'month')
    .format(_iminDateFilterFormat);

  const _defaultIminEndDateFilter = moment().format(_iminDateFilterFormat);
  const _defaultTodoFlowFilter = '1';
  const _defaultIminFlowCategory = 'null';
  const _defaultTodoFlowCategory = 'null';
  const _defaultIminFlowCategoryIndex = '0';
  const _defaultIminFlowCategoryName = '';
  const _defaultTodoDashboardFilter =
    FlowsToDoFilterTabs[FlowsToDoFilterTabs.TO_DO];
  const _defaultFlowsImInDashboardFilter =
    FlowsImInFilterTabs[FlowsImInFilterTabs.STARTED];
  const _defaultProcessMapCategoryId = 'all-categories';
  const _defaultProcessMapCategoryIndex = '0';
  const _defaultExpandedCategoriesList = [];

  const _defaultActiveFlowCategoryIndex = '0';
  const _defaultActiveFlowCategoryId = 'all-categories';

  // We need to store state so we know both the state for the Imin view and
  //  the Todo view when we refresh the flow lists
  let _selectedIminStatus = _defaultIminFlowStatus;
  let _selectedIminFlowFilterId = _defaultIminFlowFilter;
  let _selectedIminStartDateFilter = _defaultIminStartDateFilter;
  let _selectedIminEndDateFilter = _defaultIminEndDateFilter;
  let _selectedTodoFlowFilterId = _defaultTodoFlowFilter;
  let _onlyStartedByMe = true;
  let _selectedIminFlowCategoryId = _defaultIminFlowCategory;
  let _selectedTodoFlowCategoryId = _defaultTodoFlowCategory;
  let _selectedIminFlowCategoryIndex = _defaultIminFlowCategoryIndex;
  let _selectedIminFlowCategoryName = _defaultIminFlowCategoryName;
  let _selectedTodoDashboardFilter = _defaultTodoDashboardFilter;
  let _selectedFlowsImInDashboardFilter = _defaultFlowsImInDashboardFilter;
  let _flowsTodoCountValue = 0;
  let _flowsTodoDueSoonCountValue = 0;
  let _flowsTodoDueTodayCountValue = 0;
  let _flowsTodoOverdueCountValue = 0;
  let _flowsImInStartedCountValue = 0;
  let _flowsImInDueSoonCountValue = 0;
  let _flowsImInDueTodayCountValue = 0;
  let _flowsImInOverDueCountValue = 0;
  let _flowsImInCompletedCountValue = 0;
  let _flowsImInRejectedCountValue = 0;
  let _flowsIminInProgressCountValue = 0;
  let _flowsImInCancelledCountValue = 0;
  let _selectedProcessMapCategoryId = _defaultProcessMapCategoryId;
  let _selectedProcessMapCategoryIndex = _defaultProcessMapCategoryIndex;
  let _expandedCategoriesList = _defaultExpandedCategoriesList;

  let _selectedActiveFlowCategoryIndex = _defaultActiveFlowCategoryIndex;
  let _selectedActiveFlowCategoryId = _defaultActiveFlowCategoryId;

  const service = {
    reportFlows: [], // All flow models that are published (grouped by category in start.flows.formatter)
    groupedFlowsTodo: [] as IGroupedFlowsForUserPascalCase[], // Flows To Do grouped by the selected group
    groupedFlowsImin: [] as IGroupedFlowsForUserPascalCase[], // Flows Im In grouped by the selected group and status
    iminFlowFilters: [] as IFlowFilter[],
    todoFlowFilters: [] as IFlowFilter[],
    statusOptions: [
      { name: 'In Progress', id: 'InProgress' },
      { name: 'All', id: 'All' },
      { name: 'Completed', id: 'Completed' },
      { name: 'Rejected', id: 'Rejected' },
      { name: 'Cancelled', id: 'Cancelled' }
    ],
    flowToDoCategories: [] as IFlowGroupCategory[],
    flowImInCategories: [] as IFlowGroupCategory[],

    // -------------Methods----------------
    applyFlowTodoFilter: applyFlowTodoFilter,
    applyFlowIminFilter: applyFlowIminFilter,
    clearLists: clearLists,
    countflowsTodo: countflowsTodo, //used by the counter displayed in the LHS menu

    getOnlyStartedByMe: getOnlyStartedByMe,
    getIminStatus: getIminStatus,
    getIminDateFilterFormat: getIminDateFilterFormat,
    getIminStartDateFilter: getIminStartDateFilter,
    getIminEndDateFilter: getIminEndDateFilter,
    getIminFlowFilter: getIminFlowFilterId,
    getTodoFlowFilter: getTodoFlowFilterId,
    getTodoFlowCategory: getTodoFlowCategoryId,
    getTodoFlowCount: getTodoFlowCount,
    getTodoDueSoonFlowCount: getTodoDueSoonFlowCount,
    getTodoDueTodayFlowCount: getTodoDueTodayFlowCount,
    getTodoOverdueFlowCount: getTodoOverdueFlowCount,
    getIminFlowCategory: getIminFlowCategoryId,
    getTodoFlowFilters: getTodoFlowFilters,
    getTodoDashboardFilter: getTodoDashboardFilter,
    getIminFlowFilters: getIminFlowFilters,
    getIminFlowCategoryIndex: getIminFlowCategoryIndex,
    getIminFlowCategoryName: getIminFlowCategoryName,
    getIminStartedFlowCount: getIminStartedFlowCount,
    getIminDueSoonFlowCount: getIminDueSoonFlowCount,
    getIminDueTodayFlowCount: getIminDueTodayFlowCount,
    getIminOverdueFlowCount: getIminOverdueFlowCount,
    getIminCompletedFlowCount: getIminCompletedFlowCount,
    getIminRejectedFlowCount: getIminRejectedFlowCount,
    getIminInProgressFlowCount: getIminInProgressFlowCount,
    getIminCancelledFlowCount: getIminCancelledFlowCount,
    getFlowsImInDashboardFilter: getFlowsImInDashboardFilter,
    getProcessMapCategoryId: getProcessMapCategoryId,
    getProcessMapCategoryIndex: getProcessMapCategoryIndex,
    getActiveFlowCategoryIndex: getActiveFlowCategoryIndex,
    getActiveFlowCategoryId: getActiveFlowCategoryId,
    getExpandedCategoriesList: getExpandedCategoriesList,

    setOnlyStartedByMe: setOnlyStartedByMe,
    setIminStatus: setIminStatus,
    setIminStartDateFilter: setIminStartDateFilter,
    setIminEndDateFilter: setIminEndDateFilter,
    setIminFlowFilter: setIminFlowFilter,
    setTodoFlowFilter: setTodoFlowFilter,
    setTodoFlowCategory: setTodoFlowCategory,
    setTodoDashboardFilter: setTodoDashboardFilter,
    setIminFlowCategory: setIminFlowCategory,
    setIminFlowCategoryIndex: setIminFlowCategoryIndex,
    setIminFlowCategoryName: setIminFlowCategoryName,
    setImInDashboardFilter: setImInDashboardFilter,
    setProcessMapCategoryId: setProcessMapCategoryId,
    setProcessMapCategoryIndex: setProcessMapCategoryIndex,
    setActiveFlowCategoryIndex: setActiveFlowCategoryIndex,
    setActiveFlowCategoryId: setActiveFlowCategoryId,
    setExpandedCategoriesList: setExpandedCategoriesList,

    getCategorizedMyApprovalFlowModels: getCategorizedMyApprovalFlowModels,
    getCategorizedSentForApprovalFlowModels:
      getCategorizedSentForApprovalFlowModels,
    getCategorizedProcessOverdueFlowModels:
      getCategorizedProcessOverdueFlowModels,
    getCategorizedWorkflows: getCategorizedWorkflows,
    getCategorizedProcessMaps,

    refreshReportFlows: refreshReportFlows,
    refreshFlowsImin: refreshFlowsImin,
    //Call up the API to get the flows for the selected group and status
    refreshFlowsTodo: refreshFlowsTodo, //Call up the API to get the flows for the selected group and status
    refreshFlowInstanceLists: refreshFlowInstanceLists, // Called when we get a signalR notification
    replaceFlowInstanceListsActor: replaceFlowInstanceListsActor,
    refreshTodoFlowFilters: refreshTodoFlowFilters, // Refresh TodoFlowFilters if the filter does not exists, set it to default filter

    updateFlowProgress: updateFlowProgress,
    //called when flow progress saved (to ensure changes visible immediatly)
    updateFlowNames: updateFlowNames, //user has changed flow name in modeler, update here
    updateFlowInLists: updateFlowInLists,
    removeDeletedProcessOwner: removeDeletedProcessOwner,
    updateFlowCommentCountInLists: updateFlowCommentCountInLists
  };

  return service;

  //////////// Public API Methods

  function applyFlowTodoFilter() {
    _flowsTodoCountValue = 0;
    const filteredFlows = [];
    lodashService.forEach(service.groupedFlowsTodo, function (group) {
      let groupHasFlows = false;
      lodashService.forEach(group.Flows, function (flow) {
        flow.show = false;
        if (
          (!_selectedTodoFlowCategoryId ||
            _selectedTodoFlowCategoryId === _defaultTodoFlowCategory ||
            _selectedTodoFlowCategoryId === flow.FlowCategoryId) &&
          (!_selectedTodoFlowFilterId ||
            _selectedTodoFlowFilterId === _defaultTodoFlowFilter ||
            _selectedTodoFlowFilterId === flow.FlowModelId)
        ) {
          _flowsTodoCountValue++;
          filteredFlows.push(flow);
          if (
            _selectedTodoDashboardFilter ===
            FlowsToDoFilterTabs[FlowsToDoFilterTabs.TO_DO]
          ) {
            flow.show = true;
            groupHasFlows = true;
          } else {
            if (flowMeetsTodoDashboardFilterCriteria(flow)) {
              flow.show = true;
              groupHasFlows = true;
            }
          }
        }
      });
      group.show = groupHasFlows;
    });
    setFlowTodoSummaryCount(filteredFlows);
  }

  function applyFlowIminFilter() {
    _flowsImInStartedCountValue = 0;
    const filteredFlows = [];
    lodashService.forEach(service.groupedFlowsImin, function (group) {
      let groupHasFlows = false;
      lodashService.forEach(group.Flows, function (flow) {
        flow.show = false;
        if (
          (!_selectedIminFlowCategoryId ||
            _selectedIminFlowCategoryId === _defaultIminFlowCategory ||
            _selectedIminFlowCategoryId === flow.FlowCategoryId) &&
          (!_selectedIminFlowFilterId ||
            _selectedIminFlowFilterId === _defaultIminFlowFilter ||
            _selectedIminFlowFilterId === flow.FlowModelId)
        ) {
          _flowsImInStartedCountValue++;
          filteredFlows.push(flow);
          if (flowMeetsFlowsImInDashboardFilterCriteria(flow)) {
            flow.show = true;
            groupHasFlows = true;
          }
        }
      });
      group.show = groupHasFlows;
    });
    setFlowsImInSummaryCount(filteredFlows);
  }

  function clearLists() {
    service.groupedFlowsTodo = [];
    service.groupedFlowsImin = [];
    service.reportFlows = [];
  }

  function countflowsTodo() {
    const groupCount = service.groupedFlowsTodo.length;
    let todoCount = 0;
    for (let groupIndex = 0; groupIndex < groupCount; groupIndex++) {
      todoCount += service.groupedFlowsTodo[groupIndex].Flows.length;
    }
    return todoCount;
  }

  function getOnlyStartedByMe() {
    return _onlyStartedByMe;
  }

  function getIminStatus() {
    return _selectedIminStatus;
  }

  function getIminFlowFilterId() {
    return _selectedIminFlowFilterId;
  }

  function getIminDateFilterFormat() {
    return _iminDateFilterFormat;
  }

  function getIminStartDateFilter() {
    return _selectedIminStartDateFilter;
  }

  function getIminEndDateFilter() {
    return _selectedIminEndDateFilter;
  }

  function getTodoFlowFilterId() {
    return _selectedTodoFlowFilterId;
  }

  function getTodoFlowCategoryId() {
    return _selectedTodoFlowCategoryId;
  }

  function getIminFlowCategoryId() {
    return _selectedIminFlowCategoryId;
  }

  function getIminFlowCategoryIndex() {
    return _selectedIminFlowCategoryIndex;
  }

  function getIminFlowCategoryName() {
    return _selectedIminFlowCategoryName;
  }

  function getTodoDashboardFilter() {
    return _selectedTodoDashboardFilter;
  }

  function getTodoFlowCount() {
    return _flowsTodoCountValue;
  }
  function getTodoDueSoonFlowCount() {
    return _flowsTodoDueSoonCountValue;
  }
  function getTodoDueTodayFlowCount() {
    return _flowsTodoDueTodayCountValue;
  }
  function getTodoOverdueFlowCount() {
    return _flowsTodoOverdueCountValue;
  }
  function getFlowsImInDashboardFilter() {
    return _selectedFlowsImInDashboardFilter;
  }
  function getIminStartedFlowCount() {
    return _flowsImInStartedCountValue;
  }
  function getIminDueSoonFlowCount() {
    return _flowsImInDueSoonCountValue;
  }
  function getIminDueTodayFlowCount() {
    return _flowsImInDueTodayCountValue;
  }
  function getIminOverdueFlowCount() {
    return _flowsImInOverDueCountValue;
  }
  function getIminCompletedFlowCount() {
    return _flowsImInCompletedCountValue;
  }
  function getIminRejectedFlowCount() {
    return _flowsImInRejectedCountValue;
  }
  function getIminInProgressFlowCount() {
    return _flowsIminInProgressCountValue;
  }
  function getIminCancelledFlowCount() {
    return _flowsImInCancelledCountValue;
  }
  function getProcessMapCategoryId() {
    return _selectedProcessMapCategoryId;
  }
  function getProcessMapCategoryIndex() {
    return _selectedProcessMapCategoryIndex;
  }

  function getExpandedCategoriesList() {
    return _expandedCategoriesList;
  }

  function getActiveFlowCategoryIndex() {
    return _selectedActiveFlowCategoryIndex;
  }

  function getActiveFlowCategoryId() {
    return _selectedActiveFlowCategoryId;
  }

  function getTodoFlowFilters() {
    if (_selectedTodoFlowCategoryId !== _defaultTodoFlowCategory)
      return service.todoFlowFilters.filter(
        (f) => f.id === '1' || f.flowCategoryId === _selectedTodoFlowCategoryId
      );
    else return service.todoFlowFilters;
  }

  function getIminFlowFilters() {
    if (_selectedIminFlowCategoryId !== _defaultIminFlowCategory)
      return service.iminFlowFilters.filter(
        (f) => f.id === '1' || f.flowCategoryId === _selectedIminFlowCategoryId
      );
    else return service.iminFlowFilters;
  }

  function setOnlyStartedByMe(onlyStartedByMe) {
    _onlyStartedByMe = onlyStartedByMe;
  }

  function setIminStatus(status) {
    if (status) {
      _selectedIminStatus = status;
    } else {
      _selectedIminStatus = _defaultIminFlowStatus;
    }
  }

  function setIminStartDateFilter(date) {
    if (date) {
      _selectedIminStartDateFilter = date;
    } else {
      _selectedIminStartDateFilter = _defaultIminStartDateFilter;
    }
  }

  function setIminEndDateFilter(date) {
    if (date) {
      _selectedIminEndDateFilter = date;
    } else {
      _selectedIminEndDateFilter = _defaultIminEndDateFilter;
    }
  }

  function setIminFlowFilter(flowFilter) {
    if (flowFilter) {
      _selectedIminFlowFilterId = flowFilter;
    } else {
      _selectedIminFlowFilterId = null;
    }
  }

  function setTodoFlowFilter(flowFilter) {
    if (flowFilter) {
      _selectedTodoFlowFilterId = flowFilter;
    } else {
      _selectedTodoFlowFilterId = null;
    }
  }

  function setTodoFlowCategory(flowCategoryId) {
    _selectedTodoFlowCategoryId = flowCategoryId || _defaultTodoFlowCategory;
    _selectedTodoFlowFilterId = _defaultTodoFlowFilter;
  }

  function setIminFlowCategory(
    flowCategoryId,
    defaultFlowFilterRequired = true
  ) {
    _selectedIminFlowCategoryId = flowCategoryId || _defaultIminFlowCategory;
    if (defaultFlowFilterRequired) {
      _selectedIminFlowFilterId = _defaultIminFlowFilter;
    }
  }

  function setIminFlowCategoryIndex(index: string) {
    _selectedIminFlowCategoryIndex = index;
  }

  function setIminFlowCategoryName(name: string) {
    _selectedIminFlowCategoryName = name;
  }

  function setActiveFlowCategoryIndex(index: string) {
    _selectedActiveFlowCategoryIndex = index;
  }

  function setActiveFlowCategoryId(id: string) {
    _selectedActiveFlowCategoryId = id;
  }

  function setExpandedCategoriesList(ids: Array<string>) {
    _expandedCategoriesList = ids;
  }

  function updateFlowCommentCountInLists(flowId, commentCount) {
    updateFlowCommentCount(service.groupedFlowsImin, flowId, commentCount);
    updateFlowCommentCount(service.groupedFlowsTodo, flowId, commentCount);
  }

  function setProcessMapCategoryId(id: string) {
    _selectedProcessMapCategoryId = id;
  }

  function setProcessMapCategoryIndex(index: string) {
    _selectedProcessMapCategoryIndex = index;
  }

  function updateFlowProgress(flowId, stepId, formData) {
    // since a flow can appear in both flowsImIn and flowsTodo - we need to update both
    // flows can be grouped by due date, flow, completed status etc.
    // so rather than search each list, combine them all into one, then search

    // Start with FLOWS IM IN
    updateFlowInList(service.groupedFlowsImin, flowId, stepId, formData);

    // Then do FLOWS TO DO
    updateFlowInList(service.groupedFlowsTodo, flowId, stepId, formData);
  }

  function setTodoDashboardFilter(dashboardFilter: string) {
    _selectedTodoDashboardFilter = dashboardFilter;
  }

  function setImInDashboardFilter(dashboardFilter: string) {
    _selectedFlowsImInDashboardFilter = dashboardFilter;
  }

  function updateFlowNames(data) {
    service.groupedFlowsTodo = updateFlowModelNameInList(
      data,
      service.groupedFlowsTodo,
      false
    );

    service.groupedFlowsImin = updateFlowModelNameInList(
      data,
      service.groupedFlowsImin,
      false
    );

    service.reportFlows = updateFlowModelNameInList(
      data,
      service.reportFlows,
      true,
      'flowModels'
    );
  }

  function removeDeletedProcessOwner(flowModelIds) {
    service.reportFlows.forEach((category) => {
      category.flowModels.forEach((flowModel) => {
        if (flowModelIds.some((id) => flowModel.id === id)) {
          flowModel.processOwnerName = null;
        }
      });
    });
  }

  function updateFlowModelNameInList(msgDetails, source, camel, property?) {
    if (property === undefined) {
      property = camel ? 'flows' : 'Flows';
    }
    //we still have a mix of Pascal and Camel, rather than try and fix now and risk breaking other things
    //simply switch on a flag
    let changesMade = false;
    if (camel) {
      source.forEach((g) => {
        g[property].forEach((f) => {
          if (f.id === msgDetails.id) {
            changesMade = changesMade || f.name !== msgDetails.name;
            f.name = msgDetails.name;
            f.flowModelName = msgDetails.name;
          }
        });
      });
    } else {
      source.forEach((g) => {
        g[property].forEach((f) => {
          if (f.FlowModelId === msgDetails.id) {
            changesMade = changesMade || f.Name !== msgDetails.name;
            f.Name = msgDetails.name;
            g.GroupName = msgDetails.name;
          }
        });
      });
    }
    return changesMade ? angular.copy(source) : source;
  }

  function updateFlowInList(list, flowId, stepId, formData) {
    if (list.length > 0) {
      let allFlows = [];
      lodashService.each(list, (g) => {
        allFlows = allFlows.concat(g.Flows);
      });
      const flow = lodashService.find(allFlows, (f) => {
        return f.FlowId === flowId;
      });
      if (flow) {
        const step = lodashService.find(flow.Steps, (s) => {
          return s.Id === stepId;
        });
        copyFormDataToFormElements(step, formData);
      }
    }
  }

  function copyFormDataToFormElements(step, formData) {
    if (!step) {
      return;
    }
    lodashService.each(formData, (d) => {
      const field = lodashService.find(step.Fields, (f) => {
        return f.Name === d.key;
      });
      if (
        field &&
        (field.Type === 'tasklist' || field.Type === 'multiselectlist')
      ) {
        //anything that uses values and not value
        field.Value = d.values;
        lodashService.each(field.Value, function (val) {
          val.value = val.value.toString();
          val.values = '';
        });
      } else if (field && field.Type !== 'instruction') {
        field.Value = d.value;
      }
    });
  }

  /*
   * GetFlows API Data Model returned:
   *
   * DataModel
   *  - FlowModels
   *  - GroupedFlows
   *  - - GroupName
   *  - - Flows
   *  - - - Steps
   *  - - - - Fields
   */
  function refreshFlowsTodo() {
    return flowApiService.getFlowsTodo().then(function (response) {
      const groupedFlows = response.GroupedFlows || [];
      const flowModels = response.FlowModels || [];
      runnerFlowsFormatter.removeGroupsWithoutFlows(groupedFlows);
      formatFlowsTodo(groupedFlows);
      setTodoFlowFilters(flowModels);
      applyFlowTodoFilter();
      return service.groupedFlowsTodo;
    });
  }

  function convertToISOStartDay(dateString, format) {
    const dateTime = moment(dateString, format).startOf('day');
    return dateTime.toISOString();
  }

  function convertToISOEndOfDay(dateString, format) {
    const dateTime = moment(dateString, format).endOf('day');
    return dateTime.toISOString();
  }
  function refreshFlowsImin() {
    const startDateIsoString = convertToISOStartDay(
      _selectedIminStartDateFilter,
      _iminDateFilterFormat
    );
    const endDateIsoString = convertToISOEndOfDay(
      _selectedIminEndDateFilter,
      _iminDateFilterFormat
    );

    return flowApiService
      .getFlowsImin(
        _selectedIminStatus,
        _onlyStartedByMe,
        startDateIsoString,
        endDateIsoString
      )
      .then(function (response) {
        runnerFlowsFormatter.removeGroupsWithoutFlows(response.GroupedFlows);
        formatFlowsImin(response.GroupedFlows, undefined);
        setIminFlowFilters(response.FlowModels);
        applyFlowIminFilter();
        return service.groupedFlowsImin;
      });
  }

  // Called after a signalr notification is received
  function refreshFlowInstanceLists() {
    const todo = refreshFlowsTodo();
    const imin = refreshFlowsImin();
    return $q.all([todo, imin]);
  }

  function updateFlowInLists(updatedFlow) {
    const user = sessionService.getUser();
    if (updatedFlow.WaitingForUserIds.includes(user.id)) {
      updateFlow(service.groupedFlowsTodo);
    } else {
      removeFlow(service.groupedFlowsTodo);
      generateCategoriesOfGroupedFlows(
        service.flowToDoCategories,
        service.groupedFlowsTodo
      );
    }

    if (
      _selectedIminStatus === 'InProgress' &&
      (updatedFlow.PercentageComplete === 100 ||
        updatedFlow.CurrentStepsHeader === '')
    ) {
      removeFlow(service.groupedFlowsImin);
      generateCategoriesOfGroupedFlows(
        service.flowImInCategories,
        service.groupedFlowsImin
      );
    } else {
      updateFlow(service.groupedFlowsImin);
    }

    applyFlowTodoFilter();
    applyFlowIminFilter();

    function updateFlow(groupedFlows) {
      if (!Array.isArray(groupedFlows)) {
        return;
      }

      const groupWasUpdated = groupedFlows.some((group) => {
        if (group.GroupName !== updatedFlow.Name) {
          return false;
        }

        const flowWasUpdated = group.Flows.some((flow, index, array) => {
          if (flow.FlowIdentifier !== updatedFlow.FlowIdentifier) {
            return false;
          }

          array[index] = updatedFlow;
          return true;
        });

        if (!flowWasUpdated) {
          group.Flows.push(updatedFlow);
        }
        return true;
      });

      if (!groupWasUpdated) {
        const group = {
          FlowCategory: updatedFlow.Category,
          FlowCategoryId: updatedFlow.FlowCategoryId,
          Flows: [updatedFlow],
          GroupName: updatedFlow.Name
        };
        groupedFlows.push(group);
        groupedFlows.sort((a, b) => a.GroupName.localeCompare(b.GroupName));
      }
    }

    function removeFlow(groupedFlows) {
      if (!Array.isArray(groupedFlows)) {
        return;
      }

      const groupIndex = groupedFlows.findIndex(
        (group) => group.GroupName === updatedFlow.Name
      );
      if (groupIndex === -1) {
        return;
      }

      const group = groupedFlows[groupIndex];
      const flowIndex = group.Flows.findIndex(
        (flow) => flow.FlowId === updatedFlow.FlowId
      );
      if (flowIndex === -1) {
        return;
      }

      group.Flows.splice(flowIndex, 1);
      if (group.Flows.length === 0) {
        groupedFlows.splice(groupIndex, 1);
      }
    }
  }

  // an actor has been deleted so we will replace references to them
  function replaceFlowInstanceListsActor(actorName, replacementActorName) {
    replaceWaitingForName(service.groupedFlowsTodo);
    replaceWaitingForName(service.groupedFlowsImin);

    function replaceWaitingForName(groupedFlows) {
      if (!Array.isArray(groupedFlows)) {
        return;
      }

      groupedFlows.forEach((group) => {
        group.Flows.forEach((flow) => {
          if (flow.WaitingForName.indexOf(actorName) === -1) {
            return;
          }

          const userNames = flow.WaitingForName.split(', ');
          const oldUserNameIndex = userNames.findIndex(
            (name) => name === actorName
          );
          if (oldUserNameIndex === -1) {
            return;
          }

          userNames[oldUserNameIndex] = replacementActorName;
          flow.WaitingForName = userNames.join(', ');
        });
      });
    }
  }

  /* End of GetFlows API calls */

  function getCategorizedMyApprovalFlowModels(noSpinner = false) {
    return flowModelApiService
      .getMyApprovalFlowModels(noSpinner)
      .then((flowModels) =>
        runnerStartFlowsFormatter.groupFlowModelsByCategory(
          flowModels,
          function (category) {
            category.subCategories?.forEach((subCategory) => subCategory);
          }
        )
      );
  }

  function getCategorizedSentForApprovalFlowModels(noSpinner = false) {
    return flowModelApiService
      .getSentForApprovalFlowModels(noSpinner)
      .then((flowModels) =>
        runnerStartFlowsFormatter.groupFlowModelsByCategory(
          flowModels,
          function (category) {
            category.subCategories?.forEach((subCategory) => subCategory);
          }
        )
      );
  }

  function getCategorizedProcessOverdueFlowModels(noSpinner = false) {
    return flowModelApiService
      .getProcessOverdueFlowModels(noSpinner)
      .then((flowModels) =>
        runnerStartFlowsFormatter.groupFlowModelsByCategory(
          flowModels,
          function (category) {
            category.subCategories?.forEach((subCategory) => subCategory);
          }
        )
      );
  }

  function getCategorizedWorkflows(noSpinner = false) {
    return flowModelApiService
      .getPublishedFlowModels(
        noSpinner,
        flowinglyConstants.flowModelPublishType.WORKFLOW
      )
      .then((flowModels) =>
        runnerStartFlowsFormatter.groupFlowModelsByCategory(flowModels)
      );
  }

  function getCategorizedProcessMaps(noSpinner) {
    return flowModelApiService
      .getPublishedFlowModels(
        noSpinner,
        flowinglyConstants.flowModelPublishType.PROCESS_MAP
      )
      .then((flowModels) =>
        runnerStartFlowsFormatter.groupFlowModelsByCategory(flowModels)
      );
  }

  function refreshReportFlows(noSpinner) {
    return flowModelApiService
      .getReportFlowModels(noSpinner)
      .then(async (reportFlowModels) => {
        await formatReportFlowModels(reportFlowModels);
        return service.reportFlows;
      });
  }

  function refreshTodoFlowFilters(flowFilters) {
    const previousSelectedFlowFilter = getTodoFlowFilterId();
    if (lodashService.find(flowFilters, { id: previousSelectedFlowFilter })) {
      return previousSelectedFlowFilter;
    } else {
      setTodoFlowFilter(_defaultTodoFlowFilter);
      refreshFlowsTodo();
      return _defaultTodoFlowFilter;
    }
  }

  //////////// Private Methods

  // The following format methods simply call other methods to format the API responses.
  // No formatting should be completed in this service itself
  // They are however responsible for updating the list contents

  function updateFlowCommentCount(listOfFlows, flowId, commentCount) {
    let allFlows = [];
    lodashService.each(listOfFlows, (g) => {
      allFlows = allFlows.concat(g.Flows);
    });
    const flow = lodashService.find(allFlows, (f) => {
      return f.FlowId === flowId;
    });

    if (flow) flow.CommentCount = commentCount;
  }

  async function formatReportFlowModels(
    reportFlowModels: IReportFlowModelDetail[]
  ) {
    const activeFlows =
      runnerStartFlowsFormatter.groupFlowModelsByCategory(reportFlowModels);
    angular.copy(activeFlows, service.reportFlows);
  }

  function generateCategoriesOfGroupedFlows(
    categories: IFlowGroupCategory[],
    groupedFlows: IGroupedFlowsForUserPascalCase[]
  ) {
    if (APP_CONFIG.enableSubCategories) {
      generateNestedCategoriesOfGroupedFlows(categories, groupedFlows);
    } else {
      categories.length = 0;
      if (!groupedFlows) {
        return;
      }
      groupedFlows.forEach((group) => {
        const category = categories.find((c) => c.name === group.FlowCategory);
        if (category === undefined) {
          const newCategory: IFlowGroupCategory = {
            id: group.FlowCategoryId,
            name: group.FlowCategory || 'No Category',
            count: group.Flows.length
          };
          categories.push(newCategory);
        } else {
          category.count += group.Flows.length;
        }
      });
    }
  }

  function formatFlowsTodo(groupedFlows: IGroupedFlowsForUserPascalCase[]) {
    groupedFlows = groupedFlows || [];
    generateCategoriesOfGroupedFlows(service.flowToDoCategories, groupedFlows);
    runnerFlowsFormatter.format(groupedFlows, 'DueDate');
    angular.copy(groupedFlows, service.groupedFlowsTodo);
  }

  function formatFlowsImin(
    groupedFlows: IGroupedFlowsForUserPascalCase[],
    sortAscending = true
  ) {
    //flows Ive started is a little different, simply copy across
    //you cannot change the flow status here, so having intelligent update is not as important
    generateCategoriesOfGroupedFlows(service.flowImInCategories, groupedFlows);
    runnerFlowsFormatter.format(groupedFlows, null, sortAscending);
    angular.copy(groupedFlows, service.groupedFlowsImin);
  }

  function setIminFlowFilters(flowModels: IFlowModelForUserPascalCase[]) {
    service.iminFlowFilters = [];
    service.iminFlowFilters.push({
      name: 'All Flows',
      id: _defaultIminFlowFilter
    });
    lodashService.forEach(flowModels, function (flowModel) {
      service.iminFlowFilters.push({
        name: flowModel.Name,
        id: flowModel.Id,
        flowCategory: flowModel.FlowCategory,
        flowCategoryId: flowModel.FlowCategoryId
      });
    });
  }

  function setTodoFlowFilters(flowModels) {
    service.todoFlowFilters = [];
    service.todoFlowFilters.push({
      name: 'All Flows',
      id: _defaultTodoFlowFilter
    });
    lodashService.forEach(flowModels, function (flowModel) {
      service.todoFlowFilters.push({
        name: flowModel.Name,
        id: flowModel.Id,
        flowCategory: flowModel.FlowCategory,
        flowCategoryId: flowModel.FlowCategoryId
      });
    });
  }

  function generateNestedCategoriesOfGroupedFlows(
    categories: IFlowGroupCategory[],
    groupedFlows: IGroupedFlowsForUserPascalCase[]
  ) {
    const topLevelCategories = new Map();
    groupedFlows.forEach((groupedFlow) => {
      let categoryFromTree = groupedFlow.CategoryTree;
      if (!topLevelCategories.has(categoryFromTree?.CategoryId)) {
        const category = {
          name: categoryFromTree?.CategoryName || 'No Category',
          id: categoryFromTree?.CategoryId,
          flowModels: [],
          subCategories: [],
          count: 0
        };
        topLevelCategories.set(categoryFromTree?.CategoryId, category);
      }
      let parentCategory = topLevelCategories.get(categoryFromTree?.CategoryId);
      categoryFromTree = categoryFromTree?.Category;
      while (categoryFromTree) {
        const existingCategory = parentCategory.subCategories.find(
          (c) => c.id === categoryFromTree?.CategoryId
        );
        if (existingCategory) {
          parentCategory = existingCategory;
        } else {
          const category = {
            name: categoryFromTree?.CategoryName,
            id: categoryFromTree?.CategoryId,
            flowModels: [],
            subCategories: [],
            count: 0
          };
          parentCategory.subCategories.push(category);
          parentCategory.subCategories = orderItemsAlphabetically(
            parentCategory.subCategories
          );
          let subCategoryFlowCount = 0;
          const { flowModels } = parentCategory || {};
          if (flowModels) {
            for (const flowModel of flowModels) {
              const flows = flowModel.Flows || [];
              subCategoryFlowCount += flows.length;
            }
          }
          parentCategory.count = subCategoryFlowCount;
          parentCategory = category;
        }
        categoryFromTree = categoryFromTree?.Category;
      }
      parentCategory.flowModels.push(groupedFlow);
      parentCategory.count += groupedFlow.Flows.length;
    });
    categories.splice(
      0,
      categories.length,
      ...Array.from(topLevelCategories.values())
    );
    categories = orderItemsAlphabetically(categories);
    categories =
      runnerCategoryNavigationService.getFormattedTreeData(categories);
    categories.unshift({
      id: 'all-categories',
      text: 'All',
      count: 0
    });
  }
  function orderItemsAlphabetically(categories) {
    return categories.sort(function (a, b) {
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    });
  }

  function flowMeetsTodoDashboardFilterCriteria(flow) {
    if (flow.DueDate === null) return false;
    const formattedDate = new Date(flow.DueDate);
    switch (_selectedTodoDashboardFilter) {
      case FlowsToDoFilterTabs[FlowsToDoFilterTabs.DUE_TODAY]:
        if (
          flow.DueDateInLocalFormat ==
            new Date().toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric'
            }) &&
          !flow.IsOverdue
        ) {
          return true;
        }
        break;
      case FlowsToDoFilterTabs[FlowsToDoFilterTabs.DUE_SOON]:
        if (formattedDate > new Date()) {
          return true;
        }
        break;
      case FlowsToDoFilterTabs[FlowsToDoFilterTabs.OVERDUE]:
        if (flow.IsOverdue) {
          return true;
        }
        break;
      default:
        break;
    }
  }

  function setFlowTodoSummaryCount(flows) {
    _flowsTodoDueSoonCountValue = 0;
    _flowsTodoDueTodayCountValue = 0;
    _flowsTodoOverdueCountValue = 0;
    const flowsToBeSummarised =
      _selectedTodoFlowFilterId === _defaultTodoFlowFilter
        ? flows
        : flows.filter((x) => x.FlowModelId === _selectedTodoFlowFilterId);
    lodashService.forEach(flowsToBeSummarised, function (flow) {
      if (flow.IsOverdue) {
        _flowsTodoOverdueCountValue++;
      }
      const formattedDate = new Date(flow.DueDate);
      if (
        flow.DueDateInLocalFormat ==
          new Date().toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          }) &&
        !flow.IsOverdue
      ) {
        _flowsTodoDueTodayCountValue++;
      }
      if (formattedDate > new Date()) {
        _flowsTodoDueSoonCountValue++;
      }
    });
  }

  function flowMeetsFlowsImInDashboardFilterCriteria(flow) {
    const formattedDate = new Date(flow.DueDate);
    switch (_selectedFlowsImInDashboardFilter) {
      case FlowsImInFilterTabs[FlowsImInFilterTabs.STARTED]:
        return true;
      case FlowsImInFilterTabs[FlowsImInFilterTabs.DUE_TODAY]:
        if (
          flow.DueDateInLocalFormat ==
            new Date().toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric'
            }) &&
          !flow.IsOverdue
        ) {
          return true;
        }
        break;
      case FlowsImInFilterTabs[FlowsImInFilterTabs.DUE_SOON]:
        if (formattedDate > new Date()) {
          return true;
        }
        break;
      case FlowsImInFilterTabs[FlowsImInFilterTabs.OVERDUE]:
        if (flow.IsOverdue && flow.PercentageComplete !== 100) {
          return true;
        }
        break;
      case FlowsImInFilterTabs[FlowsImInFilterTabs.COMPLETED]:
        if (
          flow.FinalisedDate != null &&
          (flow.FinalisedReason === 'Completed' ||
            flow.FinalisedReason === null)
        ) {
          return true;
        }
        break;
      case FlowsImInFilterTabs[FlowsImInFilterTabs.REJECTED]:
        if (flow.FinalisedReason === 'Rejected' && flow.FinalisedDate != null) {
          return true;
        }
        break;
      case FlowsImInFilterTabs[FlowsImInFilterTabs.IN_PROGRESS]:
        if (flow.PercentageComplete !== 100) return true;
        break;
      case FlowsImInFilterTabs[FlowsImInFilterTabs.CANCELLED]:
        if (
          flow.FinalisedReason === 'Withdrawn' &&
          flow.FinalisedDate != null
        ) {
          return true;
        }
        break;
      default:
        break;
    }
  }

  function setFlowsImInSummaryCount(flows) {
    _flowsImInDueSoonCountValue = 0;
    _flowsImInDueTodayCountValue = 0;
    _flowsImInOverDueCountValue = 0;
    _flowsImInCompletedCountValue = 0;
    _flowsImInRejectedCountValue = 0;
    _flowsIminInProgressCountValue = 0;
    _flowsImInCancelledCountValue = 0;
    const flowsToBeSummarised =
      _selectedIminFlowFilterId === _defaultIminFlowFilter
        ? flows
        : flows.filter((x) => x.FlowModelId === _selectedIminFlowFilterId);
    lodashService.forEach(flowsToBeSummarised, function (flow) {
      if (flow.PercentageComplete !== 100) {
        _flowsIminInProgressCountValue++;
        if (flow.IsOverdue) _flowsImInOverDueCountValue++;
      }
      const formattedDate = new Date(flow.DueDate);
      if (
        flow.DueDateInLocalFormat ==
          new Date().toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          }) &&
        !flow.IsOverdue
      ) {
        _flowsImInDueTodayCountValue++;
      }
      if (formattedDate > new Date()) {
        _flowsImInDueSoonCountValue++;
      }
      if (
        flow.FinalisedDate != null &&
        (flow.FinalisedReason === 'Completed' || flow.FinalisedReason === null)
      ) {
        _flowsImInCompletedCountValue++;
      }
      if (flow.FinalisedReason === 'Rejected' && flow.FinalisedDate != null) {
        _flowsImInRejectedCountValue++;
      }
      if (flow.FinalisedReason === 'Withdrawn' && flow.FinalisedDate != null) {
        _flowsImInCancelledCountValue++;
      }
    });
  }
}

interface IFlowFilter {
  name: string;
  id: Guid | string; // String because the default id is "1", should be changed to an actual guid
  flowCategory?: string;
  flowCategoryId?: Guid;
}

export type FlowListManagerType = ReturnType<typeof flowListManager>;
