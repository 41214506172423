import React from 'react';

interface Props {
  summaryText: string;
  bodyText: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

const EmptyState = ({
  summaryText,
  bodyText,
  buttonText = '',
  onButtonClick
}: Props) => {
  return (
    <div className="empty-state-container">
      <div className="empty-state align-center pb-30 pt-50">
        <div className="mt-50">
          <h4 className="empty-state__summary">{summaryText}</h4>
          <h5
            className="empty-state__text"
            dangerouslySetInnerHTML={{ __html: bodyText }}
          />
          <br />
        </div>
        {buttonText && onButtonClick && (
          <button onClick={onButtonClick} className="btn green">
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
