import angular from 'angular';
import { IRule } from './rule.interface';
import { ModelerValidation } from './@types/services';
import { Services } from '@Shared.Angular/@types/services';
import IModelNode from '@Shared.Angular/@types/modelNode';
import {
  DynamicActorTypeIds,
  TaskType
} from '@Shared.Angular/flowingly.services/flowingly.constants';

export class RulesetParserService {
  constructor(
    private ruleService: ModelerValidation.RuleService,
    private flowinglyConstants: Services.FlowinglyConstants
  ) {}

  divergeParser(firstNodesOfDivergedPaths: IModelNode[]) {
    const matchRules: IRule[] = [this.ruleService.DEFAULT];
    const dynamicActorCount = firstNodesOfDivergedPaths.filter(
      (node) =>
        node.taskType === TaskType.TASK &&
        node.dynamicActorType === DynamicActorTypeIds.SELECT_DYNAMIC_ACTORS
    ).length;
    const dynamicActorARCount = firstNodesOfDivergedPaths.filter(
      (node) =>
        node.taskType === TaskType.APPROVAL &&
        node.dynamicActorType === DynamicActorTypeIds.SELECT_DYNAMIC_ACTORS
    ).length;
    const parallelARCount = firstNodesOfDivergedPaths.filter(
      (node) => node.taskType === TaskType.PARALLEL_APPROVAL
    ).length;
    const sequentialARCount = firstNodesOfDivergedPaths.filter(
      (node) => node.taskType === TaskType.SEQUENTIAL_APPROVAL
    ).length;

    if (dynamicActorCount >= 2) {
      matchRules.push(this.ruleService.DYNAMICACTOR_DYNAMICACTOR);
    }

    if (dynamicActorARCount >= 2) {
      matchRules.push(this.ruleService.DYNAMICACTORAR_DYNAMICACTORAR);
    }

    if (dynamicActorCount > 0 && parallelARCount > 0) {
      matchRules.push(this.ruleService.DYNAMICACTOR_PARALLELAPPROVAL);
    }

    if (dynamicActorARCount > 0 && parallelARCount > 0) {
      matchRules.push(this.ruleService.DYNAMICACTORAR_PARALLELAPPROVAL);
    }

    if (dynamicActorCount > 0 && sequentialARCount > 0) {
      matchRules.push(this.ruleService.DYNAMICACTOR_SEQUENTIALAPPROVAL);
    }

    if (dynamicActorARCount > 0 && sequentialARCount > 0) {
      matchRules.push(this.ruleService.DYNAMICACTORAR_SEQUENTIALAPPROVAL);
    }

    if (sequentialARCount >= 2) {
      matchRules.push(this.ruleService.SEQUENTIALAPPROVAL_SEQUENTIALAPPROVAL);
    }

    if (parallelARCount >= 2) {
      matchRules.push(this.ruleService.PARALLELAPPROVAL_PARALLELAPPROVAL);
    }

    if (sequentialARCount > 0 && parallelARCount > 0) {
      matchRules.push(this.ruleService.PARALLELAPPROVAL_SEQUENTIALAPPROVAL);
    }

    return matchRules;
  }

  convergeParser(nodes: IModelNode[]): [IRule] {
    const matchRules: [IRule] = [this.ruleService.DEFAULT];

    const exclusiveGatewayCount = nodes.filter(
      (n) =>
        n.category === this.flowinglyConstants.nodeCategory.EXCLUSIVE_GATEWAY
    ).length;

    if (exclusiveGatewayCount > 0) {
      matchRules.push(this.ruleService.EXCLUSIVEGATEWAY_CONVERGEGATEWAY);
    }

    return matchRules;
  }
}

angular
  .module('flowingly.modeler.validation')
  .factory('rulesetParserService', [
    'ruleService',
    'flowinglyConstants',
    (ruleService, flowinglyConstants) =>
      new RulesetParserService(ruleService, flowinglyConstants)
  ]);

export type RulesetParserServiceType = InstanceType<
  typeof RulesetParserService
>;
