import React, { useRef } from 'react';

interface SearchProps {
  searchItem: string;
  placeholder: string;
  onSearchChange: (value: string) => void;
}

const Search: React.FC<SearchProps> = ({
  searchItem,
  onSearchChange,
  placeholder
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onSearchChange(value.toLowerCase());
    updateInputContainerClass(event.target, value);
  };

  const updateInputContainerClass = (
    inputElement: HTMLInputElement,
    value: string
  ) => {
    const inputContainer = inputElement.closest(
      '.governance-input-placeholder'
    );
    if (inputContainer) {
      if (value === '') {
        inputContainer.classList.remove('has-value');
      } else {
        inputContainer.classList.add('has-value');
      }
    }
  };

  const handleClearSearch = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      onSearchChange('');
      updateInputContainerClass(searchInputRef.current, '');
    }
  };

  return (
    <div className="governance-search-container">
      <i className="fa-light fa-magnifying-glass"></i>
      <div className="governance-input-placeholder">
        <input
          ref={searchInputRef}
          className="governance-search"
          type="text"
          value={searchItem}
          onChange={handleInputChange}
        />
        <div className="governance-placeholder">{placeholder}</div>
        {searchItem !== '' && (
          <i
            className="fa-light fa-times governance-clear"
            onClick={handleClearSearch}
            title="Clear"
          ></i>
        )}
      </div>
    </div>
  );
};

export default Search;
