/**
 * @ngdoc service
 * @name tokenService
 * @module flowingly.runner.services
 *
 * @description Service responsible for managing tokens
 */
declare const moment;

('use strict');
import { SharedAngular } from '@Angular.Runner/@types/sharedAngular';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';
// Service responsible for persisting user information using angular-storage

export interface ITenantBusiness {
  name: string;
  id: string;
}

angular.module('flowingly.services').factory('tokenService', tokenService);

tokenService.$inject = [
  '$rootScope',
  '$interval',
  '$cookies',
  '$location',
  'APP_CONFIG',
  'store',
  'jwtHelper',
  'angularAuth0',
  'momentService',
  'authLoggingApiService',
  'browserUtilsService',
  'angularAuth0SpaSdk',
  'flowinglyConstants'
];

function tokenService(
  $rootScope: angular.IRootScopeService,
  $interval: angular.IIntervalService,
  $cookies,
  $location: angular.ILocationService,
  APP_CONFIG: Services.APP_CONFIG,
  store,
  jwtHelper,
  angularAuth0,
  momentService: Moment,
  authLoggingApiService: Services.AuthLoggingApiService,
  browserUtilsService: Services.BrowserUtilsService,
  angularAuth0SpaSdk: SharedAngular.AngularAuth0SpaSdk,
  flowinglyConstants
) {
  // We are using a different namespace for Development, Staging and Production (see web.config)
  const nameSpace = 'flowingly.' + APP_CONFIG.flowinglyEnvironment;
  const tokenStoreName = nameSpace + '.token';
  const lastLoginStoreName = nameSpace + '.lastlogin';
  const ngidelExpiryName = 'ngIdle.expiry';
  let _token = store.get(tokenStoreName); //Returned when user authenticates against Auth0.com (see auth.service.js)
  let _unauthorised = false;
  let inLogoutProcess = false;
  let checkTokenExpiredPromise: Promise<string>;
  let checkTokenExpiredPending = false;

  const service = {
    getPermissions: getPermissions,
    getTenant: getTenant,
    getToken: getToken,
    checkTokenExpire: checkTokenExpire,
    clearToken: clearToken,
    refreshTokenIfPermissionsDonotExist: refreshTokenIfPermissionsDonotExist,
    setToken: setToken,
    getLastLogin: getLastLogin,
    clearLastLogin: clearLastLogin,
    setIdleExpiry: setIdleExpiry,
    manualIdleCheck: manualIdleCheck,
    isAllApplicationIdle: isAllApplicationIdle,
    setUnauthorisedAccess: setUnauthorisedAccess,
    wasUnauthorisedAccess: wasUnauthorisedAccess,
    setInLogoutProcess: setInLogoutProcess
  };

  return service;

  //////////// Public API Methods

  function setInLogoutProcess(val) {
    inLogoutProcess = val;
  }

  function setUnauthorisedAccess(access) {
    _unauthorised = access;
  }

  function wasUnauthorisedAccess() {
    return _unauthorised;
  }

  function getPermissions() {
    if (_token) {
      return jwtHelper.decodeToken(_token).flowinglypermissions;
    }
  }

  function getTenant(): ITenantBusiness {
    if (_token) {
      const tokenData = jwtHelper.decodeToken(_token);
      return {
        id: tokenData.businessidentifier,
        name: tokenData.businessname
      };
    }
  }

  function getToken() {
    _token = store.get(tokenStoreName);
    return _token;
  }

  function isAllApplicationIdle() {
    if (browserUtilsService.isApp()) {
      return false;
    }
    const idleExpiry = $cookies.get(ngidelExpiryName);

    if (
      idleExpiry &&
      momentService.utc(idleExpiry).isBefore(momentService.utc())
    )
      return true;

    return false;
  }

  function manualIdleCheck() {
    const idleExpiry = $cookies.get(ngidelExpiryName),
      interval = momentService(idleExpiry) - momentService() + 2000;

    console.log(`manualIdleCheck after seconds Web: ${interval / 1000}`);

    if (interval > 0) {
      $interval(
        () => {
          $rootScope.$broadcast('IdleStart');
        },
        interval,
        1
      );
    }
  }

  function refreshTokenIfPermissionsDonotExist(isRequestUserCondition) {
    const domainPort =
      window.location.port !== '' ? `:${window.location.port}` : '';
    const currentDomain = `${window.location.protocol}//${window.location.hostname}${domainPort}`;

    authLoggingApiService.log(
      'tokenService.ts refreshTokenIfPermissionsDonotExist() called'
    );
    const checkoldTokenPromise = new Promise((resolve, reject) => {
      // for invite user, shouldn't look local storage token at all!
      if (
        $location.path().toLowerCase() === '/acceptinvite' &&
        $location.search().inviteToken &&
        !isRequestUserCondition
      ) {
        resolve(undefined);
      } else {
        const token = getToken();

        if (token) {
          // if token expired, don't refresh it
          if (isAllApplicationIdle() && !inLogoutProcess) {
            clearToken();
            authLoggingApiService.log(
              'tokenService.ts refreshTokenIfPermissionsDonotExist() - rejecting with message Error: session expired! and sending re-dire-ct-url as /flowsactive'
            );
            reject({
              error: 'Error: session expired!',
              redirectUri: currentDomain + '/flowsactive/'
            });
          }

          if (!getPermissions()) {
            if (isAuth0AppV2(token)) {
              renewTokenAuth0SpaSdk(
                resolve,
                reject,
                'refreshTokenIfPermissionsDonotExist()'
              );
            } else {
              angularAuth0.checkSession(
                {
                  responseType: 'id_token token',
                  clientID: APP_CONFIG.auth0ClientId,
                  redirectUri: currentDomain + '/flowsactive/'
                },
                function (err, delegationResult) {
                  if (delegationResult) {
                    setToken(delegationResult.idToken);
                    authLoggingApiService.log(
                      'tokenService.ts refreshTokenIfPermissionsDonotExist() resolving with id token'
                    );
                    resolve(delegationResult.idToken);
                  } else {
                    authLoggingApiService.log(
                      'tokenService.ts refreshTokenIfPermissionsDonotExist() rejecting with message Error: Refresh of the token failed!'
                    );
                    reject('Error: Refresh of the token failed!');
                  }
                }
              );
            }
          } else {
            authLoggingApiService.log(
              'tokenService.ts refreshTokenIfPermissionsDonotExist() rejecting with message undefined'
            );
            resolve(undefined); // Token was not expired
          }
        } else {
          authLoggingApiService.log(
            'tokenService.ts refreshTokenIfPermissionsDonotExist() rejecting with message Missing token'
          );
          resolve({ error: 'Missing token' });
        }
      }
    });

    return checkoldTokenPromise;
  }

  function setToken(token, savelogin=false) {
    authLoggingApiService.log('tokenService.ts setToken() called');

    _token = token;
    store.set(tokenStoreName, _token);
    if (savelogin) {
      setLastLogin(token);
    }
    setIdleExpiry();
  }

  function setLastLogin(token) {
    const connection = jwtHelper.decodeToken(token)["connection"] || null;
    store.set(lastLoginStoreName, { connection: connection });
  }

  function getLastLogin() {
    return store.get(lastLoginStoreName);
  }

  function clearLastLogin() {
    store.remove(lastLoginStoreName);
  }

  function setIdleExpiry() {
    const expireTime = moment
      .utc()
      .add(APP_CONFIG.sessionTimeoutInSecond, 's')
      .toISOString();
    $cookies.put(ngidelExpiryName, expireTime);
  }

  function clearToken() {
    authLoggingApiService.log('tokenService.ts clearToken() called');
    store.remove(tokenStoreName);
    _token = undefined;
  }

  // Returns new token if it is refreshed, else returns null
  // If there is an error it rejects the promise
  function checkTokenExpire(redirectUri) {
    authLoggingApiService.log('tokenService.ts checkTokenExpire() called');
    if (checkTokenExpiredPending) {
      return checkTokenExpiredPromise;
    }
    checkTokenExpiredPending = true;
    checkTokenExpiredPromise = new Promise((resolve, reject) => {
      const token = getToken();
      if (!token) {
        authLoggingApiService.log(
          'tokenService.ts checkTokenExpire() rejecting with message Error: Missing token'
        );
        reject('Error: Missing token');
        checkTokenExpiredPending = false;
        return;
      }

      if (!isTokenExpired(token)) {
        authLoggingApiService.log(
          'tokenService.ts checkTokenExpire() resolving with message null'
        );
        resolve(null); // Token was not expired
        checkTokenExpiredPending = false;
        return;
      }

      if (isAuth0AppV2(token)) {
        renewTokenAuth0SpaSdk(resolve, reject);
      } else {
        const domainPort =
          window.location.port !== '' ? `:${window.location.port}` : '';
        const currentDomain = `${window.location.protocol}//${window.location.hostname}${domainPort}`;
        angularAuth0.checkSession(
          {
            responseType: 'id_token token',
            clientID: APP_CONFIG.auth0ClientId,
            redirectUri: currentDomain + (redirectUri || '')
          },
          function (err, delegationResult) {
            if (delegationResult) {
              setToken(delegationResult.idToken);
              authLoggingApiService.log(
                'tokenService.ts checkTokenExpire() resolving with id token'
              );
              resolve(delegationResult.idToken);
            } else {
              authLoggingApiService.log(
                'tokenService.ts checkTokenExpire() checkSession Error: ' +
                  JSON.stringify(err)
              );
              reject('Error: Refresh of the token failed!');
            }
            checkTokenExpiredPending = false;
          }
        );
      }
    });

    return checkTokenExpiredPromise;
  }

  async function renewTokenAuth0SpaSdk(
    resolve: (value: string | PromiseLike<string>) => void,
    reject: (reason?: any) => void,
    caller = 'checkTokenExpire()'
  ) {
    if (
      window.location.pathname === '/modeler/' &&
      APP_CONFIG.flowinglyEnvironment ===
        flowinglyConstants.flowinglyEnvironments.Local
    ) {
      console.error(
        'Please close this page, refresh runner, and reopen modeler.This issue occurs only in local enviornment. Since the refresh token saved in local storage by runner cannot be used by modeler for token renewal, as it is running on a different port.'
      );
      return;
    }

    try {
      const auth0Client = await angularAuth0SpaSdk.getClient();
      await auth0Client.getTokenSilently({
        detailedResponse: true,
        cacheMode: 'off'
      });
      const claims = await auth0Client.getIdTokenClaims();
      const idToken = claims.__raw;
      setToken(idToken);
      checkTokenExpiredPending = false;
      authLoggingApiService.log(
        `tokenService.ts ${caller} resolving with id token`
      );
      resolve(idToken);
    } catch (error) {
      checkTokenExpiredPending = false;
      reject('Error: Refresh of the token failed!');
    }
  }

  function isTokenExpired(token) {
    const willExpireInSeconds = 45;
    return (
      jwtHelper.getTokenExpirationDate(token) - new Date().getTime() <
      willExpireInSeconds * 1000
    );
  }

  function isAuth0AppV2(token: string) {
    const clientIdInToken = jwtHelper.decodeToken(token).aud;
    return clientIdInToken === APP_CONFIG.auth0AppV2ClientId;
  }
}

export type TokenServiceType = ReturnType<typeof tokenService>;
