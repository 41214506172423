'use strict';
import angular, { ITemplateCacheService } from 'angular';
const runnerGovernanceModule = angular.module(
  'flowingly.runner.governance',
  []
);
runnerGovernanceModule.run([
  '$templateCache',
  ($templateCache: ITemplateCacheService) => {
    $templateCache.put(
      'Client/runner.governance/runner.governance.tmpl.html',
      require('./runner.governance.tmpl.html').default
    );
  }
]);
export default runnerGovernanceModule;
