import React, { useEffect, useState } from 'react';
import ProcessMapsList from './List/ProcessMapsList';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import Category from '@Client/runner.directives/common/Category/Category';

type Props = {
  isMobile: boolean;
  backFromState: string;
};

const ProcessMaps = (props: Props) => {
  const { isMobile, backFromState } = props;

  const allCategoriesId = 'all-categories';
  const recentlyViewedId = 'recently-viewed';
  const allCategoriesIndex = '0';

  const [selectedCategory, setSelectedCategory] =
    React.useState(allCategoriesId);

  const appConfig = useService<SharedAngular.APP_CONFIG>('APP_CONFIG');
  const flowListManager = useService<FlowListManager>('flowListManager');
  const lodashService = useService<Lodash>('lodashService');
  const pubSubService =
    useService<SharedAngular.PubSubService>('pubsubService');
  const appInsightsService =
    useService<SharedAngular.AppInsightsService>('appInsightsService');
  const runnerCategoryNavigationService =
    useService<RunnerCategoryNavigationService>(
      'runnerCategoryNavigationService'
    );

  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [expandedCategory, setExpandedCategory] = React.useState(null);

  const [allowProcessMap] = useState(appConfig.allowProcessMap);
  const [showSubCategories] = useState(appConfig.enableSubCategories);
  const [categorisedProcessMaps, setCategorisedProcessMaps] = useState([]);
  const [processMaps, setProcessMaps] = useState([]);
  const [filteredProcessMaps, setFilteredProcessMaps] = useState([]);

  useEffect(() => {
    const processMapSubscriberId = 'flowingly.runner.processmap';
    pubSubService.subscribe(
      'SIGNALR_WORKFLOW_NAME_CHANGED',
      (event, params) => updateFlowModelName(params),
      processMapSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_SETUP_CATEGORY_DELETED',
      onCategoryDeleted,
      processMapSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_SETUP_FLOW_MODEL_DELETED',
      onFlowModelDeleted,
      processMapSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_WORKFLOW_PUBLISHED',
      loadProcessMaps,
      processMapSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_WORKFLOW_UNPUBLISHED',
      loadProcessMaps,
      processMapSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_ACTOR_DELETED',
      (event, params) => removeDeletedActor(params),
      processMapSubscriberId
    );

    return () => {
      pubSubService.unsubscribeAll(processMapSubscriberId);
    };
  }, [categorisedProcessMaps, selectedCategory]);

  const fetchCategorizedProcessMaps = async () => {
    appInsightsService.startEventTimer('flowLoadProcessMapList');
    const categorisedProcessMaps =
      await flowListManager.getCategorizedProcessMaps(false);
    pubSubService.publish('PROCESSMAPV2_LOADED', {
      loaded: true,
      count: categorisedProcessMaps.length
    });
    if (appConfig.enableSubCategories) {
      setCategorisedProcessMaps(
        runnerCategoryNavigationService.getFormattedTreeData(
          categorisedProcessMaps
        )
      );
    } else {
      setCategorisedProcessMaps(categorisedProcessMaps);
      setProcessMaps(
        runnerCategoryNavigationService.getFlowModelsFromNestedCategories(
          categorisedProcessMaps
        )
      );
      filterFlowsByCategory(allCategoriesId);
    }
    appInsightsService.trackMetricIfTimerExist('flowLoadProcessMapList');
  };

  const filterFlowsByCategory = (
    id: string,
    categoryIndex?: string,
    categoryName?: string,
    expandedCategory?: string[]
  ) => {
    if (expandedCategory) {
      flowListManager.setExpandedCategoriesList(expandedCategory);
    }
    if (categoryIndex != null) {
      flowListManager.setProcessMapCategoryId(id);
      flowListManager.setProcessMapCategoryIndex(categoryIndex);
    }
    if (backFromState === 'app.runner.processmapviewv2') {
      id = flowListManager.getProcessMapCategoryId();
      categoryIndex = flowListManager.getProcessMapCategoryIndex();
      setExpandedCategory(flowListManager.getExpandedCategoriesList());
    }

    if (
      !runnerCategoryNavigationService.findNodeById(categorisedProcessMaps, id)
    ) {
      if (id != recentlyViewedId) {
        id = allCategoriesId;
        categoryIndex = allCategoriesIndex;
      }
    }
    setSelectedCategory(id);
    if (categoryIndex == null) {
      setSelectedIndex(flowListManager.getProcessMapCategoryIndex());
    } else {
      setSelectedIndex(categoryIndex);
    }

    if (appConfig.enableSubCategories) {
      if (id === allCategoriesId) {
        setFilteredProcessMaps(
          extractFlowModelsFromArray(categorisedProcessMaps)
        );
      } else if (id === recentlyViewedId) {
        setFilteredProcessMaps(viewedRecently(categorisedProcessMaps));
      } else {
        const filteredCategories = runnerCategoryNavigationService.findNodeById(
          categorisedProcessMaps,
          id
        );
        setFilteredProcessMaps(filteredCategories.flowModels);
      }
    } else {
      if (id === allCategoriesId) {
        setFilteredProcessMaps(processMaps);
      } else if (id === recentlyViewedId) {
        setFilteredProcessMaps(viewedRecently(categorisedProcessMaps));
      } else {
        const filteredMaps = processMaps.filter((map) => map.categoryId === id);
        setFilteredProcessMaps(filteredMaps);
      }
    }
  };

  const getProcesMapsFromCategorisedProcessmaps = () => {
    if (!categorisedProcessMaps) {
      return;
    }
    let processMaps;
    if (showSubCategories) {
      processMaps =
        runnerCategoryNavigationService.getFlowModelsFromNestedCategories(
          categorisedProcessMaps
        );
    } else {
      processMaps = lodashService.flatMap(categorisedProcessMaps, 'flowModels');
    }
    setProcessMaps(processMaps);
    filterFlowsByCategory(selectedCategory);
  };

  useEffect(() => {
    fetchCategorizedProcessMaps();
  }, []);

  useEffect(() => {
    getProcesMapsFromCategorisedProcessmaps();
  }, [categorisedProcessMaps]);

  const extractFlowModelsFromArray = (categories) => {
    let result = [];
    categories.forEach((category) => {
      if (category.flowModels?.length > 0) {
        result = result.concat(category.flowModels);
      }
      if (category.items?.length > 0) {
        result = result.concat(extractFlowModelsFromArray(category.items));
      }
    });
    return result;
  };

  const viewedRecently = (categorisedProcessMaps) => {
    const result = extractFlowModelsFromArray(categorisedProcessMaps);
    const filteredData = result.filter((item) => item.viewedOn !== null);
    return filteredData.sort((a, b) => {
      return new Date(b.viewedOn).getTime() - new Date(a.viewedOn).getTime();
    });
  };

  const handleFlowModelClick = (id: string) => {
    pubSubService.publish('PROCESSMAPV2_CLICKED', id);
  };

  const updateFlowModelName = (jsonParams: string) => {
    if (!Array.isArray(categorisedProcessMaps)) {
      return;
    }

    const params = JSON.parse(jsonParams);
    const updatedMap = updateFlowModelNameInCategorisedData(
      categorisedProcessMaps,
      params.id,
      params.name
    );
    if (updatedMap) {
      setCategorisedProcessMaps([...categorisedProcessMaps]);
    }
  };

  const selectCategory = (categoryName: string) => {
    setSelectedCategory(
      categorisedProcessMaps.find(({ name }) => name === categoryName).name
    );
    setNullSelectedCategoryToAllCategory();
  };

  const setNullSelectedCategoryToAllCategory = () => {
    if (selectedCategory == allCategoriesId) {
      setSelectedCategory(allCategoriesId);
    }
  };

  const onCategoryDeleted = () => {
    fetchCategorizedProcessMaps();
  };

  const onFlowModelDeleted = () => {
    fetchCategorizedProcessMaps();
  };

  const loadProcessMaps = () => {
    fetchCategorizedProcessMaps();
  };

  const removeDeletedActor = (jsonParams: string) => {
    if (!Array.isArray(categorisedProcessMaps)) {
      return;
    }
    const params = JSON.parse(jsonParams);
    let updatedFlowModels = false;
    const updatedCategorisedProcessMaps = categorisedProcessMaps.map(
      (category) => {
        const updatedFlowModelsInCategory = category.flowModels.map(
          (flowModel) => {
            if (flowModel.processOwnerName === params.actorName) {
              updatedFlowModels = true;
              return { ...flowModel, processOwnerName: null };
            }
            return flowModel;
          }
        );
        return { ...category, flowModels: updatedFlowModelsInCategory };
      }
    );

    setCategorisedProcessMaps(updatedCategorisedProcessMaps);

    if (updatedFlowModels) {
      selectCategory(selectedCategory);
    }
  };

  const updateFlowModelNameInCategorisedData = (
    categorisedProcessMaps,
    mapId: string,
    nameToBeUpdated: string
  ) => {
    for (const category of categorisedProcessMaps) {
      const map = category.flowModels.find((x) => x.id === mapId);
      if (map) {
        map.name = nameToBeUpdated;
        return map;
      }

      if (category.items) {
        const foundMap = updateFlowModelNameInCategorisedData(
          category.items,
          mapId,
          nameToBeUpdated
        );
        if (foundMap) {
          return foundMap;
        }
      }
    }
    return undefined;
  };

  return (
    <div className="categories-row row">
      {allowProcessMap && categorisedProcessMaps.length > 0 && (
        <div>
          <Category
            categorisedItems={categorisedProcessMaps}
            selectedCategory={selectedCategory}
            selectedIndex={selectedIndex}
            expandedCategory={expandedCategory}
            allCategoriesId={allCategoriesId}
            recentlyViewed={recentlyViewedId}
            isMobile={isMobile}
            onCategoryClick={filterFlowsByCategory}
            onFlowModelListClick={handleFlowModelClick}
          />
          <div className={isMobile ? '' : 'cards-col col mt-20'}>
            {filteredProcessMaps && (
              <ProcessMapsList
                processMaps={filteredProcessMaps}
                isMobile={isMobile}
                onFlowModelClick={handleFlowModelClick}
                selectedCategory={selectedCategory}
                showMyMapsTab={
                  processMaps.filter((x) => x.isUserProcessOwner).length > 0
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProcessMaps;
