import { Services } from '@Shared.Angular/@types/services';
import INodeCommandData from '@Shared.Angular/@types/core/contracts/commands/modeler/nodeCommandData';
import IGroupDetail from '@Shared.Angular/@types/core/contracts/queryModel/group/groupDetail';
import {
  ActorTypes,
  DynamicActorTypeIds,
  DynamicActorTypeNames,
  FormFieldType,
  FormFieldTypePascal,
  NodeCategory,
  TaskType
} from '@Shared.Angular/flowingly.services/flowingly.constants';
import {
  IFlowModelParser,
  IImportableFlowModelFile,
  ImportableFlowModel,
  ImportableFlowModelState
} from '../flow.model.import.service';
import IModelNode from '@Shared.Angular/@types/modelNode';
import { IModelNodeLink } from '@Shared.Angular/@types/workflow';

interface ISwimLaneDetails {
  name: string;
  count: number;
  start: number;
  key: number;
}

interface IGroup {
  id: string;
  name: string;
}

export default class FlowModelXmlParser implements IFlowModelParser {
  constructor(
    private guidService: Services.GuidService,
    private avatarService: Services.AvatarService,
    private flowinglyConstants: Services.FlowinglyConstants,
    private notificationService: Services.NotificationService
  ) {}

  private MODEL_X_SPACING = 210;
  private MODEL_Y_SPACING = 200;
  private key = 0;

  parseImportables = (importFiles: IImportableFlowModelFile[]) => {
    const imports: Promise<ImportableFlowModel[]>[] = [];
    importFiles.forEach((importFile) => {
      if (importFile.file.name.toLowerCase().endsWith('.xml')) {
        this.key = 0;
        try {
          const importableProcesses = this.convertXmlProcess(importFile.file);
          imports.push(importableProcesses);
          importableProcesses.then(
            (importables) => (importFile.handled = importables.length > 0)
          );
        } catch {
          /* empty */
        }
      }
    });
    return Promise.allSettled(imports).then((results) => {
      return results
        .filter((result) => result.status === 'fulfilled')
        .map(
          (result: PromiseFulfilledResult<ImportableFlowModel[]>) =>
            result.value
        )
        .flat();
    });
  };

  private convertXmlProcess = (file: File) => {
    const result = file.text().then((text) => {
      const cleanText = text.replaceAll(
        // eslint-disable-next-line no-control-regex
        new RegExp('[\\x00-\\x08\\x0e-\\x1f]+', 'g'),
        ''
      );
      const parser = new DOMParser();
      const xmlDocument = parser.parseFromString(cleanText, 'application/xml');
      const error = xmlDocument.querySelector('parsererror');
      if (error) {
        console.error(error);
        this.notificationService.showErrorToast(
          error.childNodes[1].textContent,
          5000
        );
        return [];
      }
      this.removeWhitespaceNodes(xmlDocument);
      const flowModels = Array.from(xmlDocument.childNodes)
        .map((node) => {
          if (node.nodeName === NodeName.ProcessGroup) {
            return this.parseProcessGroup(node, []);
          } else if (node.nodeName === NodeName.Process) {
            return this.parseProcess(node, []);
          } else {
            return [] as ImportableFlowModel[];
          }
        })
        .flat();
      const uniqueFlowModels: ImportableFlowModel[] = [];
      const map = new Map();
      for (const item of flowModels) {
        if (!map.has(item.key)) {
          map.set(item.key, true);
          uniqueFlowModels.push(item);
        }
      }
      uniqueFlowModels.forEach((flowModel) => (flowModel.file = file));
      return uniqueFlowModels;
    });

    return result;
  };

  private removeWhitespaceNodes = (node: Node) => {
    for (let i = node.childNodes.length; i-- > 0; ) {
      const child = node.childNodes[i];
      if (child.nodeType === 3 && child.nodeValue.match(/^\s*$/)) {
        node.removeChild(child);
      } else if (child.nodeType === 1) {
        this.removeWhitespaceNodes(child);
      }
    }
  };

  private parseProcessGroup = (
    processGroup: Node,
    hierarchy: IGroup[]
  ): ImportableFlowModel[] => {
    const localHierarchy = [...hierarchy];
    const groupId = (processGroup as Element).getAttribute('Id');
    const groupName = (processGroup as Element).getAttribute('Name');
    localHierarchy.push({ id: groupId, name: groupName });
    return Array.from(processGroup.childNodes)
      .map((childNode) => {
        if (childNode.nodeName === NodeName.ProcessGroup) {
          return this.parseProcessGroup(childNode, localHierarchy);
        } else if (childNode.nodeName === NodeName.ProcessGroupItems) {
          return this.parseProcessGroupItems(childNode, localHierarchy);
        } else {
          return [] as ImportableFlowModel[];
        }
      })
      .flat();
  };

  private parseProcessGroupItems = (
    processGroupItems: Node,
    hierarchy: IGroup[]
  ) => {
    if (
      !processGroupItems.childNodes ||
      processGroupItems.childNodes.length === 0
    ) {
      const hierarchyNames = hierarchy.map((group) => group.name);
      return [
        new ImportableFlowModel({
          key: hierarchyNames.join('/'),
          categoryHierarchy: hierarchyNames,
          willImport: true,
          state: ImportableFlowModelState.Pending
        })
      ];
    }
    return Array.from(processGroupItems.childNodes)
      .map((childNode) => {
        if (childNode.nodeName === NodeName.ProcessGroup) {
          return this.parseProcessGroup(childNode, hierarchy);
        } else if (childNode.nodeName === NodeName.Process) {
          return this.parseProcess(childNode, hierarchy);
        } else {
          return [] as ImportableFlowModel[];
        }
      })
      .flat();
  };

  private parseProcess = (process: Node, hierarchy: IGroup[]) => {
    let result: ImportableFlowModel[];
    try {
      const childNodes = Array.from(process.childNodes);
      result = childNodes
        .map((childNode) => {
          if (childNode.nodeName !== NodeName.ProcessProcedures) {
            return [] as ImportableFlowModel[];
          }
          const processElement = process as Element;
          const name = processElement.getAttribute('Name');
          const id = processElement.getAttribute('Id');
          const uniqueId = processElement.getAttribute('UniqueId');
          const state = processElement.getAttribute('State');
          const importable = new ImportableFlowModel({
            key: id,
            uniqueId: uniqueId,
            name: name,
            processOwnerName: processElement.getAttribute('Owner'),
            publish: state !== 'Draft',
            description: processElement
              .getAttribute('Objective')
              ?.replaceAll('|~|', ''),
            background: (
              processElement.getAttribute('Background')?.replaceAll('|~|', '') +
              this.getSearchKeywords(
                childNodes.find((n) => n.nodeName === NodeName.SearchKeywords)
              )
            ).substring(0, 500),
            triggerInput: this.getTriggers(
              childNodes.find((n) => n.nodeName === NodeName.Triggers)
            )?.substring(0, 500),
            processInput: this.getInputs(
              childNodes.find((n) => n.nodeName === NodeName.Inputs)
            )?.substring(0, 500),
            processOutput: (
              this.getOutputs(
                childNodes.find((n) => n.nodeName === NodeName.Outputs)
              ) +
              this.getTargets(
                childNodes.find((n) => n.nodeName === NodeName.Targets)
              )
            )?.substring(0, 500),
            categoryHierarchy: hierarchy.map((group) => group.name),
            teams: this.getTeamNames(childNode).map((teamName) => {
              return { name: teamName } as IGroupDetail;
            }),
            modelNodes: [],
            modelLinks: [],
            flowinglyNodes: [],
            willImport: true,
            state: ImportableFlowModelState.Pending
          });

          this.parseProcessProcedures(childNode, importable);

          const itemsNode = childNodes.find(
            (n) => n.nodeName === NodeName.ProcessGroupItems
          );
          const groupNode = Array.from(itemsNode?.childNodes || []).find(
            (n) => n.nodeName === NodeName.ProcessGroup
          );
          const owningGroupId = (groupNode as Element).getAttribute('Id');
          if (hierarchy.length > 0 && hierarchy.at(-1).id !== owningGroupId) {
            return [] as ImportableFlowModel[];
          } else if (hierarchy.length === 0) {
            importable.categoryHierarchy = groupNode
              ? [(groupNode as Element).getAttribute('Name')]
              : [];
          }

          return [importable];
        })
        .flat();
    } catch (exception) {
      console.debug(exception);
      result = [];
    }
    return result;
  };

  private getInputs = (inputs: Node) => {
    if (!inputs) {
      return '';
    }
    const inputString = Array.from(inputs.childNodes)
      .map((input) => {
        const inputElement = input as Element;
        return `${inputElement.getAttribute(
          'Resource'
        )} | ${inputElement.getAttribute(
          'FromProcess'
        )} | ${inputElement.getAttribute('HowUsed')}`;
      })
      .join('. ');
    return inputString;
  };

  private getTriggers = (triggers: Node) => {
    if (!triggers) {
      return '';
    }
    const triggerString = Array.from(triggers.childNodes)
      .map((trigger) => {
        const triggerElement = trigger as Element;
        return `${triggerElement.getAttribute(
          'Trigger'
        )} | ${triggerElement.getAttribute(
          'Frequency'
        )} | ${triggerElement.getAttribute('Volume')}`;
      })
      .join('. ');
    return triggerString;
  };

  private getOutputs = (outputs: Node) => {
    if (!outputs) {
      return '';
    }
    const outputString = Array.from(outputs.childNodes)
      .map((input) => {
        const outputElement = input as Element;
        return `${outputElement.getAttribute(
          'Output'
        )} | ${outputElement.getAttribute(
          'ToProcess'
        )} | ${outputElement.getAttribute('HowUsed')}`;
      })
      .join('. ');
    return outputString;
  };

  private getTargets = (targets: Node) => {
    if (!targets) {
      return '';
    }
    const targetString = Array.from(targets.childNodes)
      .map((target) => {
        const targetElement = target as Element;
        return `${targetElement.getAttribute(
          'Target'
        )} | ${targetElement.getAttribute('Measure')}`;
      })
      .join('. ');
    return targetString;
  };

  private getSearchKeywords = (keywords: Node) => {
    if (!keywords) {
      return '';
    }
    const keywordString = Array.from(keywords.childNodes)
      .map((keyword) => ' ' + keyword.textContent + '.')
      .join('');
    return '.' + keywordString;
  };

  private getTeamNames = (processProcedures: Node) => {
    const allRoles = Array.from(processProcedures.childNodes)
      .filter((node) => node.nodeName === NodeName.Activity)
      .map((activity) =>
        Array.from(activity.childNodes).find(
          (node) => node.nodeName === NodeName.Ownerships
        )
      )
      .flatMap((ownerships) =>
        ownerships ? Array.from(ownerships.childNodes) : []
      )
      .filter((ownership) => ownership.nodeName === NodeName.Role)
      .map((role) => (role as Element).getAttribute('Name'));
    return [...new Set(allRoles).values()];
  };

  private createStartNode = () => {
    const node: IModelNode = {
      id: this.guidService.new(),
      key: this.key++,
      category: NodeCategory.EVENT,
      text: 'Start',
      eventType: 1,
      eventDimension: 1,
      item: 'start',
      isNew: false,
      IsInitial: 'true',
      loc: `${-this.MODEL_X_SPACING} ${-this.MODEL_Y_SPACING}`
    };
    return node;
  };

  private createEndNode = (priorNode: IModelNode) => {
    const locationParts = priorNode.loc.split(' ');
    const priorNodeX = parseInt(locationParts[0]);
    const priorNodeY = parseInt(locationParts[1]);
    const node: IModelNode = {
      id: this.guidService.new(),
      key: this.key++,
      category: NodeCategory.EVENT,
      text: 'End',
      eventType: 1,
      eventDimension: 8,
      item: 'End',
      isNew: false,
      IsFinal: 'true',
      loc: `${priorNodeX + this.MODEL_X_SPACING * 0.75} ${priorNodeY}`
    };
    return node;
  };

  // Assumption: the Activities etc within ProcessProcedures
  //  are in actual process order
  private parseProcessProcedures = (
    processProcedures: Node,
    importable: ImportableFlowModel
  ) => {
    const parallelGroupedNodes = this.getProcessNodesInParallelGroups(
      Array.from(processProcedures.childNodes)
    );

    const swimLaneDetails = this.getSwimLaneDetails(parallelGroupedNodes);

    const startNode = this.createStartNode();
    importable.modelNodes.push(startNode);
    let priorNodes = [startNode];
    let divergeCount = 0;
    let mergeCount = 0;
    let decisionCount = 0;
    let columnIndex = 0;
    const processedDecisionActivityIds: string[] = [];
    const decisionKeyToIsYesLink = {};
    parallelGroupedNodes.forEach((parallelGroup) => {
      const activityDecisionCount =
        this.getDecisionWithActivityCount(parallelGroup);
      const insideDiverge = parallelGroup.length - activityDecisionCount > 1;
      if (insideDiverge) {
        const divergeNode = this.createDivergeModelNode(
          columnIndex + divergeCount + mergeCount,
          priorNodes
        );
        importable.modelNodes.push(divergeNode);
        const links = this.createLinkBetweenNodes(
          priorNodes,
          divergeNode,
          decisionKeyToIsYesLink
        );
        importable.modelLinks.push(...links);
        priorNodes = [divergeNode];
        divergeCount++;
      }

      const xIndex = columnIndex + divergeCount + mergeCount;
      const parallelNodes: IModelNode[] = [];
      parallelGroup.forEach((node, parallelIndex) => {
        const nodeId = (node as Element).getAttribute('Id');
        if (processedDecisionActivityIds.includes(nodeId)) {
          return;
        }

        if (node.nodeName === NodeName.Activity) {
          const modelNode = this.createModelNodeFromActivity(
            node,
            xIndex,
            parallelIndex,
            parallelNodes,
            swimLaneDetails
          );
          importable.modelNodes.push(modelNode);
          const links = this.createLinkBetweenNodes(
            priorNodes,
            modelNode,
            decisionKeyToIsYesLink
          );
          importable.modelLinks.push(...links);
          parallelNodes.push(modelNode);
          const flowinglyNode = this.createFlowinglyNodeFromActivity(
            node,
            modelNode,
            importable.modelNodes.indexOf(modelNode)
          );
          importable.flowinglyNodes.push(flowinglyNode);
        } else if (node.nodeName === NodeName.ProcessLink) {
          const modelNode = this.createModelNodeFromProcessLink(
            node,
            xIndex,
            parallelIndex,
            parallelNodes,
            swimLaneDetails
          );
          importable.modelNodes.push(modelNode);
          const links = this.createLinkBetweenNodes(
            priorNodes,
            modelNode,
            decisionKeyToIsYesLink
          );
          importable.modelLinks.push(...links);
          parallelNodes.push(modelNode);
          const flowinglyNode = this.createFlowinglyNodeFromComponent(
            node,
            modelNode,
            importable.modelNodes.indexOf(modelNode)
          );
          importable.flowinglyNodes.push(flowinglyNode);
        } else if (node.nodeName === NodeName.OrphanProcessLink) {
          const modelNodes = this.createModelNodesFromDecision(
            node,
            xIndex,
            parallelIndex,
            parallelNodes,
            swimLaneDetails
          );
          columnIndex++; // For the alternative path node
          importable.modelNodes.push(...modelNodes);
          const decision = modelNodes.find(
            (node) => node.category === NodeCategory.EXCLUSIVE_GATEWAY
          );
          const linkedNode = modelNodes.find(
            (node) =>
              node.category === NodeCategory.COMPONENT ||
              node.category === NodeCategory.ACTIVITY
          );

          const decisionLinks = this.createLinkBetweenNodes(
            [decision],
            linkedNode,
            decisionKeyToIsYesLink
          );
          importable.modelLinks.push(...decisionLinks);

          const endNode = modelNodes.find(
            (node) => node.category === NodeCategory.EVENT
          );
          const endLink = this.createLinkBetweenNodes(
            [linkedNode],
            endNode,
            decisionKeyToIsYesLink
          );
          importable.modelLinks.push(...endLink);

          const links = this.createLinkBetweenNodes(
            priorNodes,
            decision,
            decisionKeyToIsYesLink
          );
          importable.modelLinks.push(...links);
          parallelNodes.push(decision);
          const flowinglyNode = this.createFlowinglyNodeFromComponent(
            node,
            linkedNode,
            modelNodes.indexOf(linkedNode)
          );
          importable.flowinglyNodes.push(flowinglyNode);
          decisionCount++;
        } else if (node.nodeName === NodeName.Decision) {
          const decisionActivity = this.getActivityForDecision(
            node,
            parallelGroup
          );
          if (decisionActivity) {
            const decisionActivityId = (
              decisionActivity as Element
            ).getAttribute('Id');
            processedDecisionActivityIds.push(decisionActivityId);
          }
          const modelNodes = this.createModelNodesFromDecision(
            node,
            xIndex,
            parallelIndex,
            parallelNodes,
            swimLaneDetails,
            decisionActivity
          );
          columnIndex++; // For the alternative path node
          importable.modelNodes.push(...modelNodes);
          const decision = modelNodes.find(
            (node) => node.category === NodeCategory.EXCLUSIVE_GATEWAY
          );
          const linkedNode = modelNodes.find(
            (node) =>
              node.category === NodeCategory.COMPONENT ||
              node.category === NodeCategory.ACTIVITY
          );
          const endNode = modelNodes.find(
            (node) => node.category === NodeCategory.EVENT
          );
          const decisionLinks = this.createLinkBetweenNodes(
            [decision],
            linkedNode,
            decisionKeyToIsYesLink
          );
          const isYesLink =
            (node as Element).getAttribute('DecisionLinkIsYes') === 'true';
          decisionKeyToIsYesLink[decision.key] = isYesLink;
          decisionLinks[0].text = isYesLink ? 'Yes' : 'No';
          importable.modelLinks.push(...decisionLinks);

          const endLink = this.createLinkBetweenNodes(
            [linkedNode],
            endNode,
            decisionKeyToIsYesLink
          );
          importable.modelLinks.push(...endLink);
          const links = this.createLinkBetweenNodes(
            priorNodes,
            decision,
            decisionKeyToIsYesLink
          );
          importable.modelLinks.push(...links);
          parallelNodes.push(decision);
          const flowinglyNode = decisionActivity
            ? this.createFlowinglyNodeFromActivity(
                decisionActivity,
                linkedNode,
                importable.modelNodes.indexOf(linkedNode)
              )
            : this.createFlowinglyNodeFromComponent(
                node,
                linkedNode,
                importable.modelNodes.indexOf(linkedNode)
              );
          importable.flowinglyNodes.push(flowinglyNode);
          decisionCount++;
        }
      });
      priorNodes = parallelNodes;

      if (insideDiverge) {
        mergeCount++;
        const mergeNode = this.createMergeModelNode(
          columnIndex + divergeCount + mergeCount,
          priorNodes
        );
        importable.modelNodes.push(mergeNode);
        const links = this.createLinkBetweenNodes(
          priorNodes,
          mergeNode,
          decisionKeyToIsYesLink
        );
        importable.modelLinks.push(...links);

        const decision = importable.modelNodes
          .toReversed()
          .find((n) => n.category === NodeCategory.DIVERGE_GATEWAY);
        const decisionLoc = decision.loc.split(' ');
        decision.loc = decisionLoc[0] + ' ' + mergeNode.loc.split(' ')[1];

        priorNodes = [mergeNode];
      }

      columnIndex++;
    });

    const swimLaneNodes = this.createSwimLaneModelNodes(
      swimLaneDetails,
      parallelGroupedNodes.length +
        divergeCount +
        mergeCount +
        decisionCount +
        1
    );
    importable.modelNodes.push(...swimLaneNodes);

    const endNode = this.createEndNode(priorNodes[0]);
    importable.modelNodes.push(endNode);
    const links = this.createLinkBetweenNodes(
      priorNodes,
      endNode,
      decisionKeyToIsYesLink
    );
    importable.modelLinks.push(...links);
  };

  private getDecisionWithActivityCount = (nodes: Node[]) => {
    return nodes.filter(
      (node) =>
        node.nodeName === NodeName.Decision &&
        (node as Element).getAttribute('DecisionLinkType') === 'Activity'
    ).length;
  };

  private getActivityForDecision = (decision: Node, nodes: Node[]) => {
    if ((decision as Element).getAttribute('DecisionLinkType') !== 'Activity') {
      return null;
    }
    const decisionUniqueId = (decision as Element).getAttribute('UniqueId');
    const decisionActivity = nodes.find(
      (node) =>
        (node as Element).getAttribute('LinkedDecisionUniqueId') ===
        decisionUniqueId
    );
    return decisionActivity;
  };

  private getSwimLaneDetails = (parallelGroupedNodes) => {
    const swimLanes: ISwimLaneDetails[] = [];
    parallelGroupedNodes.forEach((parallelGroup) => {
      const groupLanes: ISwimLaneDetails[] = [];
      parallelGroup.forEach((node) => {
        const ownership = this.getNodeOwnership(node);
        let lane = groupLanes.find((l) => l.name === ownership);
        if (!lane) {
          lane = {
            name: ownership,
            count: 0,
            start: 0,
            key: this.key++
          };
          groupLanes.push(lane);
        }
        lane.count +=
          node.nodeName === NodeName.Decision ||
          node.nodeName === NodeName.OrphanProcessLink
            ? 2
            : 1;
      });
      groupLanes.forEach((lane) => {
        const swimLane = swimLanes.find((l) => l.name === lane.name);
        if (!swimLane) {
          swimLanes.push(lane);
        } else {
          swimLane.count = Math.max(swimLane.count, lane.count);
        }
      });
    });
    let totalLaneHeight = 0;
    swimLanes.forEach((lane) => {
      lane.start = totalLaneHeight;
      totalLaneHeight += lane.count;
    });
    return swimLanes;
  };

  private createSwimLaneModelNodes = (
    swimLaneDetails: ISwimLaneDetails[],
    nodeGroupCount: number
  ) => {
    if (swimLaneDetails.length === 0) {
      return [];
    }
    const nodes: IModelNode[] = [];
    const poolKey = this.key++;
    const xOrigin = -(this.MODEL_X_SPACING * 1.5);
    const laneWidth = Math.max(nodeGroupCount, 2.5) * this.MODEL_X_SPACING;
    const yOrigin = -(this.MODEL_Y_SPACING / 2);
    const poolNode: IModelNode = {
      key: poolKey,
      text: 'Pool',
      isGroup: 'true',
      category: NodeCategory.POOL,
      loc: `${xOrigin} ${yOrigin}`,
      id: this.guidService.new()
    };
    nodes.push(poolNode);

    const laneColours = [
      '#eae5f2',
      '#f9dfd9',
      '#fbf3e1',
      '#e8efe5',
      '#d4ebe9',
      '#dbe6f3',
      '#f3e5e8',
      '#fdecdf',
      '#d3dadc'
    ];
    let totalLaneHeight = 0;
    swimLaneDetails.forEach((l, index) => {
      const laneHeight = l.count * this.MODEL_Y_SPACING;
      const laneNode: IModelNode = {
        key: l.key,
        text: l.name,
        isGroup: 'true',
        group: poolKey,
        category: NodeCategory.LANE,
        loc: `${xOrigin} ${yOrigin + totalLaneHeight}`,
        size: `${laneWidth} ${laneHeight}`,
        id: this.guidService.new(),
        color: laneColours[index % laneColours.length]
      };
      nodes.push(laneNode);

      totalLaneHeight += laneHeight;
    });
    return nodes;
  };

  private getNodeOwnership = (node: Node) => {
    const ownerships = Array.from(node.childNodes).find(
      (child) => child.nodeName === NodeName.Ownerships
    );
    if (ownerships == undefined) {
      return '';
    }
    const ownerRoles = Array.from(ownerships.childNodes)
      .filter(
        (child) =>
          child.nodeName === NodeName.Role ||
          (child.nodeName === NodeName.Tag &&
            (child as Element).getAttribute('IsSwimlaneParticipant') === 'true')
      )
      .map((child) => (child as Element).getAttribute('Name'))
      .sort()
      .join('\n');
    return ownerRoles;
  };

  private getProcessNodesInParallelGroups = (nodes: Node[]) => {
    const groupedNodes: Node[][] = [];
    const handledRefs = [];
    const decisionActivities: Node[] = [];
    nodes.forEach((node) => {
      if (decisionActivities.includes(node)) {
        return;
      }
      const parallelGroupingRef = (node as Element).getAttribute(
        'ParallelGroupingRef'
      );
      if (handledRefs.includes(parallelGroupingRef)) {
        return;
      }

      if (parallelGroupingRef == null) {
        const group = [node];
        if (node.nodeName === NodeName.Decision) {
          const decisionActivity = this.getActivityForDecision(node, nodes);
          if (decisionActivity) {
            group.push(decisionActivity);
            decisionActivities.push(decisionActivity);
          }
        }
        groupedNodes.push(group);
      } else {
        const parallelNodes = nodes.filter(
          (node) =>
            (node as Element).getAttribute('ParallelGroupingRef') ===
            parallelGroupingRef
        );
        groupedNodes.push(parallelNodes);
        handledRefs.push(parallelGroupingRef);
      }
    });
    return groupedNodes;
  };

  private createLinkBetweenNodes = (
    fromNodes: IModelNode[],
    toNode: IModelNode,
    decisionKeyToIsYesLink: object
  ) => {
    const links = fromNodes.map((fromNode) => {
      let link: IModelNodeLink;
      const fromNodeIsDecision =
        fromNode.category === NodeCategory.EXCLUSIVE_GATEWAY;
      if (fromNodeIsDecision) {
        const isYesLink = decisionKeyToIsYesLink[fromNode.key];
        const linkText =
          isYesLink === undefined ? undefined : isYesLink ? 'No' : 'Yes';
        link = this.createDecisionLink(
          fromNode.key,
          toNode.key,
          fromNode.allowNamingLinks ?? false,
          linkText
        );
      } else {
        link = this.createActivityLink(fromNode, toNode.key);
      }
      return link;
    });
    return links;
  };

  private createDecisionLink = (
    fromKey: number,
    toKey: number,
    allowNaming: boolean,
    linkText: string
  ) => {
    return {
      from: fromKey,
      to: toKey,
      text: linkText,
      visible: allowNaming,
      Trigger: {
        Type: 'Auto',
        NameRef: 'GatewayDecisionCommand'
      },
      Conditions: [
        {
          Type: 'Action',
          ConditionInversion: 'false',
          NameRef: 'ExclusiveGatewayImplementation'
        }
      ]
    };
  };

  private createActivityLink = (fromNode: IModelNode, toKey: number) => {
    return {
      from: fromNode.key,
      to: toKey,
      category: '',
      Trigger: {
        Type: fromNode.IsInitial === 'true' ? 'Auto' : 'Command',
        NameRef: 'ExecuteActivityCommand'
      },
      Restrictions: [
        {
          NameRef: 'Initiator',
          Type: 'Allow'
        }
      ]
    };
  };

  private createModelNodeFromActivity = (
    activity: Node,
    xIndex: number,
    yIndex: number,
    parallelNodes: IModelNode[],
    swimLaneDetails: ISwimLaneDetails[]
  ) => {
    const location = this.getLocationForNode(xIndex, yIndex);

    const modelNode: IModelNode = {
      key: this.key++,
      id: this.guidService.new(),
      category: NodeCategory.ACTIVITY,
      actor: DynamicActorTypeNames.INITIATOR,
      actorName: DynamicActorTypeNames.INITIATOR,
      avatarUrl: this.avatarService.getModelerNodeAvatarUrl(
        null,
        DynamicActorTypeNames.INITIATOR
      ),
      actorType: ActorTypes.DYNAMIC,
      dynamicActorType: DynamicActorTypeIds.ASSIGNED_INITIATOR,
      text: Array.from(activity.childNodes).find(
        (n) => n.nodeName === NodeName.Text
      )?.textContent,
      item: 'generic task',
      taskType: TaskType.TASK,
      loc: `${location.x} ${location.y}`,
      NotifyOnStepCreated: true,
      reminderType: 'None',
      deadlineType: 'None',
      costTimeType: 'None',
      webhooks: [],
      noReminder: true,
      ngDialogId: 'ngdialog2',
      isSaved: true,
      isNew: false,
      displayNotificationIcon: 'false'
    };
    this.setLocationForNodeInSwimLane(
      modelNode,
      activity,
      parallelNodes,
      swimLaneDetails
    );
    return modelNode;
  };

  private createModelNodeFromProcessLink = (
    decision: Node,
    xIndex: number,
    yIndex: number,
    parallelNodes: IModelNode[],
    swimLaneDetails: ISwimLaneDetails[]
  ) => {
    const location = this.getLocationForNode(xIndex, yIndex);
    const modelNode: IModelNode = {
      key: this.key++,
      category: NodeCategory.COMPONENT,
      taskType: TaskType.COMPONENT,
      loc: `${location.x} ${location.y}`,
      id: this.guidService.new(),
      text:
        (decision as Element).getAttribute('LinkedProcessName') +
        ` {${(decision as Element).getAttribute('LinkedProcessId')}}`
    };
    this.setLocationForNodeInSwimLane(
      modelNode,
      decision,
      parallelNodes,
      swimLaneDetails
    );
    return modelNode;
  };

  private createModelNodesFromDecision = (
    node: Node,
    xIndex: number,
    yIndex: number,
    parallelNodes: IModelNode[],
    swimLaneDetails: ISwimLaneDetails[],
    decisionActivity?: Node
  ) => {
    const isDecisionForOrphan = node.nodeName === NodeName.OrphanProcessLink;
    const location = this.getLocationForNode(xIndex, yIndex);
    const decision: IModelNode = {
      key: this.key++,
      id: this.guidService.new(),
      category: NodeCategory.EXCLUSIVE_GATEWAY,
      text: Array.from(node.childNodes).find(
        (n) => n.nodeName === NodeName.Text
      )?.textContent,
      loc: `${location.x} ${location.y}`,
      allowNamingLinks: !isDecisionForOrphan,
      gateway: {
        gates: [],
        fieldId: undefined,
        dbFieldName: undefined,
        dbName: undefined
      }
    };

    this.setLocationForNodeInSwimLane(
      decision,
      node,
      parallelNodes,
      swimLaneDetails
    );

    const decisionLocationParts = decision.loc.split(' ');
    const decisionYCoord = parseInt(decisionLocationParts[1]);
    const decisionLinksToProcess =
      (node as Element).getAttribute('DecisionLinkType') === 'ProcessLink' ||
      isDecisionForOrphan;
    let linkNode: IModelNode;
    if (decisionLinksToProcess) {
      const xCoord =
        location.x +
        (isDecisionForOrphan ? this.MODEL_X_SPACING / 2 : this.MODEL_X_SPACING);
      linkNode = {
        key: this.key++,
        category: NodeCategory.COMPONENT,
        taskType: TaskType.COMPONENT,
        loc: `${xCoord} ${decisionYCoord}`,
        id: this.guidService.new(),
        text:
          (node as Element).getAttribute('LinkedProcessName') +
          ` {${(node as Element).getAttribute('LinkedProcessId')}}`
      };
    } else {
      linkNode = this.createModelNodeFromActivity(
        decisionActivity,
        xIndex + 0.5,
        yIndex,
        parallelNodes,
        swimLaneDetails
      );
    }
    // Looks tidier if decision is below its linked Node
    decision.loc = `${decisionLocationParts[0]} ${
      decisionYCoord + this.MODEL_Y_SPACING
    }`;

    const endNode = this.createEndNode(linkNode);
    return [decision, linkNode, endNode];
  };

  private createDivergeModelNode = (
    xIndex: number,
    priorNodes: IModelNode[]
  ) => {
    const xCoordinate = xIndex * this.MODEL_X_SPACING;
    const yCoordinate = priorNodes[0].loc.split(' ')[1];
    const modelNode: IModelNode = {
      key: this.key++,
      id: this.guidService.new(),
      category: NodeCategory.DIVERGE_GATEWAY,
      text: 'Diverge',
      loc: `${xCoordinate} ${yCoordinate}`
    };
    return modelNode;
  };

  private createMergeModelNode = (xIndex: number, priorNodes: IModelNode[]) => {
    const xCoordinate = xIndex * this.MODEL_X_SPACING;

    const priorNodesYLimits = { min: Number.MAX_VALUE, max: 0 };
    priorNodes.forEach((node) => {
      const locationParts = node.loc.split(' ');
      const y = parseInt(locationParts[1]);
      priorNodesYLimits.min = Math.min(priorNodesYLimits.min, y);
      priorNodesYLimits.max = Math.max(priorNodesYLimits.max, y);
    });
    const yCoordinate =
      (priorNodesYLimits.max - priorNodesYLimits.min) / 2 +
      priorNodesYLimits.min;

    const modelNode: IModelNode = {
      key: this.key++,
      id: this.guidService.new(),
      category: NodeCategory.CONVERGE_GATEWAY,
      text: 'Merge',
      loc: `${xCoordinate} ${yCoordinate}`
    };

    return modelNode;
  };

  private setLocationForNodeInSwimLane = (
    node: IModelNode,
    sourceNode: Node,
    parallelNodes: IModelNode[],
    swimLaneDetails: ISwimLaneDetails[]
  ) => {
    const swimLaneName = this.getNodeOwnership(sourceNode);
    const swimLane = swimLaneDetails.find((lane) => lane.name === swimLaneName);
    if (swimLane === undefined) {
      return;
    }
    const xCoordinate = node.loc.split(' ')[0];
    const location = {
      x: xCoordinate,
      y: swimLane.start * this.MODEL_Y_SPACING
    };
    node.loc = `${location.x} ${location.y}`;
    node.group = swimLane.key;

    while (parallelNodes.some((n) => n.loc === node.loc)) {
      location.y += this.MODEL_Y_SPACING;
      node.loc = `${location.x} ${location.y}`;
    }
  };

  private getLocationForNode = (xIndex: number, yIndex: number) => {
    const xCoordinate = xIndex * this.MODEL_X_SPACING;
    const yCoordindate = yIndex * this.MODEL_Y_SPACING;
    return { x: xCoordinate, y: yCoordindate };
  };

  private createFlowinglyNodeFromActivity = (
    activity: Node,
    modelNode: IModelNode,
    modelNodeArrayIndex: number
  ) => {
    const flowinglyNode: INodeCommandData = {
      ActorName: DynamicActorTypeNames.INITIATOR,
      AssignedInitiator: true,
      AssignedInitiatorManager: false,
      AssignedPreviousActor: false,
      AvatarUrl: this.avatarService.getModelerNodeAvatarUrl(
        null,
        DynamicActorTypeNames.INITIATOR
      ),
      Card: {
        formElements: this.createFormElementsForActivity(
          activity,
          modelNode.key
        )
      },
      DynamicActorType: DynamicActorTypeIds.ASSIGNED_INITIATOR,
      FlowModelId: this.guidService.empty(),
      Id: this.guidService.new(),
      IsFirstNode: false,
      IsLastNode: false,
      IsPublicForm: false,
      MaxNumberOfApproversRequired: 0,
      ModelerNodeId: modelNode.id,
      NodeDataArrayIndex: modelNodeArrayIndex,
      NotifyOnStepCreated: true,
      NumberOfApproversRequired: 0,
      NumberOfApproversRequiredType: 0,
      PublicFormType: 0,
      RefSequence: '',
      SelectedApprovers: [],
      SelectedDynamicActors: [],
      StepName: modelNode.text,
      StepType: this.flowinglyConstants.taskType.TASK,
      UserNotificationRequest: false,
      WhenApproversSelected: 0,
      isDeleted: false,
      NodeCustomEmail: null
    };
    return flowinglyNode;
  };

  private createFlowinglyNodeFromComponent = (
    activity: Node,
    modelNode: IModelNode,
    modelNodeArrayIndex: number
  ) => {
    const flowinglyNode: INodeCommandData = {
      ActorName: null,
      AvatarUrl: null,
      Card: { formElements: [] },
      FlowModelId: this.guidService.empty(),
      StepType: this.flowinglyConstants.taskType.COMPONENT,
      Id: this.guidService.new(),
      IsFirstNode: false,
      IsLastNode: false,
      IsPublicForm: false,
      ModelerNodeId: modelNode.id,
      NodeDataArrayIndex: modelNodeArrayIndex,
      NotifyOnStepCreated: true,
      PublicFormType: 0,
      RefSequence: '',
      SelectedApprovers: [],
      SelectedDynamicActors: [],
      StepName: modelNode.text,
      UserNotificationRequest: false,
      isDeleted: false,
      NodeCustomEmail: null
    };
    return flowinglyNode;
  };

  private createFormElementsForActivity = (
    activity: Node,
    modelNodeKey: number
  ) => {
    const childProcessProcedures = Array.from(activity.childNodes).find(
      (node) => node.nodeName === NodeName.ChildProcessProcedures
    );
    if (childProcessProcedures === undefined) {
      return [];
    }
    let taskCount = -1;
    const fields: any[] = [
      {
        conditions: [],
        defaultValueOption: 'none',
        displayName: 'Instruction',
        id: this.guidService.new(),
        name: 'field' + modelNodeKey,
        noLabel: true,
        type: FormFieldType.INSTRUCTION,
        typeName: FormFieldTypePascal.INSTRUCTION,
        value: Array.from(childProcessProcedures.childNodes)
          .map((child) => {
            if (child.nodeName === NodeName.Task) {
              taskCount++;
            }
            return this.getNodeTextContent(child, taskCount);
          })
          .join('')
      }
    ];
    return fields;
  };

  private getNodeTextContent = (node: Node, taskCount: number) => {
    const blueColourCode = '#0070d0';
    const greenColourCode = '#008030';
    const childNodes = Array.from(node.childNodes);
    let text = '';
    if (node.nodeName === NodeName.Note) {
      text = this.getNoteHtml(childNodes, blueColourCode);
    } else if (
      node.nodeName === NodeName.Task ||
      node.nodeName === NodeName.Decision
    ) {
      text = this.getTaskHtml(childNodes, taskCount, greenColourCode);
    } else if (node.nodeName === NodeName.WebLink) {
      text = this.getWebLinkHtml(childNodes);
    } else if (
      node.nodeName === NodeName.Form ||
      node.nodeName === NodeName.Guide ||
      node.nodeName === NodeName.Image ||
      node.nodeName === NodeName.Information ||
      node.nodeName === NodeName.Policy ||
      node.nodeName === NodeName.Training
    ) {
      if (
        (node as Element).hasAttribute('NetworkFileName') &&
        (node as Element).getAttribute('NetworkFileName')
      ) {
        text = this.getNetworkFileHtml(node, childNodes);
      } else {
        text = this.getFileHtml(node, childNodes);
      }
    }

    const linkedProcessName = (node as Element).getAttribute(
      'LinkedProcessName'
    );
    if (linkedProcessName) {
      const linkedProcessId = (node as Element).getAttribute('LinkedProcessId');
      text +=
        `<p style="margin-left:60px;"><span style="color:${blueColourCode}">` +
        `<b>PROCESS</b></span> <a><b>${linkedProcessName}{${linkedProcessId}}</b></a></p>`;
    }
    return text.replaceAll('|~|', '<br />');
  };

  private getNoteHtml(childNodes: ChildNode[], color: string) {
    const heading = this.escapeHtml(
      childNodes.find((child) => child.nodeName === NodeName.Text)?.textContent
    );
    const content = this.escapeHtml(
      childNodes.find((child) => child.nodeName === NodeName.Attachment)
        ?.textContent
    );
    return (
      `<p><strong><span style="color:${color};">NOTE&nbsp; &nbsp; &nbsp; &nbsp;</span>` +
      `<span>${heading}</span></strong></p>` +
      (content ? `<p style="margin-left:60px;">${content}</p>` : '')
    );
  }

  private getTaskHtml(
    childNodes: ChildNode[],
    taskCount: number,
    color: string
  ) {
    const alphabetLetterCount = 26;
    const asciiACharCode = 97;
    let taskCode = String.fromCharCode(
      (taskCount % alphabetLetterCount) + asciiACharCode
    );
    const repeatCount = Math.floor(taskCount / alphabetLetterCount) + 1;
    taskCode = taskCode.repeat(repeatCount);
    const content = this.escapeHtml(
      childNodes.find((child) => child.nodeName === NodeName.Text)?.textContent
    );
    return `<p style="margin-left:${taskCode.length + 1}em;text-indent:-${
      taskCode.length + 1
    }em"><span style="color:${color}"><strong>${taskCode}&nbsp; &nbsp; &nbsp;</strong></span>${content}</p>`;
  }

  private getWebLinkHtml(childNodes: ChildNode[]) {
    const linkText = this.escapeHtml(
      childNodes.find((child) => child.nodeName === NodeName.Text)?.textContent
    );
    let link =
      childNodes.find((child) => child.nodeName === NodeName.Attachment)
        ?.textContent || '';
    link = link.replaceAll('&mobileredirect=true', '');
    return `<p><a href="${link}" target="_blank">${linkText}</a></p>`;
  }

  private getNetworkFileHtml(node: Node, childNodes: ChildNode[]) {
    let fileLink = (node as Element).getAttribute('NetworkFileName');
    // this will trip up the antiXSS check
    fileLink = fileLink.replaceAll('&mobileredirect=true', '');
    const fileName = this.escapeHtml(
      childNodes.find((child) => child.nodeName === NodeName.Text)?.textContent
    );
    return `<p><a href="${fileLink}" target="_blank">${fileName}</a></p>`;
  }

  private getFileHtml(node: Node, childNodes: ChildNode[]) {
    const documentUniqueId = (node as Element).getAttribute('DocumentUniqueId');
    const content = this.escapeHtml(
      childNodes.find((child) => child.nodeName === NodeName.Text)?.textContent
    );
    return `<p>[${node.nodeName}|${content}|${documentUniqueId}]</p>`;
  }

  private escapeHtml(text: string) {
    if (!text) {
      return text;
    }
    const textArea = document.createElement('textarea');
    textArea.innerText = text;
    return textArea.innerHTML;
  }
}

enum NodeName {
  Activity = 'Activity',
  Attachment = 'Attachment',
  ChildProcessProcedures = 'ChildProcessProcedures',
  Decision = 'Decision',
  Form = 'Form',
  Guide = 'Guide',
  Image = 'Image',
  Information = 'Information',
  Inputs = 'Inputs',
  Note = 'Note',
  OrphanProcessLink = 'OrphanProcessLink',
  Outputs = 'Outputs',
  Ownerships = 'Ownerships',
  Policy = 'Policy',
  Process = 'Process',
  ProcessGroup = 'ProcessGroup',
  ProcessGroupItems = 'ProcessGroupItems',
  ProcessLink = 'ProcessLink',
  ProcessProcedures = 'ProcessProcedures',
  Role = 'Role',
  SearchKeywords = 'SearchKeywords',
  Tag = 'Tag',
  Targets = 'Targets',
  Task = 'Task',
  Text = 'Text',
  Triggers = 'Triggers',
  Training = 'Training',
  WebLink = 'WebLink'
}
