/**
 * @ngdoc directive
 * @name flowHistoryCompleted
 * @module flowingly.runner.flow
 * @description  This comppnent is used to display information about when a flow was completed.
 * @usage
 * ```
   <flow-history-completed ng-if="$ctrl.flow.MomentCompleted" flow="$ctrl.flow" is-mobile="$ctrl.isMobile"></flow-history-completed>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * flow: the flow data to display (JSON)
 * * isMobile: show mobile view if set
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.flow').component('flowHistoryCompleted', {
  bindings: {
    flow: '<',
    isMobile: '<', //show mobile view if set
    onCommentsClick: '&', //show comments tab on click
    onPrintClick: '&', //print pdf on click
    toggleDeleteModal: '<'
  },
  controller: function () {
    const $ctrl = this;
    $ctrl.showComments = showComments;
    $ctrl.displayFinalisedReason =
      $ctrl.flow.FinalisedReason.toLowerCase() === 'withdrawn'
        ? 'Cancelled'
        : $ctrl.flow.FinalisedReason;

    /// PUBLIC /////////////////////////////////////////////////
    function showComments() {
      $ctrl.onCommentsClick();
    }
  },
  templateUrl:
    'Client/runner.flow/runner.flow.history/runner.flow.history.completed.tmpl.html'
});
