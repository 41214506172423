import React from 'react';
import { FlowModelType } from '@Shared.Angular/flowingly.services/flowingly.constants';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import EmptyState from '@Client/runner.directives/common/EmptyState';
import FlowIconSquare from '@Client/runner.directives/common/FlowIconSquare/FlowIconSquare';
import { IPublishApprovalFlowModel } from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/publishApprovalFlowModel';

interface Props {
  isMobile: boolean;
  filteredFlowModels: IPublishApprovalFlowModel[];
}

const SentForApprovalList: React.FC<Props> = ({
  isMobile,
  filteredFlowModels
}) => {
  const flowinglyMomentService =
    useService<SharedAngular.FlowinglyMomentService>('flowinglyMomentService');
  const tempModelerUrlService = useService<SharedAngular.TempModelerUrlService>(
    'tempModelerUrlService'
  );

  const getOverdueStatus = (dueBy: string | null) => {
    const isOverdue = dueBy
      ? new Date(dueBy).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
      : false;

    return {
      statusText: isOverdue ? 'Overdue' : 'Due By',
      statusClass: isOverdue ? 'color-warning' : ''
    };
  };

  return filteredFlowModels.length > 0 ? (
    <div className={`flow-block ${isMobile ? 'isMobile' : ''}`}>
      <div className={`flow-list ${isMobile ? 'isMobile' : ''}`}>
        <ul>
          {filteredFlowModels.map((flowModel, index) => {
            const { statusText, statusClass } = getOverdueStatus(
              flowModel.dueBy
            );

            return (
              <li key={`${flowModel.id}-${index}`}>
                <div className={`flow-start`} id={`flowactive_${flowModel.id}`}>
                  <div
                    className="flow-start-header flex flex-row"
                    onClick={() =>
                      tempModelerUrlService.openModeler(
                        flowModel.id,
                        false,
                        true,
                        null,
                        true
                      )
                    }
                  >
                    {!isMobile && (
                      <FlowIconSquare name={flowModel.name} flowCode="" />
                    )}

                    {!isMobile && (
                      <div className="governance-list-contents flex flex-row">
                        <div className="governance-list-item">
                          <div className="governance-list-item-text flex-column">
                            {flowModel.name}
                          </div>
                          <div className="governance-list-item-sub-text flex-column">
                            {flowModel.category}
                          </div>
                        </div>
                        <div className="governance-list-item">
                          <div className="governance-list-item-text flex-column">
                            {flowModel.description
                              ? flowModel.description
                              : '\u00A0'}
                          </div>
                          <div className="governance-list-item-sub-text flex-column">
                            {flowModel.flowModelType === FlowModelType.WORKFLOW
                              ? 'Workflow'
                              : 'Process Map'}
                          </div>
                        </div>
                        <div className="governance-list-item">
                          <div className="governance-list-item-text flex-column">
                            <div>Approver</div>
                            <div className="governance-list-item-sub-text flex-column">
                              {flowModel.approver}
                            </div>
                          </div>
                        </div>
                        <div className="governance-list-item">
                          <div
                            className={`governance-list-item-text flex-column`}
                          >
                            <div className={`${statusClass}`}>{statusText}</div>

                            <div className="governance-list-item-sub-text flex-column">
                              {flowinglyMomentService.formatRawDate(
                                flowModel.dueBy,
                                'DD MMM YYYY'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isMobile && (
                      <div className="flow-start-mobile">
                        <div className="flex flow-header__main">
                          <FlowIconSquare name={flowModel.name} flowCode="" />
                          <div className="flow-list__contents flex flex-row">
                            <div className="flow-list__contents__subject--mobile mr-20">
                              <div className="flow-list__contents__subject__text flex-column">
                                {flowModel.name}
                              </div>
                              <div className="flow-list__contents__subject__started-by flex-column">
                                {flowModel.category}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flow-header__extra flow-header__extra--start-flow">
                          {flowModel.description}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : (
    <div
      className={`governance-process-review-due-list-empty ${
        isMobile ? 'isMobile' : ''
      }`}
    >
      <EmptyState
        summaryText={`No flow models were sent for approval`}
        bodyText={`You haven’t submitted any flow models for approval`}
      />
    </div>
  );
};

export default SentForApprovalList;
