import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

class RunnerGovernanceController {
  isMobile: boolean;
  helpUri: string;
  constructor(
    private browserUtilsService: SharedAngular.BrowserUtilsService,
    private $state: angular.ui.IStateService,
    private APP_CONFIG: SharedAngular.APP_CONFIG
  ) {
    if (
      !this.APP_CONFIG.enableProcessApprovals &&
      !APP_CONFIG.showGovernanceFlowModelsWithReviewDue
    ) {
      this.$state.go('app.runner.flowsactive');
    }
    this.isMobile = this.browserUtilsService.isMobileDevice();
  }
}
angular
  .module('flowingly.runner.governance')
  .controller('runnerGovernanceController', [
    'browserUtilsService',
    '$state',
    'APP_CONFIG',
    function (browserUtilsService, state, APP_CONFIG) {
      return new RunnerGovernanceController(
        browserUtilsService,
        state,
        APP_CONFIG
      );
    }
  ]);
