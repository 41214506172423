import angular, {
  ILocationService,
  IQService,
  IRootScopeService,
  ITimeoutService,
  IWindowService
} from 'angular';
import { IFlowinglyWindow } from './interfaces/flowingly.window';
import { IStateService } from 'angular-ui-router';
import { SharedAngular } from './@types/sharedAngular';
import {
  LoginAuditAction,
  LoginAuditIdentityProvider
} from '@Shared.Angular/flowingly.services/flowingly.constants';

/**
 * Register event handler for ui-router state change
 *
 * See https://github.com/angular-ui/ui-router/wiki for more information.
 */

registerEventHandler.$inject = [
  '$window',
  '$rootScope',
  '$q',
  'flowinglyConstants',
  '$state',
  '$location',
  'APP_CONFIG',
  'authService',
  'authManager',
  'sessionService',
  'tokenService',
  'intercomService',
  'lock',
  'devLoggingService',
  'Idle',
  'redirectService',
  'redirectFileService',
  'PPDFService',
  '$timeout',
  'authLoggingApiService',
  '$animate',
  'brandingService',
  'appInsightsService',
  'userApiService',
  'browserUtilsService',
  'angularAuth0SpaSdk'
];

declare const window: IFlowinglyWindow;

async function registerEventHandler(
  $window: IWindowService,
  $rootScope: IRootScopeService,
  $q: IQService,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  $state: IStateService,
  $location: ILocationService,
  APP_CONFIG: SharedAngular.APP_CONFIG,
  authService: AuthService,
  authManager: angular.jwt.IAuthManagerServiceProvider,
  sessionService: SharedAngular.SessionService,
  tokenService: SharedAngular.TokenService,
  intercomService: SharedAngular.IntercomService,
  lock: AngularLock,
  devLoggingService: SharedAngular.DevLoggingService,
  Idle: angular.idle.IIdleService,
  redirectService: RedirectService,
  redirectFileService: RedirectFileService,
  PPDFService: PPDFService,
  $timeout: ITimeoutService,
  authLoggingApiService: SharedAngular.AuthLoggingApiService,
  $animate: angular.animate.IAnimateService,
  brandingService: SharedAngular.BrandingService,
  appInsightsService: SharedAngular.AppInsightsService,
  userApiService: SharedAngular.UserApiService,
  browserUtilsService: SharedAngular.BrowserUtilsService,
  angularAuth0SpaSdk: SharedAngular.AngularAuth0SpaSdk
) {
  $animate.enabled(false);
  (window as any).PPDFService = PPDFService;

  if (
    $location.path().toLowerCase() === '/acceptinvite' &&
    $location.search().inviteToken
  ) {
    tokenService.setToken($location.search().inviteToken);
  }

  if (!isLoggingIn() && isLoggedIn()) {
    userApiService.getLoginState().then((loggedIn: boolean) => {
      if (!loggedIn) {
        authService.logout();
        return;
      }
    });
  }

  if (
    location.search.includes('state=') &&
    (location.search.includes('code=') || location.search.includes('error='))
  ) {
    try {
      const auth0Client = await angularAuth0SpaSdk.getClient();
      const { appState } = await auth0Client.handleRedirectCallback();
      window.history.replaceState(
        {},
        document.title,
        appState.redirectUrl || ''
      );
      const idTokenClaims = await auth0Client.getIdTokenClaims();
      await authService.auth0SpaSdkAuthenticated(idTokenClaims.__raw);
    } catch (error) {
      devLoggingService.error(error);
    }
  }

  /**
   * after successfully login, user is redirected back to index.html from oAuth
   * so need to get pre-login settings again
   */
  const loginSettingsLoaded = sessionService
    .getLoginSettings($window.location.hostname)
    .then((loginSettings) => {
      if (loginSettings) {
        brandingService.setPageTitle(loginSettings.title);
        brandingService.setFavIcon(loginSettings.favIcon);

        APP_CONFIG.populateLoginSettings(loginSettings);
        if (APP_CONFIG.enableAppInsightsRunner) {
          appInsightsService.startAppInsights(
            APP_CONFIG.appInsightsRunnerConfig
          );
        }
      }

      if (!browserUtilsService.isApp()) {
        tokenService.setIdleExpiry();
      }

      return authService.getProfileDeferred().then((profile) => {
        return new Promise<void>((resolve, reject) => {
          authService.getUserPendingAcceptCondition(
            () => {
              authService.getUserDetails(profile.email);
              userApiService.userLoginAudit({
                success: true,
                identityProvider: sessionService.isSso()
                  ? LoginAuditIdentityProvider.SSO
                  : LoginAuditIdentityProvider.UserAndPassword,
                action: LoginAuditAction.Login
              });

              $rootScope.$emit('flowingly.onAuthentication');
              resolve();
            },
            () => {
              authService.loginFailed();
              reject();
            }
          );
        });
      });
    });

  registerRedirectFlow(); // intercept, and callbacks  for the redirect

  //set the logging level (TODO make this a provider)
  devLoggingService.setLoggingLevel(devLoggingService.levels.INFO);

  const userReady = authService.getUserDeferred();
  $q.all([loginSettingsLoaded, userReady]).then(() => {
    sessionService
      .getSettings(['Flowingly.Core.ServiceCatalog.Runner'])
      .then(async (settings) => {
        if (!isLoggingIn()) {
          const loggedIn = await userApiService.getLoginState();
          if (!loggedIn) {
            authService.logout();
            return;
          }
          APP_CONFIG.populateSettings(settings);

          const homeUrl = APP_CONFIG.runnerRehomeUrl;
          if (homeUrl) {
            sessionService.rehome(homeUrl);
          }
        }

        if (sessionService.inTenantBusiness()) {
          APP_CONFIG.enableTenantSwitching = true;
        }
        if (
          !browserUtilsService.isApp() &&
          APP_CONFIG.sessionTimeoutInSecond > 0
        ) {
          Idle.setIdle(APP_CONFIG.sessionTimeoutInSecond);
        }

        startIntercom();
      });
  });

  // Put the authService and sessionService on $rootScope so its methods
  // can be accessed from the nav bar
  $rootScope.authService = authService;
  $rootScope.sessionService = sessionService;
  $rootScope.APP_CONFIG = APP_CONFIG;

  // Register the authentication listener that is set up in auth.service.js
  authService.registerAuthenticationListener();

  // Use the authManager from angular-jwt to check for
  // the user's authentication state when the page is
  // refreshed and maintain authentication
  authManager.checkAuthOnRefresh();

  // Listen for 401 unauthorized requests and redirect
  // the user to the login page
  // We are not using this, so remove it
  //authManager.redirectWhenUnauthenticated();
  // This ensures that the Authenticate message is fired by Lock. It is part of a work around by the Auth0 Lock guys
  // to get their lock app working with nested ui routes
  lock.interceptHash(); // See https://auth0.com/forum/t/authenticated-event-not-triggering/3554/45 for why this is added

  // Fired when the transition begins
  $rootScope.$on(
    '$stateChangeStart',
    function (event, toState, toParams, fromState, fromParams) {
      APP_CONFIG.ready.then(() => {
        handleRedirections(
          toState,
          toParams,
          fromState,
          APP_CONFIG,
          $location,
          $timeout
        );
      });
      //set the header title
      $rootScope.pageHeader = '';
      if (toState.name === 'app.runner.flowstodo')
        $rootScope.pageHeader = 'To Do';
      else if (toState.name === 'app.runner.flowsin')
        $rootScope.pageHeader = "Flows I'm In";
      else if (toState.name === 'app.runner.flowsactive')
        $rootScope.pageHeader = 'Start Flows';
      else if (toState.name === 'app.runner.reports')
        $rootScope.pageHeader = 'Reports';
      else if (toState.name === 'app.runner.profile')
        $rootScope.pageHeader = 'My Profile';
      else if (toState.name === 'app.runner.report')
        $rootScope.pageHeader = 'Report';
      else if (toState.name === 'app.runner.library')
        $rootScope.pageHeader = 'Library';
      else if (toState.name === 'app.runner.notifications')
        $rootScope.pageHeader = 'Notifications';
      else if (toState.name === 'app.runner.processmap')
        $rootScope.pageHeader = 'Process Maps';
      else if (toState.name === 'app.runner.processmapv2')
        $rootScope.pageHeader = 'Process Maps';
      else if (toState.name === 'app.accept')
        $rootScope.pageHeader = 'Accept Invite';
      else if (toState.name === 'app.runner.settings')
        $rootScope.pageHeader = 'Settings';
      else if (toState.name === 'app.runner.flow')
        $rootScope.previousRoute = event.currentScope.currentRoute;
      else if (fromState.name === 'app.runner.search')
        $rootScope.previousSearchTerm = fromParams.term;
      else if (toState.name === 'app.signup') {
        $window.document.title = `Sign Up | ${APP_CONFIG.brandingName}`;
        $rootScope.pageHeader = 'Sign Up';
      } else if (toState.name.indexOf('app.runner.setup') >= 0)
        $rootScope.pageHeader = 'Setup';
      else if (toState.name === 'app.runner.governance')
        $rootScope.pageHeader = 'Governance';

      startAppInsightsEventTimer(toState.name, fromState.name);
      intercomService.trackEvent(toState.name);
    }
  );

  const stateChangeErrorLn = $rootScope.$on(
    '$stateChangeError',
    function (error, event, toState, toParams, fromState, fromParams) {
      if (!sessionService.getUser()) {
        $state.go('app.login');
      }
      devLoggingService.log('$stateChangeError');
      devLoggingService.error(error);
    }
  );

  const stateNotFoundLn = $rootScope.$on(
    '$stateNotFound',
    function (event, toState, toParams, fromState, fromParams) {
      // When state name is not app.login
      devLoggingService.log('$stateNotFound');
    }
  );

  $rootScope.$on('$destroy', function () {
    //stop watching when scope is destroyed
    //stateChangeSuccessLn();
    stateChangeErrorLn();
    stateNotFoundLn();
    devLoggingService.log('$destroy');
  });

  $rootScope.$on('$stateChangeSuccess', function (_, current) {
    if (current.params != undefined) {
      document.title = current.params.title;
    } else if (APP_CONFIG.runnerTitle) {
      document.title = APP_CONFIG.runnerTitle;
    }

    //Set the current url so we can use this information to sedn to intercom
    $rootScope.currentRoute = '/' + current.url;
  });

  $rootScope.$on('$locationChangeStart', async function (event, next, current) {
    // For now this allows the user to get to the signup page without needing to authenticate
    // but surly there is a better way
    if (nextUrlMatchesCompareString(next, 'acceptInvite')) {
      $state.go('app.accept');
    } else if (next.indexOf('/form/') > 0) {
      const paths = $location.path().split('/');
      $state.go('app.public-form', { id: paths[paths.length - 1] });
    } else if (next.indexOf('/flow/') > 0) {
      const paths = $location.path().split('/');
      $state.go('app.public-form', { id: paths[paths.length - 1] });
    } else if (next.indexOf('/map') > 0) {
      const paths = $location.path().split('/');
      $state.go('app.publicmap', { id: paths[paths.length - 1] });
    } else if (nextUrlMatchesCompareString(next, 'ssocallback')) {
      $state.go('app.sso');
    } else {
      const token = $location.search().token;
      // Allows login using a token
      if (token && token !== 'null') {
        try {
          const profile = $location.search().profile;
          await authService.loginWithToken(token, profile);
        } catch {
          // Later code will handle any token issues
        }
        // Clear the token from the url
        $location.search('token', null);
        $location.search('profile', null);
      }
      // These checks are needed so we dont interfere with the login process and so can gracefully deal with the
      // situation where the user is authenticated but is missing in local storage
      if (
        !nextUrlMatchesCompareString(next, 'login') &&
        current.search('access_token') < 1
      ) {
        if (!sessionService.getUser()) {
          event.preventDefault(); // We are changing where we need to go
          authLoggingApiService.log(
            'runner.run.ts - $locationChangeStart event fired on rootScope. About to be navigated to /login'
          );
          let redirectUrl = '';
          if (next === current) {
            redirectUrl = current; // starting url to redirect to after login
          }

          if (redirectUrl) {
            $location.path('/login').search({ redirectUrl }); // $stage.go does not work in $locationChangeStart
          } else {
            $location.url('/login'); // $stage.go does not work in $locationChangeStart
          }
        } else {
          return tokenService
            .checkTokenExpire('/flowsactive/')
            .then((token) => {
              if (token !== null) {
                // Token has expired
                authManager.authenticate(sessionService.getProfile(), token);
              }
            })
            .catch(function (e) {
              devLoggingService.error(e);
              event.preventDefault(); // We are changing where we need to go
              authLoggingApiService.log(
                'runner.run.ts - $locationChangeStart event fired on rootScope. Check token expiry rejected promise. About to be navigated to /login'
              );
              const currentUrl = $location.absUrl();
              const encodedRedirectUrl = encodeURIComponent(currentUrl);
              $location.url(`/login?redirectUrl=${encodedRedirectUrl}`); // $stage.go does not work in $locationChangeStart
            });
        }
      }
    }
  });

  //Returns true if the last part of the Next URL matches the compare string passed in
  //Note I'm not happy with substringing a URL to determine which route it goes to but it has been done this way twice before
  //so will keep to this pattern ARRRRRRRGGGGHHHHHH!!!!
  function nextUrlMatchesCompareString(nextUrl, comaparer) {
    //remove all query string params to match correctly
    nextUrl = nextUrl.split(/[?#]/)[0];

    return nextUrl.substr(nextUrl.length - comaparer.length) === comaparer;
  }

  /**
   * See how the flow works here https://bizflo.atlassian.net/wiki/pages/viewpage.action?pageId=743833731
   */
  function registerRedirectFlow() {
    if (isRedirectionInFlight()) {
      return;
    } else if (
      ($location as any).$$search[flowinglyConstants.redirect.FILE_PARAM_KEY]
    ) {
      redirectFileService.setPendingRequestAsJwt(
        ($location as any).$$search[flowinglyConstants.redirect.FILE_PARAM_KEY]
      );
      return redirectFileService.beginFileRequest(); // case 1: clicked or direct entry to file
    } else if (
      ($location as any).$$search[flowinglyConstants.redirect.QUERY_PARAM_KEY]
    ) {
      redirectService.setPendingRequestAsJwt(
        ($location as any).$$search[flowinglyConstants.redirect.QUERY_PARAM_KEY]
      );
    }

    if (
      !(
        redirectService.hasPendingRequest() ||
        redirectFileService.hasPendingRequest()
      )
    ) {
      return;
    }

    if (isLoggedIn() || sessionService.isAuthenticationInFlight()) {
      if (redirectService.hasPendingRequest()) {
        return redirectService.beginRedirectRequest();
      } else if (redirectFileService.hasPendingRequest()) {
        // this is so dodgy -cassey
        return redirectFileService.beginFileRequest(); // case 2: some reason file entry failed, so it user refreshes and ends up here
      }
    } else {
      // if not logged in at all, wait for the authentication to finish
      // or if user is an SsoUser, we authenticate ourselves
      if (redirectService.hasPendingRequest()) {
        redirectService
          .readPendingRequest()
          .then((request) => {
            const jwt = request.value;
            if (jwt.isSsoUser) {
              authLoggingApiService.log(
                'runner.run.ts - registerRe-di-rectFlow().re-di-rectService.readPendingRequest().then. Is an sso user as per jwt.isSsoUser about to be navigated to /sso '
              );
              // [FLOW-7241] Commenting out redirection to /sso route for the time being. This needs to be enabled
              // to dynamically sign in sso users when we figure out the sso flow.
              // ($window as any).location = '/sso';
            }
          })
          .catch((err) => {
            // if all else fails, just redirect back to home
            console.error(err);
            redirectService.destroyPendingRequest();
            authLoggingApiService.log(
              'runner.run.ts - registerRedirectFlow().re-dir-ectService.readPendingRequest().catch. About to be navigated to /'
            );
            ($window as any).location = '/';
          });
      }
    }

    function isRedirectionInFlight() {
      return ($location as any).$$path == '/redirect';
    }
  }

  /**
   *  Returns true if a token has been stored in the local storage or
   *  if a front end authentication request is in flight
   *
   *  @returns {boolean}
   */
  function isLoggedIn() {
    return !!tokenService.getToken();
  }

  function isLoggingIn() {
    const currentUrl = new URL(window.location.toString());
    return (
      currentUrl.pathname.startsWith('/login') ||
      currentUrl.pathname.startsWith('/acceptInvite') ||
      currentUrl.searchParams['access_token']
    );
  }

  function startIntercom() {
    const user = sessionService.getUser();
    const intercomServiceOptions = {
      app_id: APP_CONFIG.intercomId,
      email: user.email,
      name: user.fullName,
      phone: user.phoneNumber,
      user_id: user.id,
      user_role: user.roles && user.roles.map((role) => role.name).join(', '),
      number_of_users_managed: user.numberOfUsersManaged,
      is_owner: user.isOwner,
      //this information associates this user with this company in intercom
      company: {
        id: user.businessId,
        name: user.businessName,
        industry: user.businessIndustry,
        created_at: user.businessCreatedDate
      }
    };

    intercomService.initializeIntercom(intercomServiceOptions);
  }

  function startAppInsightsEventTimer(
    toStateName: string,
    fromStateName: string
  ) {
    switch (toStateName) {
      case 'app.runner.flowsactive': {
        appInsightsService.startEventTimer('flowsActiveEntered');
        break;
      }
      case 'app.runner.flowsin': {
        appInsightsService.startEventTimer('flowsInEntered');
        break;
      }
      case 'app.runner.flowstodo': {
        appInsightsService.startEventTimer('flowsTodoEntered');
        break;
      }
      case 'app.runner.flow': {
        // flow selected from list
        const regEx = /app.runner.flowsin|app.runner.flowstodo/;

        /**
         * no previous route means runner lands on this page when it started
         * happened when either refresh browser or click on an external link to a flow
         * */
        if (!fromStateName) {
          appInsightsService.startEventTimer('flowLoadFromExternalLink');
        } else if (regEx.test(fromStateName)) {
          appInsightsService.startEventTimer('flowSelectedFromList');
        }
        break;
      }
      default:
        break;
    }
  }

  function handleRedirections(
    toState: angular.ui.IState,
    toParams: angular.ui.IStateParamsService,
    fromState: angular.ui.IState,
    APP_CONFIG: SharedAngular.APP_CONFIG,
    $location: angular.ILocationService,
    $timeout: angular.ITimeoutService
  ) {
    if (
      toState.name == 'app.runner.processmap' &&
      APP_CONFIG.enableProcessMapsV2
    ) {
      $timeout(() => {
        $location.path('/processmapsv2');
      });
    }

    if (
      toState.name == 'app.runner.processmapview' &&
      APP_CONFIG.enableProcessMapsV2
    ) {
      $timeout(() => {
        const processMapId = toParams.processMapId;
        $location.path(`/processmapsv2/${processMapId}`);
      });
    }

    const homePage = APP_CONFIG.defaultHomePage;
    if (
      homePage != 'flowsactive' &&
      toState.name == 'app.runner.flowsactive' &&
      fromState.url == '^'
    ) {
      $timeout(() => {
        $location.path(homePage);
      });
    }
  }
}

angular.module('flowingly.runner').run(registerEventHandler);
