import angular from 'angular';
import { IScope, ITimeoutService } from 'angular';

angular.module('fg').directive('fgEditCanvasField', [
  '$timeout',
  'workflowStore',
  'workflowService',
  'flowinglyModelUtilityService',
  'pubsubService',
  'currencyService',
  'flowinglyConstants',
  'APP_CONFIG',
  function (
    $timeout,
    workflowStore,
    workflowService,
    flowinglyModelUtilityService,
    pubsubService,
    currencyService,
    flowinglyConstants,
    APP_CONFIG
  ) {
    return {
      templateUrl:
        'angular-form-gen/edit/canvas/field/fg-edit-canvas-field.ng.html',
      controller: [
        '$scope',
        '$timeout',
        'workflowStore',
        'workflowService',
        'flowinglyModelUtilityService',
        'pubsubService',
        function (
          $scope: IScope,
          $timeout: ITimeoutService,
          workflowStore,
          workflowService,
          flowinglyModelUtilityService,
          pubsubService
        ) {
          const FIELD_TYPE = flowinglyConstants.formFieldType;
          const subscriberId = 'fgEditCanvasField';
          $scope.FIELD_TYPE = FIELD_TYPE;
          $scope.nameOfDecisionUsingField = undefined;

          $scope.hasCustomValidationOptions = (function (field) {
            return (
              field.type === FIELD_TYPE.NUMBER ||
              field.type === FIELD_TYPE.CURRENCY ||
              field.type === FIELD_TYPE.DATE ||
              field.type === FIELD_TYPE.DATETIME ||
              field.type === FIELD_TYPE.MULTISELECT_LIST ||
              field.type === FIELD_TYPE.SHORT_TEXT
            );
          })($scope.field);

          $scope.hasCurrencyOptions = $scope.field.type === FIELD_TYPE.CURRENCY;

          $scope.onCustomValidationSwitch = function (isOn) {
            const validation = $scope.field.customValidation || {};

            switch ($scope.field.type) {
              case FIELD_TYPE.SHORT_TEXT:
                if (isOn) {
                  validation.maxLength = 1;
                } else {
                  delete validation.maxLength;
                }
                break;
            }

            $scope.field.customValidation = validation;
          };

          $scope.$on('$destroy', () => {
            pubsubService.unsubscribeAll(subscriberId);
          });
          $scope.activateCustomValidation = function () {
            // wrapping it in a timeout because this may be be called from an ng-init
            $timeout(function () {
              switch ($scope.field.type) {
                case 'currency':
                  $scope.currencies = currencyService
                    .getCurrencies()
                    .map(function (c) {
                      return c.code;
                    })
                    .sort();
                  $scope.hasCustomCurrency = $scope.field.customUnits != null;

                  /**
                   * Drops the currency from the currency field or selects the first in the list.
                   */
                  $scope.toggleCurrency = function toggleCurrency(
                    hasCustomCurrency
                  ) {
                    if (hasCustomCurrency) {
                      $scope.field.customUnits = $scope.currencies[0];
                    } else {
                      $scope.field.customUnits = undefined;
                    }
                  };
                case 'date':
                case 'datetime':
                  /*  you cannot use readonly in modeler + formgen for some reason. It disables the picker
                   *  even after a timeout! The only way Ive found to circumvent this is to bind to the key
                   *  events and then cancel them!
                   *
                   *  I've not done delved deeper into  this, as I have more pressing tickets to attend to.
                   */
                  angular
                    .element(
                      '#' +
                        $scope.field.name +
                        '_custom_validator_operator_value'
                    )
                    .on('cut copy paste keypress keydown', function (e) {
                      e.preventDefault();
                      e.stopPropagation();
                    })
                    .css('pointer-events', 'none');
                  break;
              }
            }, 100);
          };

          $scope.activateCustomValidation();

          $scope.initialPreviousStep = function () {
            var nodeDataArray = workflowStore.getNodeDataArray();
            var linkDataArray = workflowStore.getLinkDataArray();
            $scope.previousStepsDataSource =
              flowinglyModelUtilityService.getPreviousNodesDataSourceForDropdown(
                $scope.nodeKey,
                nodeDataArray,
                linkDataArray,
                null,
                true
              );
            if (
              $scope.field.type === 'date' ||
              $scope.field.type === 'datetime'
            ) {
              var nodeData = nodeDataArray.find(function (n) {
                return n.key === $scope.nodeKey;
              });
              if (nodeData) {
                if ($scope.previousStepsDataSource === undefined)
                  $scope.previousStepsDataSource = [];

                $scope.nodeStepId = nodeData.id;
                $scope.previousStepsDataSource.push({
                  Value: nodeData.text,
                  Key: nodeData.id
                });
              }
            }
            if ($scope.field.customValidation.valueOption !== 'previousValue') {
              $scope.field.customValidation.previousStepId = undefined;

              if ($scope.field.customValidation.formFieldId) {
                var matchField = $scope.previousStepFieldsDataSource.find(
                  function (f) {
                    return f.name === $scope.field.customValidation.formFieldId;
                  }
                );
              }
            }

            if ($scope.previousStepsDataSource) {
              $timeout(function () {
                angular
                  .element(
                    '#' + $scope.field.name + '_custom_validator_previous_step'
                  )
                  .kendoComboBox({
                    dataSource: $scope.previousStepsDataSource,
                    dataTextField: 'Value',
                    dataValueField: 'Key',
                    value: $scope.field.customValidation.previousStepId,
                    clearButton: false
                  });

                if (
                  !$scope.field.customValidation.previousStepId &&
                  $scope.field.customValidation.valueOption === 'previousValue'
                ) {
                  $scope.field.customValidation.previousStepId =
                    $scope.previousStepsDataSource[0].Key;
                }

                $scope.onPreviousStepChange();
              });
            }
          };

          $scope.onPreviousStepChange = function () {
            if (
              $scope.field.customValidation &&
              $scope.field.customValidation.valueOption === 'previousValue' &&
              $scope.previousStepsDataSource &&
              $scope.field.customValidation.previousStepId
            ) {
              var previousStep = workflowStore.getNodeById(
                $scope.field.customValidation.previousStepId
              );
              $scope.previousStepFieldsDataSource =
                previousStep.Card.formElements.filter(function (f) {
                  return (
                    f.type === $scope.field.type && f.name !== $scope.field.name
                  );
                });

              if (
                $scope.nodeStepId ===
                $scope.field.customValidation.previousStepId
              ) {
                var currentStepFileds = $scope.$parent.schema.fields.filter(
                  function (f) {
                    return (
                      f.type === $scope.field.type &&
                      f.name !== $scope.field.name
                    );
                  }
                );
                for (var i = 0; i < currentStepFileds.length; i++) {
                  var otherField = currentStepFileds[i];
                  var findField = $scope.previousStepFieldsDataSource.find(
                    function (f) {
                      return f.name === otherField.name;
                    }
                  );
                  if (findField) {
                    findField.displayName = otherField.displayName;
                  } else {
                    if ($scope.previousStepFieldsDataSource === undefined)
                      $scope.previousStepFieldsDataSource = [];

                    $scope.previousStepFieldsDataSource.push({
                      displayName: otherField.displayName,
                      name: otherField.name
                    });
                  }
                }
              }
              // check if the defaultFormFieldId still exist for newly loaded previous step
              if (!!$scope.field.customValidation.formFieldId) {
                var matchField = $scope.previousStepFieldsDataSource.find(
                  function (f) {
                    return f.name === $scope.field.customValidation.formFieldId;
                  }
                );

                if (!matchField) {
                  $scope.field.customValidation.formFieldId = undefined;
                }
              }

              if ($scope.previousStepFieldsDataSource.length > 0) {
                angular
                  .element(
                    '#' +
                      $scope.field.name +
                      '_custom_validator_previous_step_field'
                  )
                  .kendoComboBox({
                    dataSource: $scope.previousStepFieldsDataSource,
                    dataTextField: 'displayName',
                    dataValueField: 'name',
                    value: $scope.field.customValidation.formFieldId,
                    clearButton: false
                  });

                if (!$scope.field.customValidation.formFieldId) {
                  $scope.field.customValidation.formFieldId =
                    $scope.previousStepFieldsDataSource[0].name;
                  $scope.onPreviousFieldChange();
                }
              }
            }
          };

          $scope.onPreviousFieldChange = function () {
            var matchField = $scope.previousStepFieldsDataSource.find(function (
              f
            ) {
              return f.name === $scope.field.customValidation.formFieldId;
            });

            if (!matchField) {
              $scope.field.customValidation.formFieldId = undefined;
            }
          };

          $scope.clearPreviousOperationData = function () {
            if ($scope.field.customValidation.valueOption === 'none') {
              $scope.field.customValidation.previousStepId = undefined;
              $scope.field.customValidation.formFieldId = undefined;
              $scope.field.customValidation.stepCreatedDateOffset = '';
            } else if (
              $scope.field.customValidation.valueOption === 'previousValue'
            ) {
              $scope.field.customValidation.value = '';
              $scope.field.customValidation.stepCreatedDateOffset = '';
              $scope.initialPreviousStep();
            } else if (
              $scope.field.customValidation.valueOption === 'createdDate'
            ) {
              $scope.field.customValidation.previousStepId = undefined;
              $scope.field.customValidation.formFieldId = undefined;
              $scope.field.customValidation.value = '';
            }
          };

          pubsubService.subscribe(
            'WORKFLOW_DESIGNER_FORM_FIELDS_CHANGED',
            () => {
              $scope.onPreviousStepChange();
            },
            subscriberId
          );

          $scope.customValidationIsEnabled =
            [
              'number',
              'currency',
              'date',
              'datetime',
              'multiselectlist'
            ].indexOf($scope.field.type) !== -1;
          if ($scope.field.displayProperties === undefined) {
            $scope.field.displayProperties = false;
          }
          if ($scope.field.customValidation) {
            if ($scope.field.customValidation.rule !== undefined)
              $scope.field.customValidation.rule =
                $scope.field.customValidation.rule.toString();
            if (!$scope.field.customValidation.valueOption)
              $scope.field.customValidation.valueOption = 'none';
            if ($scope.field.customValidation.valueOption === 'previousValue')
              $scope.initialPreviousStep();
          } else {
            if ($scope.customValidationIsEnabled) {
              $scope.field.customValidation = {
                required: false,
                valueOption: 'none'
              };
            }
          }
        }
      ],
      link: function ($scope, element, attrs) {
        $scope.toggleSetting = function () {
          const field = $scope.field;
          const oldDisplayProperties = field.displayProperties;
          $timeout(function () {
            $scope.$parent.$apply(attrs.onSettingClick);
            field.displayProperties = !oldDisplayProperties;
            if (field.displayProperties) {
              field.shrunk = false;
            }
            if (field.type === 'image') {
              field.minimized = field.shrunk =
                !field.displayProperties &&
                (field.disabled || field.value == undefined);
            }
          });
        };

        $scope.toggleDisabled = function () {
          const field = $scope.field;
          field.disabled = !field.disabled;
          if (field.type === 'image') {
            field.minimized =
              !field.displayProperties &&
              (field.disabled || field.value == undefined);
          }
        };

        $scope.showRequiredToggle = (fieldType) => {
          const exemptFields = [
            'Approval rule',
            'Attach Documents',
            'Dynamic actors',
            'Formula',
            'Image',
            'Instruction',
            'Lookup',
            'Task list'
          ];

          return !exemptFields.some((x) => x === fieldType);
        };

        $scope.showExcludeFromCompletedStepsToggle = (fieldType) => {
          const excludingFields = ['Attach Documents', 'Image', 'Instruction'];

          return excludingFields.some((x) => x === fieldType);
        };

        $scope.showIncludeInFormToggle = (fieldType) => {
          const fields = ['Image'];
          return fields.some((x) => x === fieldType);
        };

        $scope.showVisibilityToggleInsideConfiguration = () => {
          return APP_CONFIG.showVisibilityToggleInsideConfiguration;
        };

        $scope.enableConditionalForms = () => {
          return APP_CONFIG.enableConditionalForms;
        };

        $scope.isUsedByDecision = () => {
          if ($scope.nameOfDecisionUsingField === undefined) {
            const currentNode = workflowService.getCurrentNode();
            const allNodes = workflowStore.getNodeDataArray();
            const decision = allNodes.find((node) => {
              return (
                node.category === 'exclusiveGateway' &&
                node.selectedNodeKey === currentNode.key &&
                node.gateway?.fieldId === $scope.field.name
              );
            });
            $scope.nameOfDecisionUsingField = decision ? decision.text : '';
          }
          return $scope.nameOfDecisionUsingField;
        };

        $scope.enableHiddenLookupField = () => {
          return APP_CONFIG.enableHiddenLookupField;
        };
      }
    };
  }
]);
