import angular from 'angular';
import { IStateProvider } from 'angular-ui-router';
angular.module('flowingly.runner.governance').config(config);
config.$inject = ['$stateProvider'];
function config($stateProvider: IStateProvider): void {
  $stateProvider.state('app.runner.governance', {
    url: 'governance',
    params: {
      title: 'Governance',
      backFromState: ''
    },
    controller: 'runnerGovernanceController',
    controllerAs: '$ctrl',
    templateUrl: 'Client/runner.governance/runner.governance.tmpl.html'
  });
}
